<div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.xs="column" fxLayoutAlign.xs="space-between"
    fxFlex="100%;">
    <div fxLayout="column" fxFlex="95%;" fxFlex.xs="100%" fxLayoutAlign="space-around flex-start"
        fxLayoutAlign.xs="space-around center">
        <div fxLayout="row" fxLayout.xs="column">
            <span class="mat-cell abstract-title">{{ item.title }}</span>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <span class="mat-cell abstract-date">Post Date: {{ item.posted | dateFormat}}</span>
        </div>
        <div fxLayout="row" fxLayout.xs="column" *ngIf="type===NOTIF_CONSTS.PAGE.MANAGE">
            <span class="mat-cell abstract-date">Valid From/Until: {{ item.from | dateFormat}} -
                {{item.until | dateFormat}}</span>
        </div>

      <div class="list" *ngIf="type===NOTIF_CONSTS.PAGE.MANAGE">
        <span *ngIf="item.rooms" class="mat-cell">Accounts: {{ chainAsString(item.rooms) }}</span>
        <span *ngIf="item.terr" class="mat-cell">Territory: {{ item.terr }}</span>
      </div>

        <div fxLayout="row" fxLayout.xs="column" fxFlex="95%;">
            <div class="mat-cell abstract-description" [innerHtml]="item.desc"></div>
        </div>

        <div *ngIf="type===NOTIF_CONSTS.PAGE.MANAGE" fxLayout="row" fxLayout.xs="column">

            <span class="mat-cell">
                <mat-chip-list multiple>
                    <mat-chip *ngFor="let channel of item.audience" selectable="true" [selected]="true">
                      <span *ngIf="channel === 'IR'">Republic of Ireland&nbsp;</span>
                      <span *ngIf="channel === 'AU'">Australia&nbsp;</span>
                      <span *ngIf="channel === 'NZ'">New Zealand&nbsp;</span>
                      <span *ngIf="channel === 'UK'">{{channel}}&nbsp;</span>
                    </mat-chip>
                </mat-chip-list>
            </span>
        </div>

    </div>
    <div fxLayout="column" fxLayout.xs="row" fxFlex="8%;" fxFlex.xs="100%" fxLayoutAlign="space-around center"
        fxLayoutAlign.xs="space-between">

        <!-- attachment button -->
        <button
          *ngIf="item.attach"
          class="btn-action btn-prim"
          mat-icon-button
        >
            <mat-icon
                *ngIf="item.attach.length > 1"
                style="cursor: pointer;"
                [matMenuTriggerFor]="menu"
                matRipple
            >attach_file</mat-icon>
            <mat-menu #menu="matMenu">
                <div *ngFor="let attach of item.attach">
                    <button
                        mat-menu-item
                        (click)="downloadFile(attach)"
                    >
                    <mat-icon
                    style="color: black;"
                    >insert_drive_file</mat-icon>
                    <span>{{ attach.substring(attach.lastIndexOf('/') + 1) }}</span>
                </button>
                </div>
            </mat-menu>

            <mat-icon
                *ngIf="item.attach.length === 1"
                class="details-icon"
                style="cursor: pointer;"
                matRipple
                (click)="downloadFile(item.attach[0])"
                >attach_file</mat-icon>
        </button>
        
        <button *ngIf="type===NOTIF_CONSTS.PAGE.MANAGE" (click)="onEditItem()" fxLayout="row" mat-button type="button"
            class="btn-action btn-prim">

            <div class="material-icons center-icon">edit</div>
        </button>
        <button *ngIf="type===NOTIF_CONSTS.PAGE.MANAGE" (click)="onDeleteItem()" fxLayout="row" mat-button type="button"
            class="btn-action btn-cancel">

            <div class="material-icons center-icon">delete</div>
        </button>
    </div>
</div>
