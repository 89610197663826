import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as JSZip from 'jszip';
import {ImageZip} from '../../shared/models/activity/image-zip.model';

@Injectable()
export class PhotoDownloaderService {
  constructor(private http: HttpClient) {}

  downloadToZip(imageUrls: ImageZip[], that) {
    const getRequests = [];
    imageUrls.forEach((imageZip) =>
      getRequests.push(
        this.http
          .get(imageZip.url + '&crossOrigin', {
            responseType: 'blob',
          })
          .toPromise()
      )
    );

    Promise.all(getRequests).then(
      (res) => {
        const zip = new JSZip();

        res.forEach((f, i) => {
          zip.file(`${imageUrls[i].chain}-${imageUrls[i].str}_${imageUrls[i].eid}_${imageUrls[i].date}.jpg`, f);
        });

        /* With file saver */
        // zip
        //   .generateAsync({ type: 'blob' })
        //   .then(blob => saveAs(blob, 'image.zip'));

        /* Without file saver */
        zip.generateAsync({ type: 'blob' }).then((blob) => {
          const a: any = document.createElement('a');
          document.body.appendChild(a);

          a.style = 'display: none';
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = 'image.zip';
          a.click();
          window.URL.revokeObjectURL(url);
        });
      },
      (err) => {
        that.msgBanner.addMsgError(
          that.messageList,
          'Error occured on downloading images. Please contact an Administrator! Error details:\n' +
          err.message
        );
      }
    );
  }
}
