<div>

  <app-msg-banner *ngIf="showNotification" [msgList]="messageList"
                  (msgListChange)="messageList = $event"></app-msg-banner>

  <div fxLayout="row">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button
        *ngIf="config.view.valueOf() !== attachmentView.TEMP" (click)="goBackToFileStructure()"
      >
        <mat-icon>arrow_back_ios</mat-icon>
      </button>

      <div fxLayout="row" fxLayoutAlign="start center">
        <span class="title">Upload File</span>
      </div>
    </div>

    <div
      *ngIf="config.view.valueOf() !== attachmentView.ADMIN && config.view.valueOf() !== attachmentView.ADMIN_DOCUMENTS
              && config.view.valueOf() !== attachmentView.VIEW_CHAIN_DOCUMENTS && config.view.valueOf() !== attachmentView.VIEW_GENERAL_DOCUMENTS"
      fxFlex="20px" fxFlexAlign="end end"
    >
      <mat-icon class="close-button" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>

  <div>
    <div class="container" appDnd (fileDropped)="onFileDropped($event)">
      <input type="file" id="fileDropRef" [accept]="config.view.valueOf() === attachmentView.TEMP ? '*' : 'application/pdf'" #fileInput
             [multiple]="config.view.valueOf() !== attachmentView.TEMP"
             (change)="fileBrowseHandler($event.target.files)"
      />
      <img src="assets/img/ic-upload.svg" alt="">
      <h3>Drag and drop file here</h3>
      <h3>or</h3>
      <label for="fileDropRef">Browse for file</label>
    </div>

    <div class="files-list">
      <div class="single-file" *ngFor="let file of files; let i = index">
        <img src="assets/img/ic-file.svg" width="45px" alt="file">
        <div class="info">

          <h4 class="name" *ngIf="file?.name.length > 35">
            {{ file?.name.substring(0, 32) }}
            <button
              mat-button
              class="more-button"
              matTooltip="{{ file?.name }}"
              matTooltipClass="tooltip-description"
            >...
            </button>
          </h4>
          <h4 class="name" *ngIf="file?.name.length <= 35">
            {{ file?.name }}
          </h4>

          <p class="size">
            {{ formatBytes(file?.size) }}
          </p>
          <app-file-progress [progress]="file?.progress"></app-file-progress>
        </div>

        <mat-spinner
          *ngIf="loadingFile[i]"
          class="delete"
          style="display: table;"
          strokeWidth="2"
          [diameter]="20"
        ></mat-spinner>

        <mat-icon
          *ngIf="!loadingFile[i] && file?.progress === 100 && file?.success === true"
          class="delete"
          style="color: green"
        >check</mat-icon>

        <mat-icon
          *ngIf="!loadingFile[i] && file?.success === false"
          class="delete"
          style="color: red"
          [matTooltip]="failMessage[i]"
        >close</mat-icon>
        <img *ngIf="!loadingFile[i] && file?.success === false" src="assets/img/ic-delete.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
      </div>
    </div>

  </div>
</div>
