<div class="messageContainer">
    <ul class="message-item" *ngFor="let msg of msgList">
        <mat-card
          fxLayout.gt-sm="row"
          fxLayout="column"
          fxLayoutGap.gt-sm="5"
          fxLayoutGap="1"
          fxLayoutAlign="space-between center"
          fxLayoutAlign.gt-sm="space-between center"
        >
            <div fxFlex.gt-sm="10" fxFlex="100" fxLayoutAlign="center" [ngClass]="getButtonClass(msg.type)">
                {{ getMsgText(msg.type) }}
            </div>
            <mat-card-content fxFlex.gt-sm="75" fxFlex="100" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="center center" [ngClass]="getTextClass(msg.type)">
                <span>{{msg.content}}</span>
            </mat-card-content>
            <button fxFlex.gt-sm="5" fxFlex="100" fxLayoutAlign="center" mat-raised-button type="button" (click)="removeMsg(msg)">
                <i *ngIf="!msg.disabled" class="material-icons">close</i>
            </button>
        </mat-card>
    </ul>
</div>
