<div
  fxLayout="column"
  fxLayoutGap="20px"
>
  <app-msg-banner *ngIf="showNotification" [msgList]="messageList"
                  (msgListChange)="messageList = $event"></app-msg-banner>
  <div
    fxFlex
    fxLayout="row"
  >
    <div
      fxFlex
      fxLayoutAlign="start"
      class="title"
    >Search Stores
    </div>

    <div
      fxFlex
      fxLayoutAlign="end"
    >
      <mat-icon
        (click)="closeDialog()"
        class="close-button"
      >close
      </mat-icon>
    </div>
  </div>


  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="20px"
  >

    <!-- Filter menu -->
    <mat-accordion
      displayMode="flat"
    >
      <mat-expansion-panel
        [expanded]="true"
      >
        <mat-expansion-panel-header>
          <span class="material-icons filter-title">filter_alt</span>
          <span class="filter-title">Filter Stores</span>
        </mat-expansion-panel-header>

        <div
          *ngIf="!isLoading"
        >
          <form
            fxLayout="row wrap"
            fxLayoutGap="3%"
            fxLayout.lt-md="column"
            fxLayoutGap.lt-md="0%"
            (ngSubmit)="onSearch()"
            [formGroup]="searchForm"
          >
            <mat-form-field
              fxFlex="30%"
              fxFlex.lt-md="100%"
            >
              <mat-label>Account Name</mat-label>
              <input formControlName="acctName" matInput placeholder="Account Name" type="text"/>
            </mat-form-field>

            <mat-form-field
              fxFlex="30%"
              fxFlex.lt-md="100%"
            >
              <mat-label>Account</mat-label>
              <input formControlName="chain" matInput placeholder="Account" type="text"/>
            </mat-form-field>

            <mat-form-field
              fxFlex="30%"
              fxFlex.lt-md="100%"
            >
              <mat-label>Store Nbr</mat-label>
              <input formControlName="storeCode" matInput placeholder="Store Nbr" type="text"/>
            </mat-form-field>

            <mat-form-field
              fxFlex="30%"
              fxFlex.lt-md="100%"
            >
              <mat-label>Store Own Ref</mat-label>
              <input formControlName="storeOwnRef" matInput placeholder="Store Own Ref" type="text"/>
            </mat-form-field>

            <mat-form-field
              fxFlex="30%"
              fxFlex.lt-md="100%"
            >
              <mat-label>Address</mat-label>
              <input formControlName="address" matInput placeholder="Address" type="text"/>
            </mat-form-field>

            <mat-form-field
              fxFlex="30%"
              fxFlex.lt-md="100%"
            >
              <mat-label>Territory</mat-label>
              <input formControlName="territory" matInput placeholder="Territory" type="text"/>
            </mat-form-field>

            <div
              fxLayout="row"
              fxLayoutGap="10px"
              fxLayout.lt-md="column"
              class="buttons"
            >
              <button
                fxFlex="100px"
                fxFlex.lt-md="100%"
                class="btn btn-primary"
                mat-raised-button
                style="background-color: #42a5f5"
                type="submit"
                [disabled]="searchDisabled"
              >Search
              </button>
              <button
                fxFlex="100px"
                fxFlex.lt-md="100%"
                class="btn btn-primary"
                mat-raised-button
                (click)="onSearchReset()"
                style="background-color: #f44336"
              >Clear
              </button>
            </div>
          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div
      class="scrollable fixed-table"
    >
      <mat-card style="text-align: center" *ngIf="!dataSource.data.length && !isLoadingResults">
        <b>No Records Found!</b>
      </mat-card>

      <mat-spinner style="margin: 0 auto" *ngIf="isLoadingResults"></mat-spinner>

      <table
        [hidden]="isLoading"
        [dataSource]="dataSource"
        *ngIf="dataSource.data.length && !isLoadingResults"
        mat-table
        matSort
        class="mat-elevation-z2"
      >

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th class="w-10" mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)"
                          [disabled]="disableRow(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="accountName">
          <th *matHeaderCellDef class="w-200" mat-header-cell mat-sort-header>Account Name</th>
          <td *matCellDef="let row" mat-cell>{{ row.nam }}</td>
        </ng-container>

        <ng-container matColumnDef="chain">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>Account</th>
          <td *matCellDef="let row" mat-cell>{{ row.chain }}</td>
        </ng-container>

        <ng-container matColumnDef="storeCode">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>Store Nbr</th>
          <td *matCellDef="let row" mat-cell>{{ row.str }}</td>
        </ng-container>

        <ng-container matColumnDef="storeOwnRef">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>Store Own Ref</th>
          <td *matCellDef="let row" mat-cell>{{ row.strOwnRef }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th *matHeaderCellDef class="w-200" mat-header-cell mat-sort-header>Address</th>
          <td *matCellDef="let row" mat-cell>{{ row.stAdr }}</td>
        </ng-container>

        <ng-container matColumnDef="territory">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>Territory</th>
          <td *matCellDef="let row" mat-cell>{{ row.terr }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="!disableRow(row) && selection.toggle(row)"
            [ngStyle]="disableRow(row) && {'background-color': 'lightgrey'}"></tr>
      </table>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="start"
    >
      <mat-paginator
        fxShow.lt-md
        fxHide
        [hidden]="!dataSource.data.length"
        [length]="resultsLength" [pageSizeOptions]="[10, 25, 50, 75, 100]" [pageSize]="50"
        (page)="onPageChange($event)">
      </mat-paginator>

      <button
        fxFlex.lt-md="100%"
        [hidden]="!dataSource.data.length"
        (click)="addStores()"
        [disabled]="selection.selected.length === 0"
        class="btn btn-primary"
        mat-raised-button
        style="background-color: #42a5f5"
      >Add Selected Stores
      </button>

      <mat-paginator
        fxShow
        fxHide.lt-md
        [hidden]="!dataSource.data.length"
        [length]="resultsLength" [pageSizeOptions]="[10, 25, 50, 75, 100]" [pageSize]="50"
        (page)="onPageChange($event)">
      </mat-paginator>
    </div>

  </mat-dialog-content>
</div>
