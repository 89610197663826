import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CounterService {
  private activityCounter = new Subject<boolean>();
  private notificationCounter = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  listenRefreshActivityCounter(): Observable<boolean> {
    return this.activityCounter.asObservable();
  }
  refreshActivityCounter(refresh: boolean) {
    this.activityCounter.next(refresh);
  }

  listenRefreshNotificationCounter(): Observable<boolean> {
    return this.notificationCounter.asObservable();
  }
  refreshNotificationCounter(refresh: boolean) {
    this.notificationCounter.next(refresh);
  }

  getActivityPublishCount(): Observable<number> {
    return this.http.get<number>(environment.apiResourceUri + 'activities/review/count');
  }

  getNotificationPublishCount(): Observable<number> {
    return this.http.get<number>(environment.apiResourceUri + 'publish/notifications/count');
  }
}
