export class AttachmentConfig {
  view: AttachmentView;
  text?: string;
  isArchived: boolean;
  limit: number;
}

export enum AttachmentView {
  ADMIN = 1,
  ACTIVITY = 2,
  NOTIFICATION = 3,
  NOTIFICATION_TERRITORY = 4,
  TEMP = 5,
  ADMIN_DOCUMENTS = 6,
  VIEW_GENERAL_DOCUMENTS = 7,
  VIEW_CHAIN_DOCUMENTS = 8,
  ROOM_DOCUMENTS = 9
}
