import {Inject, Injectable} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import {MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import * as config from '../../modules/app-config.json';
import { InteractionRequiredAuthError, AuthError } from 'msal';
import {GraphService} from '../graph/graph.service';
import {AuthenticationResult, PopupRequest} from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class GroupGuardService implements CanActivate {
  groups: string[] = [];

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private service: GraphService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.service.user.displayName = accounts[0].idTokenClaims['preferred_username'];

      if (accounts[0].idTokenClaims['groups']) {
        this.service.user.groupIDs = <string[]>(
          (<unknown>accounts[0].idTokenClaims['groups'])
        );
      }

      const expectedGroup = route.data.expectedGroup;

      this.service.user.displayName = accounts[0].idTokenClaims['preferred_username'];
      if (this.service.user.groupIDs.length === 0) {
        if (accounts[0].idTokenClaims['hasgroups']) {
          window.alert(
            'You have too many group memberships. The application will now query Microsoft Graph to get the full list of groups that you are a member of.'
          );
          this.handleResponse();
          return false;
        }

        window.alert('Token does not have groups claim');
        return false;
      } else if (!this.service.user.groupIDs.includes(expectedGroup)) {
        window.alert('You do not have access for this');
        return false;
      }
    }

    return true;
  }

  handleResponse(): void {
    this.service.getGroups().subscribe({
      next: (response: any) => {
        response.value.map((v) => this.groups.push(v.id));

        /**
         * Some queries against Microsoft Graph return multiple pages of data either due to server-side paging
         * or due to the use of the $top query parameter to specifically limit the page size in a request.
         * When a result set spans multiple pages, Microsoft Graph returns an @odata.nextLink property in
         * the response that contains a URL to the next page of results.
         * learn more at https://docs.microsoft.com/graph/paging
         */
        if (response['@odata.nextLink']) {
          this.handleNextPage(response['@odata.nextLink']);
        } else {
          if (this.groups.includes(config.groups.groupAllUsers)) {
            this.service.user.groupIDs.push(config.groups.groupAllUsers);
          }
        }

        console.log(this.groups);
      },
      error: (err: AuthError) => {
        console.log(err);
        // If there is an interaction required error,
        // call one of the interactive methods and then make the request again.
        if (
          InteractionRequiredAuthError.isInteractionRequiredError(err.errorCode)
        ) {
          this.loginPopup();
        }
      },
    });
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          this.handleGroups();
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          this.handleGroups();
        });
    }
  }

  handleGroups() {
    this.service
      .getGroups()
      .toPromise()
      .then((response: any) => {
        response.value.map((v) => this.groups.push(v.id));

        if (response['@odata.nextLink']) {
          this.handleNextPage(response['@odata.nextLink']);
        } else {
          if (this.groups.includes(config.groups.groupAllUsers)) {
            this.service.user.groupIDs.push(
              config.groups.groupAllUsers
            );
          }
        }
        console.log(this.groups);
      });
  }

  handleNextPage(nextPage): void {
    this.service.getNextPage(nextPage).subscribe((response: any) => {
      response.value.map((v) => {
        if (!this.groups.includes(v.id)) {
          this.groups.push(v.id);
        }
      });

      if (response['@odata.nextLink']) {
        this.handleNextPage(response['@odata.nextLink']);
      } else {
        if (this.groups.includes(config.groups.groupAllUsers)) {
          this.service.user.groupIDs.push(config.groups.groupAllUsers);
        }
      }
    });
  }
}
