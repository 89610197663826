export class PortalUser {
  id: number;
  usrNam: string;
  fullNam?: string;
  usrRole: string;
  chains: string[];
  terrs: string[];

  constructor() {
    this.id = null;
    this.usrNam = null;
    this.fullNam = null;
    this.usrRole = null;
    this.chains = [];
    this.terrs = []
  }
}
