import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {IPagedResponse} from '../../shared/models/IPagedResponse';
import {ActivityCompliance} from '../../shared/models/activity/activity-compliance.model';
import {UtilsService} from './utils.service';
import {Activity} from '../../shared/models/activity/activity.model';
import {ComplianceFilter} from '../../shared/models/activity/compliance-filter.model';
import {StringResponse} from '../../shared/models/string-response';

@Injectable()
export class ActivityComplianceService {
  private header = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) {}



  getActivities(pageIndex: number, pageSize: number, searchFields: any): Observable<IPagedResponse<ActivityCompliance>> {
    let params = null;

    params = new HttpParams();
    if (searchFields != null || typeof searchFields !== 'undefined') {
      Object.keys(searchFields).map(function (key) {
        if (searchFields[key] !== null && searchFields[key] !== undefined && searchFields[key] !== '') {
          const date = UtilsService.dateToServerDate(searchFields[key]);
          if (key === 'startFirstDate' || key === 'startSecondDate'
            || key === 'endFirstDate' || key === 'endSecondDate') {
            params = params.append(
              key,
              Math.floor(date.getTime() / (24 * 60 * 60 * 1000))
            );
          } else if (key === 'updatedFirstDate') {
            date.setHours(0, 0, 0, 0);
            params = params.append(
              key,
              Math.floor(date.getTime() / 1000)
            );
          } else if (key === 'updatedSecondDate') {
            date.setHours(23, 59, 59, 59);
            params = params.append(
              key,
              Math.floor(date.getTime() / 1000)
            );
          } else {
            params = params.append(key, searchFields[key]);
          }
        }
      });
    }

    if (!pageIndex || typeof pageIndex === 'number') {
      params = params.append('page', pageIndex);
    }
    if (!pageSize || typeof pageSize === 'number') {
      params = params.append('size', pageSize);
    } else {
      params = params.append('size', 10);
    }

    return this.http.get(environment.apiResourceUri + 'activities/compliance/search',
      { params: params })
      .pipe(map((res: IPagedResponse<ActivityCompliance>) => {
          return res;
        })
      );
  }



  getAllActivities(searchFields: any, isMetrics: boolean): Observable<any[]> {
    let params = null;

    params = new HttpParams();
    if (searchFields != null || typeof searchFields !== 'undefined') {
      Object.keys(searchFields).map(function (key) {
        if (searchFields[key] !== null && searchFields[key] !== undefined && searchFields[key] !== '') {
          const date = UtilsService.dateToServerDate(searchFields[key]);
          if (key === 'startFirstDate' || key === 'startSecondDate'
            || key === 'endFirstDate' || key === 'endSecondDate') {
            params = params.append(
              key,
              Math.floor(date.getTime() / (24 * 60 * 60 * 1000))
            );
          } else if (key === 'updatedFirstDate') {
            date.setHours(0, 0, 0, 0);
            params = params.append(
              key,
              Math.floor(date.getTime() / 1000)
            );
          } else if (key === 'updatedSecondDate') {
            date.setHours(23, 59, 59, 59);
            params = params.append(
              key,
              Math.floor(date.getTime() / 1000)
            );
          } else {
            params = params.append(key, searchFields[key]);
          }
        }
      });
    }

    let path = 'activities/compliance/export-info';
    if (isMetrics) {
      path = 'activities/compliance/metrics';
    }
    return this.http.get(environment.apiResourceUri + path, { params: params })
      .pipe(map((res: any[]) => {
          return res;
        })
      );
  }

  updateResponse(actId: string, respId: string, cmnt: string, actualShprCt: number, isCompleted: boolean, uploadPhotos: boolean, checked: boolean): Observable<Activity> {
    const data = {
      actId: actId,
      respId: respId,
      isCompleted: isCompleted,
      cmnt: cmnt,
      actualShprCt: actualShprCt,
      uploadPhotos : uploadPhotos,
      checked : checked
    };
    return this.http.put<Activity>(environment.apiResourceUri + 'activities/compliance', data);
  }

  moveToOpen(actId: number): Observable<Activity> {
    return this.http.put<Activity>(environment.apiResourceUri + 'activities/compliance/open', actId);
  }

  getTitles(searchFields: any): Observable<string[]> {
    let params = null;

    params = new HttpParams();
    if (searchFields != null || typeof searchFields !== 'undefined') {
      Object.keys(searchFields).map(function (key) {
        if (searchFields[key] !== null && searchFields[key] !== undefined && searchFields[key] !== '') {
          const date = UtilsService.dateToServerDate(searchFields[key]);
          if (key === 'startFirstDate' || key === 'startSecondDate'
            || key === 'endFirstDate' || key === 'endSecondDate') {
            params = params.append(
              key,
              Math.floor(date.getTime() / (24 * 60 * 60 * 1000))
            );
          } else if (key === 'updatedFirstDate') {
            date.setHours(0, 0, 0, 0);
            params = params.append(
              key,
              Math.floor(date.getTime() / 1000)
            );
          } else if (key === 'updatedSecondDate') {
            date.setHours(23, 59, 59, 59);
            params = params.append(
              key,
              Math.floor(date.getTime() / 1000)
            );
          } else {
            params = params.append(key, searchFields[key]);
          }
        }
      });
    }

    return this.http.get(environment.apiResourceUri + 'activities/compliance/titles', { params: params })
      .pipe(map((res: string[]) => {
          return res;
        })
      );
  }
  exportItemsAndPhotos(pageIndex: number, pageSize: number, searchFields: ComplianceFilter): Observable<StringResponse> {
    let params = null;

    params = new HttpParams();
    if (searchFields != null || typeof searchFields !== 'undefined') {
      Object.keys(searchFields).map(function (key) {
        if (searchFields[key] !== null && searchFields[key] !== undefined && searchFields[key] !== '') {
          const date = UtilsService.dateToServerDate(searchFields[key]);
          if (key === 'startFirstDate' || key === 'startSecondDate'
            || key === 'endFirstDate' || key === 'endSecondDate') {
            params = params.append(
              key,
              Math.floor(date.getTime() / (24 * 60 * 60 * 1000))
            );
          } else if (key === 'updatedFirstDate') {
            date.setHours(0, 0, 0, 0);
            params = params.append(
              key,
              Math.floor(date.getTime() / 1000)
            );
          } else if (key === 'updatedSecondDate') {
            date.setHours(23, 59, 59, 59);
            params = params.append(
              key,
              Math.floor(date.getTime() / 1000)
            );
          } else {
            params = params.append(key, searchFields[key]);
          }
        }
      });
    }

    if (!pageIndex || typeof pageIndex === 'number') {
      params = params.append('page', pageIndex);
    }
    if (!pageSize || typeof pageSize === 'number') {
      params = params.append('size', pageSize);
    } else {
      params = params.append('size', 10);
    }

    return this.http.get(environment.apiResourceUri + 'activities/compliance/excel-export', { params: params })
      .pipe(map((res: StringResponse) => {
          return res;
        })
      );
  }
}
