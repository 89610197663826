import { Component, OnInit } from '@angular/core';
import {GenericActivityView} from '../../../../shared/models/activity/generic-activity-view.model';

@Component({
  selector: 'app-publish-activity',
  templateUrl: './publish-activity.component.html',
  styleUrls: ['./publish-activity.component.scss']
})
export class PublishActivityComponent implements OnInit {
  config: GenericActivityView = {
    title: 'Publish Activity',
    page: 'publish'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
