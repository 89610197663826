import {Component, OnInit} from '@angular/core';
import {GenericActivityView} from '../../../../shared/models/activity/generic-activity-view.model';

@Component({
  selector: 'app-manage-activity',
  templateUrl: './manage-activity.component.html',
  styleUrls: ['./manage-activity.component.scss']
})
export class ManageActivityComponent implements OnInit {
  config: GenericActivityView = {
    title: 'Manage Activity',
    page: 'manage'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
