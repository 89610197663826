<div
  class="wrapper"
>
  <app-msg-banner
    *ngIf="showNotification" [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <mat-card
    fxLayout="column"
    fxLayoutGap="20px"
    class="mat-elevation-z2"
  >
    <!-- Filter menu -->
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <span class="material-icons filter-title">filter_alt</span>
          <span class="filter-title">Filter Activities</span>
        </mat-expansion-panel-header>

        <div
          *ngIf="!isLoading"
        >
          <form
            fxLayout="column"
            [formGroup]="searchForm"
          >
            <div
              fxLayout="row wrap"
              fxLayoutGap="3%"
              fxLayout.lt-md="column"
              fxLayoutGap.lt-md="0%"
            >
              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
              >
                <mat-label>Activity Title</mat-label>
                <input formControlName="title" matInput placeholder="Activity Title" type="text"/>
                <mat-error *ngIf="searchForm.controls.title.errors?.pattern && searchForm.controls.title.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="!chainRestricted && this.config.page !== 'manage'"
              >
                <mat-label>Account</mat-label>
                <input formControlName="chain" matInput placeholder="Chain" type="text"/>
                <mat-error *ngIf="searchForm.controls.chain.errors?.pattern && searchForm.controls.chain.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="chainRestricted && this.config.page !== 'manage'"
              >
                <mat-label>Account</mat-label>
                <mat-select
                  formControlName="chain"
                  placeholder="Chain"
                  [multiple]="true"
                  #chainMultiSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [showToggleAllCheckbox]="true"
                      (toggleAll)="toggleChainsSelectAll($event)"
                      [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                      [toogleAllCheckboxTooltipPosition]="'above'"
                      [formControl]="chainMultiFilterCtrl"
                      placeholderLabel="Find chain..."
                      noEntriesFoundLabel="'No matching chain found'"
                    >
                      <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let chain of filteredChainsMulti | async"
                    [value]="chain"
                  >
                    {{ getChainNameById(chain) }} ({{ chain }})
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="this.config.page != 'manage'"
              >
                <mat-label>Store Nbr</mat-label>
                <input formControlName="store" matInput placeholder="Store Nbr" type="text"/>
                <mat-error *ngIf="searchForm.controls.store.errors?.pattern && searchForm.controls.store.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
              >
                <mat-label>Type</mat-label>
                <mat-select
                  [disabled]="this.config.page === 'manage' || isTerrOrMerchManager()"
                  [formControl]="searchForm.controls['type']"
                >
                  <mat-option>--All--</mat-option>
                  <mat-option *ngFor="let type of activityType" [value]="type.actTypeId">
                    <span *ngIf="type.actTypeId !== getActivityTypes.SHIPPER_COUNT">{{ type.en }}</span>
                    <span *ngIf="type.actTypeId === getActivityTypes.SHIPPER_COUNT">{{ getEnvironment.shipperName }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="this.config.page !== 'manage'"
              >
                <mat-label>Season</mat-label>
                <mat-select formControlName="season">
                  <mat-option>--All--</mat-option>
                  <mat-option *ngFor="let season of seasons | activeFilter:filterArgs" value="{{ season.seasId }}">
                    {{ season.en }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="this.config.page != 'manage'"
              >
                <mat-label>Location</mat-label>
                <mat-select formControlName="location">
                  <mat-option>--All--</mat-option>
                  <mat-option *ngFor="let location of locations | activeFilter:filterArgs" value="{{ location.locId }}">
                    {{ location.en }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                *ngIf="!terrRestricted && this.config.page !== 'manage'"
                fxFlex="30%"
                fxFlex.lt-md="100%"
              >
                <mat-label>Territory</mat-label>
                <input
                  matInput
                  [formControl]="searchForm.controls['territory']"
                  maxlength="5"
                />
                <mat-error *ngIf="searchForm.controls.territory.errors?.pattern && searchForm.controls.territory.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-form-field
                *ngIf="terrRestricted && this.config.page !== 'manage'"
                fxFlex="30%"
                fxFlex.lt-md="100%"
              >
                <mat-label>Territory</mat-label>
                <mat-select
                  formControlName="territory"
                  placeholder="Territory"
                  [multiple]="true"
                  #terrMultiSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [showToggleAllCheckbox]="true"
                      (toggleAll)="toggleTerrsSelectAll($event)"
                      [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                      [toogleAllCheckboxTooltipPosition]="'above'"
                      [formControl]="terrMultiFilterCtrl"
                      placeholderLabel="Find territory..."
                      noEntriesFoundLabel="'No matching territory found'"
                    >
                      <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let terr of filteredTerrsMulti | async"
                    [value]="terr"
                  >
                    {{ terr }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
              >
                <mat-label>Start Date Range</mat-label>
                <mat-date-range-input [rangePicker]="startPicker">
                  <input matStartDate formControlName="startBeginDate"
                         placeholder="Start date">
                  <input matEndDate formControlName="startFinishDate"
                         placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="startPicker" (click)="startPicker.open()"></mat-datepicker-toggle>
                <mat-date-range-picker #startPicker></mat-date-range-picker>

                <mat-error *ngIf="searchForm.controls.startBeginDate.hasError('matStartDateInvalid')">
                  Invalid start date</mat-error>
                <mat-error *ngIf="searchForm.controls.startFinishDate.hasError('matEndDateInvalid')">
                  Invalid end date</mat-error>
                <mat-error *ngIf="searchForm.controls.startBeginDate.errors?.pattern && searchForm.controls.startBeginDate.dirty">
                  * Input not allowed
                </mat-error>
                <mat-error *ngIf="searchForm.controls.startFinishDate.errors?.pattern && searchForm.controls.startFinishDate.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
              >
                <mat-label>End Date Range</mat-label>
                <mat-date-range-input [rangePicker]="endPicker">
                  <input matStartDate formControlName="endBeginDate"
                         placeholder="Start date">
                  <input matEndDate formControlName="endFinishDate"
                         placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="endPicker" (click)="endPicker.open()"></mat-datepicker-toggle>
                <mat-date-range-picker #endPicker></mat-date-range-picker>

                <mat-error *ngIf="searchForm.controls.endBeginDate.hasError('matStartDateInvalid')">
                  Invalid start date</mat-error>
                <mat-error *ngIf="searchForm.controls.endFinishDate.hasError('matEndDateInvalid')">
                  Invalid end date</mat-error>
                <mat-error *ngIf="searchForm.controls.endBeginDate.errors?.pattern && searchForm.controls.endBeginDate.dirty">
                  * Input not allowed
                </mat-error>
                <mat-error *ngIf="searchForm.controls.endFinishDate.errors?.pattern && searchForm.controls.endFinishDate.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="this.config.page != 'manage'"
              >
                <mat-label>Store Own Ref</mat-label>
                <input formControlName="storeOwnRef" matInput placeholder="Store Own Ref" type="text"/>
                <mat-error *ngIf="searchForm.controls.storeOwnRef.errors?.pattern && searchForm.controls.storeOwnRef.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="config.page === 'manage'"
              >
                <mat-label>Activity ID</mat-label>
                <input formControlName="activityId" matInput placeholder="Activity ID" type="text"/>
                <mat-error *ngIf="searchForm.controls.activityId.errors?.pattern && searchForm.controls.activityId.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="config.page === 'manage'"
              >
                <mat-label>Person Responsible</mat-label>
                <input type="text"
                       placeholder="Person Responsible"
                       matInput
                       [formControl]="searchForm.controls['personResponsible']"
                       [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="valueMapper">
                  <mat-option *ngFor="let usr of filteredUsers" [value]="usr">
                    {{ usr.nam }} ({{usr.usrId }})
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="config.page === 'publish'"
              >
                <mat-label>Created By</mat-label>
                <input type="text"
                       placeholder="Created By"
                       matInput
                       [formControl]="searchForm.controls['createdBy']"
                       [matAutocomplete]="auto1">
                <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                  <mat-option *ngFor="let usr of filteredPortalUsers" [value]="usr.mail">
                    {{ usr.displayName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="config.page === 'manage'"
              >
                <mat-label>Activity Status</mat-label>
                <mat-select
                  formControlName="sta"
                  placeholder="Activity Status"
                  [multiple]="true"
                  (selectionChange)="onChange($event)"
                  #staMultiSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [showToggleAllCheckbox]="true"
                      (toggleAll)="toggleStatusSelectAll($event)"
                      [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                      [toogleAllCheckboxTooltipPosition]="'above'"
                      [formControl]="staMultiFilterCtrl"
                      placeholderLabel="Find activity status..."
                      noEntriesFoundLabel="'No matching chain found'"
                    >
                      <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let sta of filteredStaMulti | async"
                    [value]="sta"
                  >
                    {{ getActivityStatusById(sta) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                fxFlex="30%"
                fxFlex.lt-md="100%"
                *ngIf="config.page === 'manage'"
              >
                <mat-label>Attachment</mat-label>
                <input formControlName="attach" matInput placeholder="Attachment" type="text"/>
                <mat-error *ngIf="searchForm.controls.attach.errors?.pattern && searchForm.controls.attach.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-slide-toggle
                fxHide
                fxShow.lt-md
                fxFlex.lt-md="100%"
                formControlName="expiredOld"
                *ngIf="config.page === 'manage'"
              >Expired (> 14 days)</mat-slide-toggle>
            </div>

            <div
              fxFlex
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="space-between center"
              fxLayoutAlign.lt-md="start"
              class="buttons"
            >
              <div
                fxLayoutAlign="start"
                fxLayout="row"
                fxLayout.lt-md="column"
              >
                <button
                  fxFlex.lt-md="100%"
                  class="btn btn-primary"
                  mat-raised-button
                  style="background-color: #42a5f5"
                  type="button"
                  (click)="onSearch()"
                  [disabled]="this.searchForm.invalid || searchDisabled"
                >Search</button>
                <button
                  fxFlex.lt-md="100%"
                  class="btn btn-primary"
                  mat-raised-button
                  (click)="onSearchReset()"
                  style="background-color: #f44336"
                  type="button"
                >Clear</button>
              </div>

              <mat-slide-toggle
                fxHide.lt-md
                fxShow
                fxFlex.lt-md="100%"
                fxLayoutAlign="end"
                formControlName="expiredOld"
                *ngIf="config.page === 'manage'"
              >Expired (> 14 days)</mat-slide-toggle>
            </div>

          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Table view -->
    <div
      class="scrollable"
    >
      <mat-card
        class="mat-elevation-z0"
        style="text-align: center"
        *ngIf="!dataSource.data.length && !isLoadingResults"
      >
        <b *ngIf="requestSent">No Records Found!</b>
      </mat-card>

      <table
        [hidden]="isLoading"
        [dataSource]="dataSource"
        *ngIf="dataSource.data.length && !isLoadingResults"
        [ngSwitch]="this.config.page"
        mat-table
        matSort
        class="mat-elevation-z2"
      >

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="activityId">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>Activity ID</th>
          <td *matCellDef="let row" mat-cell>{{ getActivityId(row.id) }}</td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th *matHeaderCellDef class="w-300" mat-header-cell mat-sort-header>Activity Title</th>
          <td *matCellDef="let row" mat-cell>
                    <span *ngIf="row.title.length > 35">
                      {{ row.title.substring(0, 32) }}
                      <button
                        mat-button
                        class="more-button"
                        matTooltip="{{ row.title }}"
                        matTooltipClass="tooltip-description">
                        ...</button>
                    </span>
            <span *ngIf="row.title.length <= 35">
                      {{ row.title }}
                    </span>
          </td>
        </ng-container>

        <!-- <ng-container *ngSwitchCase="'manage'" matColumnDef="chain">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>Account</th>
          <td *matCellDef="let row" mat-cell>{{ row.chain }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="'manage'" matColumnDef="store">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>Store</th>
          <td *matCellDef="let row" mat-cell>{{ row.str }}</td>
        </ng-container> -->

        <ng-container matColumnDef="startDate">
          <th *matHeaderCellDef class="w-150" mat-header-cell mat-sort-header>Start Date</th>
          <td *matCellDef="let row" mat-cell>{{ row.start | dateFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th *matHeaderCellDef class="w-150" mat-header-cell mat-sort-header>End Date</th>
          <td *matCellDef="let row" mat-cell>{{ row.end | dateFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="expirationDate">
          <th *matHeaderCellDef class="w-150" mat-header-cell mat-sort-header>Expiration Date</th>
          <td *matCellDef="let row" mat-cell>{{ row.exp | dateFormat}}</td>
        </ng-container>

        <ng-container *ngSwitchCase="'publish'" matColumnDef="type">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>Type</th>
          <td *matCellDef="let row" mat-cell>{{ getActivityType(row.actType) }}</td>
        </ng-container>

        <!-- <ng-container *ngIf="config.page == 'manage'" matColumnDef="shipperCount">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>{{ getEnvironment.shipperName }}</th>
          <td *matCellDef="let row" mat-cell>{{ row.shprCt == null ? 'N/A' : row.shprCt }}</td>
        </ng-container> -->

        <ng-container *ngSwitchCase="'publish'" matColumnDef="season">
          <th *matHeaderCellDef class="w-150" mat-header-cell mat-sort-header>Season</th>
          <td *matCellDef="let row" mat-cell>{{ getSeason(row.seas) }}</td>
        </ng-container>

        <ng-container *ngSwitchCase="'publish'" matColumnDef="location">
          <th *matHeaderCellDef class="w-150" mat-header-cell mat-sort-header>Location</th>
          <td *matCellDef="let row" mat-cell>{{ getLocation(row.loc) }}</td>
        </ng-container>

        <ng-container *ngIf="config.page == 'manage'" matColumnDef="personResponsible">
          <th *matHeaderCellDef class="w-150" mat-header-cell mat-sort-header>Person Responsible</th>
          <td *matCellDef="let row" mat-cell>{{ row?.usrNam }}</td>
        </ng-container>

        <ng-container *ngIf="config.page == 'publish'" matColumnDef="attach">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>Attachment</th>
          <td *matCellDef="let row" mat-cell>{{ returnAttachmentType(row.attach) }}</td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th *matHeaderCellDef class="w-200" mat-header-cell mat-sort-header>Created By</th>
          <td *matCellDef="let row" mat-cell>
                      <span *ngIf="row.createdBy?.length > 10">
                        {{ row.createdBy?.substring(0, 10) }}
                        <button
                          mat-button
                          class="more-button"
                          matTooltip="{{ row.createdBy }}"
                          matTooltipClass="tooltip-description">
                          ...</button>
                      </span>
            <span *ngIf="row.createdBy?.length <= 10">
                        {{ row.createdBy }}
                      </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="publishedBy">
          <th *matHeaderCellDef class="w-200" mat-header-cell mat-sort-header>Published By</th>
          <td *matCellDef="let row" mat-cell>
                      <span *ngIf="publishedBy(row)?.length > 10">
                        {{ publishedBy(row)?.substring(0, 10) }}
                        <button
                          mat-button
                          class="more-button"
                          matTooltip="{{ publishedBy(row) }}"
                          matTooltipClass="tooltip-description">
                          ...</button>
                      </span>
            <span *ngIf="publishedBy(row)?.length <= 10">
                        {{ publishedBy(row) }}
                      </span>
          </td>
        </ng-container>


        <ng-container matColumnDef="taskStatus">
          <th *matHeaderCellDef class="w-75" mat-header-cell mat-sort-header>Status</th>
          <td *matCellDef="let row" mat-cell>{{ row.sta ? getStatusName(row.sta) : 'Review' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.selected.length > 1 ? doNothing() : editSelected(row)"
        >
        </tr>
      </table>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="start"
    >
      <mat-paginator
        fxShow.lt-md
        fxHide
        [hidden]="!dataSource.data.length"
        [length]="resultsLength"
        [pageSizeOptions]="[10, 25, 50, 75, 100]"
        [pageSize]="50"
        (page)="onPageChange($event)"
      ></mat-paginator>

      <div
        [hidden]="!dataSource.data.length"
        [ngSwitch]="config.page"
      >
        <button
          fxFlex.lt-md="100%"
          [disabled]="selection.selected.length === 0 || selection.selected.length === 1"
          *ngSwitchCase="'manage'"
          (click)="editSelected(null)"
          class="btn btn-primary"
          mat-raised-button
          style="background-color: #42a5f5"
        >Edit Selected</button>

        <button
          fxFlex.lt-md="100%"
          [disabled]="selection.selected.length === 0 || selection.selected.length === 1"
          *ngSwitchCase="'manage'"
          (click)="deleteSelected()"
          class="btn btn-primary"
          mat-raised-button
          style="background-color: #f44336; margin-left: 10px;"
        >Delete Selected</button>

        <button
          fxFlex.lt-md="100%"
          (click)="publishSelected()"
          [disabled]="selection.selected.length === 0"
          class="btn btn-primary"
          mat-raised-button
          *ngSwitchCase="'publish'"
          style="background-color: #42a5f5"
        >Publish</button>
      </div>

      <mat-paginator
        fxShow
        fxHide.lt-md
        [hidden]="!dataSource.data.length"
        [length]="resultsLength"
        [pageSizeOptions]="[10, 25, 50, 75, 100, 150, 200]"
        [pageSize]="50"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </div>

  </mat-card>
</div>
