import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/timeoutWith';
import { environment } from '../../../environments/environment';

@Injectable()
export class CompliantMessagesService {
  constructor(private http: HttpClient) {}

  uploadPhoto(actId: string, file: File, timestamp: number): Observable<any> {
    const data: FormData = new FormData();
    data.append('file', file);
    data.append('actId', actId);
    data.append('epoch', '' + timestamp);

    return this.http.post(environment.apiResourceUri + 'activities/compliance', data, { responseType: 'text' });
  }
}
