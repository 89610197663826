import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../models/dialog';
import {PersonResponsibleFile} from '../../models/activity/import-person-responsible.model';
import {MatDialog} from '@angular/material/dialog';
import {ReaderService} from '../../../core/services/reader.service';
import {User} from '../../models/activity/user.model';
import {NewActivityService} from '../../../core/services/new-activity.service';

@Component({
  selector: 'app-load-person-container',
  templateUrl: './load-person-container.component.html',
  styleUrls: ['./load-person-container.component.scss']
})
export class LoadPersonContainerComponent implements OnInit {

  @Input() personResponsible: User[];
  @Input() terrs: string[];
  @Output() errorEmitter = new EventEmitter<string>();
  @Output() successEmitter = new EventEmitter<User[]>();

  @ViewChild('fileInput') fileInput: ElementRef;
  private fileSelected: File = null;
  private usersFromFile: PersonResponsibleFile[] = [];

  constructor(
    private dialog: MatDialog,
    private readerService: ReaderService,
    private newActivityService: NewActivityService
  ) { }

  ngOnInit(): void {
  }

  onFileSelected(event) {
    this.fileSelected = <File>event.target.files[0];
    if (this.fileNameExtensionIsValid(this.fileSelected.name) === 1) {
      this.readFromXlsxFile(event);
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: new Dialog('The file you loaded does not have xlsx extension.', false, false),
        disableClose: true,
      });
    }
  }

  fileNameExtensionIsValid(fileName): number {
    if (
      fileName.substring(
        this.fileSelected.name.length - 5,
        this.fileSelected.name.length
      ) === '.xlsx'
    ) {
      return 1;
    }
    return 2;
  }

  readFromXlsxFile(event): void {
    this.readerService.readUsersFromXlsxFile(event).then(result => {
      this.usersFromFile = result;
      this.loadPersonResponsibleFromFile(this.usersFromFile);
    });
  }

  loadPersonResponsibleFromFile(data: PersonResponsibleFile[]) {
    const ids: string[] = [];
    const existingPersonResponsible: string[] = this.personResponsible.map(p => p.usrId);

    for (const usr of data) {
      if (!existingPersonResponsible.includes(usr.usrId)) {
        ids.push(usr.usrId);
      }
    }

    if (ids.length === 0) {
      this.fileInput.nativeElement.value = '';
      return;
    }

    this.newActivityService.loadPersonResponsibleFromFile(ids, this.terrs).subscribe(
      result => {
        const failedIds: string[] = result.filter(r => r.failed === true).map(r => r.user).map(u => u.usrId);
        if (failedIds && failedIds.length > 0) {
          this.dialog.open(ConfirmationDialogComponent, {
            data: new Dialog('The following EIDs are either incorrect or not assigned to your territory: ' + failedIds.toString(),
              false, false, false),
            disableClose: true,
          });
        }

        const users = result.filter(r => r.failed === false).map(r => r.user);
        this.fileInput.nativeElement.value = '';
        this.successEmitter.emit(users);
      }, error => {
        if (error.status === 404) {
          this.errorEmitter.emit(error.error.message);
        } else {
          this.errorEmitter.emit('An error has occurred. Please contact your administrator!');
        }
        this.fileInput.nativeElement.value = '';
      }
    );
    this.fileInput.nativeElement.value = '';
  }
}
