import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {CloudFront} from '../../shared/models/activity/cloud-front.model';

@Injectable({
  providedIn: 'root'
})
export class CloudfrontService {

  constructor(private http: HttpClient) { }

  static checkIfExistsOrExpired(bucket: string) {
    const cloudFront = localStorage.getItem(bucket);
    if (!cloudFront) {
      return false;
    }
    const cloudFrontObject: CloudFront = JSON.parse(cloudFront);
    if ((cloudFrontObject.expireDate - 300) < (new Date().getTime() / 1000)) {
      return false;
    }
    return true;
  }

  public getSignedUrl(bucket: string): Observable<CloudFront> {
    let params = new HttpParams();
    params = params.append('bucket', bucket);
    return this.http.get<CloudFront>(environment.apiResourceUri + 'cloudfront', { params })
      .pipe(
        map(response => {
          this.saveUrl(bucket, response);
          return response;
        })
      );
  }

  private saveUrl(bucket: string, response: CloudFront) {
    localStorage.setItem(bucket, JSON.stringify(response));
  }

  getUrlFromStorage(bucket: string) {
    return JSON.parse(localStorage.getItem(bucket)).url;
  }
}
