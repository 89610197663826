<div
  fxLayout="column"
  fxFlex="100%"
  class="wrapper"
  [ngClass]="{
      'edit-notification': pageConfig && pageConfig.notificationId,
      'new-notification': !pageConfig,
      'wrapper': !pageConfig && !pageConfig?.notificationId
  }"
>
  <div class="page-title" *ngIf="pageConfig && pageConfig.notificationId && !isPublish">
    <span>{{pageTitle}}</span>
  </div>
  <mat-card [ngClass]="isPublish ? 'mat-elevation-z0' : 'mat-elevation-z2'" class="new-notification-content">
    <form [formGroup]="form" validate>
      <app-msg-banner *ngIf="showNotification" [msgList]="messageList" (msgListChange)="messageList = $event">
      </app-msg-banner>
      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        fxLayout.xs="column"
        fxLayoutAlign.xs="space-between"
        class="notification-type">


        <mat-card fxFlex="96%;" class="mat-elevation-z0">
          <div fxFlex fxLayout="column">
            <mat-card-content>
              <mat-form-field appearance="standard">
                <mat-label>Notification Type</mat-label>
                <mat-select [formControl]="form.controls.notType" required
                            (selectionChange)="onChange($event); changeConfig();"
                            [disabled]="pageConfig && pageConfig.notificationId">
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let notificationType of notificationTypes" [value]="notificationType">
                    <span *ngIf="notificationType === NOTIF_CONSTS.NOTIF_TYPE.CHAIN">Account</span>
                    <span *ngIf="notificationType !== NOTIF_CONSTS.NOTIF_TYPE.CHAIN">{{ notificationType }}</span>
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls.notType.hasError('required')">Notification Type is Mandatory.
                </mat-error>
              </mat-form-field>
            </mat-card-content>
          </div>
        </mat-card>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        fxLayout.xs="column"
        fxLayoutAlign.xs="space-between"
        class="notification-header">
        <mat-card fxFlex="46%;" fxFlex.xs="46%" class="mat-elevation-z0">
          <mat-form-field appearance="standard">
            <mat-label>Author</mat-label>
            <input
              matInput
              required
              readonly="readonly"
              formControlName="author"
            />
            <mat-error *ngIf="form.controls.author.dirty &&
                                      form.controls.author.hasError('required')"
            >
              Notification Title is Mandatory.
            </mat-error>
          </mat-form-field>
        </mat-card>

        <mat-card fxFlex="46%;" fxFlex.xs="46%" class="mat-elevation-z0">
          <mat-form-field appearance="standard">
            <mat-label>Language</mat-label>
            <mat-select [formControl]="form.controls.lang" required>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let language of languages" [value]="language">
                {{language}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.lang.hasError('required')">Language is Mandatory.</mat-error>
          </mat-form-field>
        </mat-card>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        fxLayout.xs="column"
        fxLayoutAlign.xs="space-between"
        class="notification-header">

        <mat-card fxFlex="46%;" fxFlex.xs="46%" class="mat-elevation-z0">
          <mat-form-field appearance="standard">
            <mat-label>Notification Title</mat-label>
            <input
              matInput
              required
              [maxLength]="getLength().title"
              formControlName="title"
            />
            <mat-hint>{{ form.controls.title.value ? form.controls.title.value.length : 0 }}
              /{{ getLength().title }}</mat-hint>
            <button mat-button
                    *ngIf="form.controls.title.value && (pageConfig && pageConfig.type === NOTIF_CONSTS.PAGE.VIEW ? false : true)"
                    matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.controls.title.setValue('')">
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="form.controls.title.errors?.required && form.controls.title.dirty">
              Notification Title is Mandatory.
            </mat-error>
            <mat-error *ngIf="form.controls.title.errors?.pattern && form.controls.title.dirty">
              * Input not allowed
            </mat-error>
            <mat-error *ngIf="form.controls.title.errors?.maxLength && form.controls.title.touched">
              {{form.controls.title.errors?.maxLength}}
            </mat-error>
          </mat-form-field>
        </mat-card>

        <mat-card fxFlex="46%;" fxFlex.xs="46%" class="mat-elevation-z0">
          <mat-form-field fxFlex appearance="standard">
            <mat-label>Notification Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker1"
              id="picker1"
              value=""
              (click)="picker1.open()"
              (keydown)="(false)"
              formControlName="posted"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              class="picker"
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1 class="picker"></mat-datepicker>
            <mat-error
              *ngIf="
                            form.controls.posted.touched &&
                            form.controls.posted.hasError('required')"
            >
              Notification Date is Mandatory.
            </mat-error>
            <mat-error *ngIf="form.controls.posted.errors?.pattern && form.controls.posted.dirty">
            * Input not allowed
          </mat-error>
          </mat-form-field>
        </mat-card>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        fxLayout.xs="column"
        fxLayoutAlign.xs="space-between"
        class="notification-period"
      >
      <mat-card fxFlex="46%;" fxFlex.xs="46%" class="mat-elevation-z0">
          <mat-form-field fxFlex appearance="standard">
            <mat-label>Valid From Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker2"
              id="picker2"
              value=""
              (click)="picker2.open()"
              (keydown)="(false)"
              formControlName="from"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              class="picker"
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2 class="picker"></mat-datepicker>
            <mat-error
              *ngIf="
                            form.controls.from.touched &&
                            form.controls.from.hasError('required')
                          "
            >
              Valid From Date is Mandatory.
            </mat-error>
            <mat-error *ngIf="form.controls.from.errors?.pattern && form.controls.from.dirty">
              * Input not allowed
            </mat-error>
          </mat-form-field>
        </mat-card>
        <mat-card fxFlex="46%;" fxFlex.xs="46%" class="mat-elevation-z0">
          <mat-form-field fxFlex appearance="standard">
            <mat-label>Until Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker3"
              id="picker3"
              value=""
              [min]="form.controls.from.value"
              (click)="picker3.open()"
              (keydown)="(false)"
              formControlName="until"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              class="picker"
              [for]="picker3"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker3 class="picker"></mat-datepicker>
            <mat-error
              *ngIf="
                            form.controls.until.touched &&
                            form.controls.until.hasError('required')
                          "
            >
              Until Date is Mandatory.
            </mat-error>
            <mat-error *ngIf="form.controls.until.errors?.pattern && form.controls.until.dirty">
              * Input not allowed
            </mat-error>
          </mat-form-field>
        </mat-card>
      </div>

      <div
        [ngSwitch]="form.controls.notType.value"
      >
        <div
          *ngIf="displayAudiences"
          fxLayout="row"
          fxLayoutAlign="space-around center"
          fxLayout.xs="column"
          fxLayoutAlign.xs="space-between"
          class="notification-audience">

          <mat-card
            fxFlex="96%"
            fxFlex.lt-md="100%"
            class="mat-elevation-z0 audience-card"
          >
            <span class="audience-header">Audience</span>
            <mat-card-content>
              <mat-chip-list
                formControlName="audience"
                multiple
                [required]="form.controls.notType.value === NOTIF_CONSTS.NOTIF_TYPE.CHAIN"
              >
                <mat-chip *ngFor="let audience of audienceList" selectable="true"
                          [selected]="isSelected(audience)"
                          (click)="toggleOffer(audience)">
                  <span *ngIf="audience === 'IR'">Republic of Ireland&nbsp;</span>
                  <span *ngIf="audience === 'AU'">Australia&nbsp;</span>
                  <span *ngIf="audience === 'NZ'">New Zealand&nbsp;</span>
                  <span *ngIf="audience === 'UK'">{{ audience }}&nbsp;</span>
                  <mat-icon>{{isSelected(audience) ? "close" : "add"}}</mat-icon>
                </mat-chip>

              </mat-chip-list>
              <mat-error
                *ngIf="
                form.controls.audience.touched &&
                form.controls.audience.value.length == 0
              "
              >
                Audience is Mandatory.
              </mat-error>
            </mat-card-content>
            <mat-divider horizontal fxFlex.xs="0%"></mat-divider>
          </mat-card>
        </div>

        <div
          *ngSwitchCase="NOTIF_CONSTS.NOTIF_TYPE.CHAIN"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayout.xs="column"
          fxLayoutAlign.xs="space-between"
          class="notification-chains">

          <mat-card fxFlex="46%;" fxFlex.xs="46%" class="mat-elevation-z0">
            <mat-form-field fxFlex appearance="standard">
              <mat-label>Account</mat-label>
              <mat-select
                formControlName="rooms"
                placeholder="Account"
                [multiple]="true"
                [required]="form.controls.notType.value === NOTIF_CONSTS.NOTIF_TYPE.CHAIN"
                #roomMultiSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    [showToggleAllCheckbox]="true"
                    (toggleAll)="toggleChainsSelectAll($event)"
                    [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                    [toogleAllCheckboxTooltipPosition]="'above'"
                    [formControl]="roomMultiFilterCtrl"
                    placeholderLabel="Find account..."
                    noEntriesFoundLabel="'No matching account found'"
                  >
                    <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let chain of filteredRoomsMulti | async"
                  [disabled]="!chain.active"
                  [value]="chain?.value.roomId"
                >
                  {{ chain.value.roomName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-card>
        </div>

        <div
          *ngSwitchCase="NOTIF_CONSTS.NOTIF_TYPE.TERR"
          fxLayout="row"
          fxLayoutAlign="space-around center"
          fxLayout.xs="column"
          fxLayoutAlign.xs="space-between"
          class="notification-terrs">

          <mat-card fxFlex="46%;" fxFlex.xs="46%" class="mat-elevation-z0">
            <mat-form-field fxFlex appearance="standard">
              <mat-label>Territory</mat-label>
              <mat-select
                formControlName="terr"
                placeholder="Territory"
                [multiple]="false"
                [required]="form.controls.notType.value === NOTIF_CONSTS.NOTIF_TYPE.TERR"
                (selectionChange)="retrieveUsersByTerritory($event)"
                #terrMultiSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="terrMultiFilterCtrl"
                    placeholderLabel="Find territory..."
                    noEntriesFoundLabel="'No matching territory found'"
                  >
                    <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let terr of filteredTerrsMulti | async"
                  [disabled]="!terr.active"
                  [value]="terr.value"
                >
                  {{ terr.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-card>

          <mat-card fxFlex="46%;" fxFlex.xs="46%" class="mat-elevation-z0">
            <mat-form-field fxFlex appearance="standard">
              <mat-label>Users</mat-label>
              <mat-select
                formControlName="users"
                placeholder="Users"
                [multiple]="true"
                [disabled]="disableUsers"
                [required]="form.controls.notType.value === NOTIF_CONSTS.NOTIF_TYPE.TERR"
                #userMultiSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    [showToggleAllCheckbox]="true"
                    (toggleAll)="toggleUserSelectAll($event)"
                    [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                    [toogleAllCheckboxTooltipPosition]="'above'"
                    [formControl]="userMultiFilterCtrl"
                    placeholderLabel="Find user..."
                    noEntriesFoundLabel="'No matching user found'"
                  >
                    <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let usr of filteredUsersMulti | async"
                  [value]="usr.usrId"
                >
                  {{ usr.nam }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-card>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        fxLayout.xs="column"
        fxLayoutAlign.xs="space-between"
        class="notification-textarea">

        <div fxFlex="96%;" class="form-group has-feedback mat-elevation-z0"
             [ngClass]="{ 'has-error': form.controls.desc.invalid && form.controls.desc.touched }">
          <span class="notification-description" style="height: auto; user-select: none;">Description</span>
          <ckeditor
            [config]="config"
            formControlName="desc"
            required
          ></ckeditor>
          <mat-error *ngIf="form.controls.desc.errors?.required">* Description is Mandatory.</mat-error>
          <mat-error *ngIf="form.controls.desc.errors?.pattern && form.controls.desc.dirty">
            * Input not allowed
          </mat-error>
          <mat-error *ngIf="form.controls.desc.errors?.maxLength && form.controls.desc.touched">
            {{form.controls.desc.errors?.maxLength}}
          </mat-error>
        </div>
      </div>

      <div
        style="margin-left: 2%"
      >
        <app-generic-attachment
          [config]="attachmentConfig"
          [attachments]="attachments"
          [isUrl]="isUrl"
        ></app-generic-attachment>
      </div>
    </form>
    <div *ngIf="(pageConfig && pageConfig.type === NOTIF_CONSTS.PAGE.MANAGE) || !pageConfig"
         fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="center center"
         fxLayoutAlign.lt-md="start"
         class="buttons-box">
      <button
        fxFlex="100px"
        fxFlex.lt-md="100%"
        mat-raised-button class="btn btn-primary" type="button"
        (click)="onSend()"
      >
        SAVE
      </button>
      <button
        *ngIf="!isPublish"
        fxFlex="100px"
        fxFlex.lt-md="100%"
        mat-raised-button class="btn btn-cancel" type="button"
        (click)="resetForm()"
      >
                <span *ngIf="!(pageConfig && pageConfig.notificationId)">
                  RESET
                </span>
        <span *ngIf="(pageConfig && pageConfig.notificationId)">
                  CANCEL
                </span>
      </button>
      <button
        *ngIf="isPublish"
        fxFlex="100px"
        fxFlex.lt-md="100%"
        mat-raised-button class="btn btn-cancel" type="button"
        (click)="deleteNotification()"
      >
        DELETE
      </button>
    </div>
  </mat-card>
</div>
