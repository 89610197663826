<div
  fxLayout="column"
  fxLayoutGap="20px"
>

  <div
    fxFlex
    fxLayout="row"
  >
    <div
      fxFlex
      fxLayoutAlign="start"
      class="title"
    >Summary
    </div>

    <div
      fxFlex
      fxLayoutAlign="end"
    >
      <mat-icon
        (click)="closeDialog()"
        class="close-button"
      >close
      </mat-icon>
    </div>
  </div>

  <div mat-dialog-content class="spinner" *ngIf="isLoading">
    Loading metrics...
    <mat-spinner style="margin: 0 auto"></mat-spinner>
  </div>

  <mat-dialog-content
    *ngIf="!isLoading"
    fxLayout="column"
    fxLayoutGap="20px"
  >

    <app-msg-banner [msgList]="messageList" (msgListChange)="messageList = $event"></app-msg-banner>
    <mat-card>
      <mat-card-content>
        <form
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="5%"
          fxLayoutGap.lt-md="0%"
          [formGroup]="activityDetails"
        >
          <mat-form-field fxFlex="20%" fxFlex.lt-md="100%" appearance="standard">
            <mat-label>Number of activities</mat-label>
            <input matInput formControlName="numberOfActivities" />
          </mat-form-field>
          <mat-form-field fxFlex="20%" fxFlex.lt-md="100%" appearance="standard">
            <mat-label>Number of complete activities</mat-label>
            <input matInput formControlName="completeResponsesCount" />
          </mat-form-field>
          <mat-form-field fxFlex="20%" fxFlex.lt-md="100%" appearance="standard">
            <mat-label>Number of pending activities</mat-label>
            <input matInput formControlName="pendingResponsesCount" />
          </mat-form-field>
          <mat-form-field fxFlex="20%" fxFlex.lt-md="100%" appearance="standard">
            <mat-label>Number of unanswered activities</mat-label>
            <input matInput formControlName="unansweredActivities" />
          </mat-form-field>
        </form>
      </mat-card-content>
    </mat-card>

    <mat-accordion multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 18px;"> Progress </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [colors]="chartOptions.colors"
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [xaxis]="chartOptions.xaxis"
            [yaxis]="chartOptions.yaxis"
            [stroke]="chartOptions.stroke"
            [fill]="chartOptions.fill"
            [title]="chartOptions.title"
            [tooltip]="chartOptions.tooltip"
            [legend]="chartOptions.legend"
            [grid]="chartOptions.grid"
          ></apx-chart>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="activityGroups.size === 1">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 18px;"> Responses </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="responsive-table mat-elevation-z8">
            <table
              class="tableLayout"
              mat-table
              matSort
              [dataSource]="dataSource"
            >
              <ng-container matColumnDef="compliance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Compliance
                </th>
                <td mat-cell *matCellDef="let row" data-label="Compliance">
                  {{ row.compliance }}
                </td>
              </ng-container>

              <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Response
                </th>
                <td mat-cell *matCellDef="let row" data-label="Response">
                  {{ row.message }}
                </td>
              </ng-container>

              <ng-container matColumnDef="count">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Count</th>
                <td mat-cell *matCellDef="let row" data-label="Count">
                  {{ row.count }}
                </td>
              </ng-container>

              <ng-container matColumnDef="percent">
                <th mat-header-cell *matHeaderCellDef>Percent</th>
                <td mat-cell *matCellDef="let row" data-label="Percent">
                  {{ row.percent }}%
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>

    <button
      mat-button
      fxFlex.lt-md="100%"
      (click)="dialogRef.close(false)"
      class="btn btn-primary"
      style="font-size: 14px;"
    >Close</button>

  </mat-dialog-content>
</div>
