import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {Chain} from '../../../shared/models/chain';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdminService} from '../../../core/services/admin.service';
import {MsgBannerService} from '../../../shared/components/msg-banner/msg-banner.service';
import {Room} from '../../../shared/models/room.model';
import {take, takeUntil} from 'rxjs/operators';
import {AttachmentConfig, AttachmentView} from '../../../shared/models/attachment.config';
import {GenericAttachmentComponent} from '../../../shared/components/generic-attachment/generic-attachment.component';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-room-dialog',
  templateUrl: './room-dialog.component.html',
  styleUrls: ['./room-dialog.component.scss']
})
export class RoomDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('chainMultiSelect', { static: true }) chainMultiSelect: MatSelect;

  form: FormGroup;
  action: string;

  public chainMultiFilterCtrl: FormControl = new FormControl();
  public filteredChainsMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  protected _onDestroy = new Subject<void>();
  public tooltipMessage = 'Select All / Unselect All';

  chains: Chain[] = [];
  isLoading = false;

  messageList = [];
  showNotification = false;

  @ViewChild(GenericAttachmentComponent) attachmentContainer: GenericAttachmentComponent;
  attachments: string[] = [];
  isUrl = false;
  attachmentConfig: AttachmentConfig = new AttachmentConfig();

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<RoomDialogComponent>,
    private service: AdminService,
    private cdr: ChangeDetectorRef,
    private msgBanner: MsgBannerService,
    @Inject(MAT_DIALOG_DATA) public data: Room
  ) {
    if (data) {
      this.form = this.fb.group({
        id: [data.id, []],
        roomName: [data.roomName, [Validators.required, Validators.pattern(environment.emojiRegEx)]],
        chains: [data.chains, Validators.required],
        roomId: [data.roomId]
      });
      this.action = 'Update';
    } else {
      this.form = this.fb.group({
        id: [null, []],
        roomName: ['', [Validators.required, Validators.pattern(environment.emojiRegEx)]],
        chains: [[], Validators.required],
        roomId: []
      });
      this.action = 'Add';
    }
  }

  ngOnInit(): void {
    this.attachmentConfig.view = AttachmentView.ROOM_DOCUMENTS;
    this.attachmentConfig.isArchived = false;
    this.attachmentConfig.limit = 1;
    if (this.data && this.data.path) {
      this.attachments.push(this.data.path);
      if (this.data.path.includes('http')) {
        this.isUrl = true;
      }
    }

    Promise.all(this.populateDefaultValues()).then(() => {
      this.filteredChainsMulti.next(this.chains.map(chain => chain.chainId).slice(0, 50));

      this.chainMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterChainsMulti();
        });
    }).catch((error) => {
      this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
    });
  }

  populateDefaultValues(): Promise<boolean>[] {
    const promiseList: Promise<boolean>[] = [];
    promiseList.push(
      new Promise((release, reject) => {
        this.service.getChains().subscribe(
          (response: any) => {
            this.chains = response;
            release(true);
          },
          (error) => {
            reject(error);
          });
      })
    );
    return promiseList;
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSubmit() {
    this.showNotification = false;
    if (this.form.invalid) {
      this.msgBanner.addMsgError(this.messageList, 'Please complete all required fields.');
      this.showNotification = true;
      return;
    }

    const room: Room = new Room();
    if (this.form.value) {
      Object.keys(this.form.value).forEach(key => {
        room[key] = this.form.value[key];
      });
    }
    if (this.attachments && this.attachments.length > 0) {
      room.path = this.attachments[0];
    }

    this.dialogRef.close(room);
  }

  onCancel() {
    this.dialogRef.close();
  }

  compareFunction(o1: string, o2: string) {
    return o1 && o2 && o1 === o2;
  }

  protected setInitialValue() {
    this.filteredChainsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.chainMultiSelect.compareWith = (a: string, b: string) =>
          a && b && a === b;
      });
  }

  toggleChainsSelectAll(selectAllValue: boolean) {
    this.filteredChainsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.form.controls.chains.patchValue(val);
        } else {
          this.form.controls.chains.patchValue([]);
        }
      });
  }

  protected filterChainsMulti() {
    if (!this.chains) {
      return;
    }
    // get the search keyword
    let search = this.chainMultiFilterCtrl.value;
    if (!search) {
      this.filteredChainsMulti.next(this.chains.map(chain => chain.chainId).slice(0, 50));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredChainsMulti.next(
      this.chains
        .filter(
          (chain) =>
            this.getChainNameById(chain.chainId)
              .concat(chain.chainId)
              .toLowerCase()
              .indexOf(search) > -1
        ).map(chain => chain.chainId)
        .slice(0, 50)
    );
  }

  getChainNameById(chainId: string) {
    return this.chains.find((chain: Chain) => chain.chainId === chainId).chainName;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

}
