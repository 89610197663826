<div
  fxLayout="column"
  fxLayoutGap="20px"
>
  <app-msg-banner *ngIf="showNotification" [msgList]="messageList" (msgListChange)="messageList = $event"></app-msg-banner>

  <div
    fxFlex
    fxLayout="row"
  >
    <div
      fxFlex
      fxLayoutAlign="start"
      class="title"
    >{{ action }} Room
    </div>

    <div
      fxFlex
      fxLayoutAlign="end"
    >
      <mat-icon
        (click)="closeDialog()"
        class="close-button"
      >close
      </mat-icon>
    </div>
  </div>

  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="20px"
  >
    <form
      [formGroup]="form"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
    >
      <mat-form-field
        hintLabel="Max 50 characters"
        appearance="standard"
        fxFlex="48%"
        fxFlex.lt-md="100%"
      >
        <input
          type="text"
          formControlName="roomName"
          matInput
          #input
          maxlength="50"
          placeholder="Enter Room Name"
          required
        />
        <mat-hint align="end">{{ input.value?.length || 0 }}/50</mat-hint>
        <mat-error *ngIf="form.controls.roomName.errors?.pattern && form.controls.roomName.dirty">
          * Input not allowed
        </mat-error>
      </mat-form-field>

      <mat-form-field
        appearance="standard"
        fxFlex="48%"
        fxFlex.lt-md="100%"
      >
        <mat-label>Select Account</mat-label>
        <mat-select
          formControlName="chains"
          placeholder="Accounts"
          [multiple]="true"
          #chainMultiSelect
        >
          <mat-option>
            <ngx-mat-select-search
              [showToggleAllCheckbox]="true"
              (toggleAll)="toggleChainsSelectAll($event)"
              [toggleAllCheckboxTooltipMessage]="tooltipMessage"
              [toogleAllCheckboxTooltipPosition]="'above'"
              [formControl]="chainMultiFilterCtrl"
              placeholderLabel="Find account..."
              noEntriesFoundLabel="'No matching chain found'"
            >
              <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let chain of filteredChainsMulti | async"
            [value]="chain"
          >
            {{ getChainNameById(chain) }} ({{ chain }})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <app-generic-attachment
      [config]="attachmentConfig"
      [attachments]="attachments"
      [isUrl]="isUrl"
    ></app-generic-attachment>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
      fxLayoutGap.lt-md="0"
    >
      <button
        fxFlex="100px"
        fxFlex.lt-md="100%"
        id="submit"
        class="btn btn-submit"
        (click)="onSubmit()"
        [disabled]="!form.valid"
      >{{ action }}
      </button>
      <button
        fxFlex="100px"
        fxFlex.lt-md="100%"
        id="cancel"
        class="btn btn-cancel"
        (click)="onCancel()"
      >Cancel
      </button>
    </div>

  </mat-dialog-content>
</div>
