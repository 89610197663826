import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {User} from '../../shared/models/activity/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUsers(usr: string, terrs: string[]): Observable<User[]> {
    let params = null;
    params = new HttpParams();

    if (terrs && terrs.length > 0) {
      params = params.append('terrs', terrs);
    }
    return this.http.get<User[]>(`${environment.apiResourceUri}users/${usr}`, { params });
  }

  getUsersByTerritory(terrs: string[]): Observable<User[]> {
    let params = null;
    params = new HttpParams();
    params = params.append('terrs', terrs);
    return this.http.get<User[]>(`${environment.apiResourceUri}users`, { params });
  }
}
