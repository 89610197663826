<mat-dialog-content>

  <app-generic-file-structure
    *ngIf="!showUpload"
    [config]="buildConfig()"
    (uploadEmitter)="uploadView($event)"
    [uploadPath]="uploadPath"
    (closeEmitter)="closeDialog($event)"
    (fileStructureState)="saveFileStructureState($event)"
    [fileStructure]="nodes"
  ></app-generic-file-structure>

  <app-generic-upload-file
    *ngIf="showUpload"
    [config]="buildConfig()"
    [uploadPath]="uploadPath"
    (fileEmitter)="fileView($event)"
    (closeEmitter)="closeDialog($event)"
  ></app-generic-upload-file>

</mat-dialog-content>
