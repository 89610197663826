import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import {RoleGuardService} from '../guards/role-guard.service';
import * as path from 'path';

export interface DirectiveParam {
  customeRole: string[];
  path: string;
}

@Directive({ selector: '[appUserRole]'})
export class UserRoleDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private roleGuardService: RoleGuardService,
    private viewContainer: ViewContainerRef
  ) { }

  params = {
    customerRole: [],
    path: ''
  };

  @Input('appUserRole')
  set appUserRole(params: DirectiveParam) {
    if (params) {
      this.params.customerRole = params.customeRole ? params.customeRole : [];
      this.params.path = params.path ? params.path : '';
    }
  }

  ngOnInit() {
    let customRoleAccess = null;
    let pathRoleAccess = null;

    let hasAccess = this.roleGuardService.isAuthorized();

    if (this.params.customerRole.length > 0 && this.roleGuardService.isAuthorized()) {
      customRoleAccess = this.roleGuardService.hasRoles(this.params.customerRole, true);
    }

    if (this.params.path && this.params.path !== '') {
      pathRoleAccess = this.roleGuardService.handleAccess(true, this.params.path);
    }

    hasAccess = hasAccess &&
      (customRoleAccess != null ? customRoleAccess : true) &&
      (pathRoleAccess != null ? pathRoleAccess : true);

    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
