import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LoadResponsesConfig} from '../../../shared/models/activity/load-responses-config.model';

@Component({
  selector: 'app-response-dialog',
  templateUrl: './response-dialog.component.html',
  styleUrls: ['./response-dialog.component.scss']
})
export class ResponseDialogComponent implements OnInit {
  config: LoadResponsesConfig;

  constructor(public dialogRef: MatDialogRef<ResponseDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data) {
      this.config = {
        updatable: this.data.updatable,
        disableActions: this.data.disableActions,
        data: this.data.data
      };
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
