import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {AdminService} from '../../../core/services/admin.service';
import {MsgBannerService} from '../../../shared/components/msg-banner/msg-banner.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {Location} from '../../../shared/models/location.model';
import {Season} from '../../../shared/models/season.model';
import {Router} from '@angular/router';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../../shared/models/dialog';
import {StaticDataDialogComponent} from '../static-data-dialog/static-data-dialog.component';
import {StringResponse} from '../../../shared/models/string-response';

@Component({
  selector: 'app-static-data-management',
  templateUrl: './static-data-management.component.html',
  styleUrls: ['./static-data-management.component.scss']
})
export class StaticDataManagementComponent implements OnInit, AfterViewInit {

  displayedColumns = [
    'name',
    'active',
    'actions'
  ];

  // error list
  messageList = [];
  showNotification = false;

  searchValue: string = null;
  dataSource = new MatTableDataSource<any>();
  pageName: string;

  sort;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'name': return item.en;
          case 'active': return item.active;
          default: return item[property];
        }
      };
    }
  }

  constructor(private service: AdminService,
              private msgBanner: MsgBannerService,
              private router: Router,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.pageName = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
  }

  ngAfterViewInit(): void {
    this.dataSource.filterPredicate = (data: Location | Season, filter: string) =>
      (data.en)
        .trim()
        .toLowerCase()
        .indexOf(filter) !== -1;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.getData();
  }

  applyFilter(clearKeyUp?: boolean) {
    if (clearKeyUp && this.searchValue != null && this.searchValue.length === 0) {
      this.getData();
    } else if (clearKeyUp === undefined) {
      this.getData();
    }
  }

  getData() {
    this.showNotification = false;

    if (this.pageName === 'location') {
      this.getLocation();
    } else if (this.pageName === 'season') {
      this.getSeason();
    }
  }

  getLocation() {
    this.service.getLocation(this.searchValue).subscribe(
      (response: Location[]) => {
        this.dataSource = new MatTableDataSource<Location>(response);
      },
      (error: any) => {
        this.dataSource = new MatTableDataSource<Location>();

        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  getSeason() {
    this.service.getSeason(this.searchValue).subscribe(
      (response: Season[]) => {
        this.dataSource = new MatTableDataSource<Season>(response);
      },
      (error: any) => {
        this.dataSource = new MatTableDataSource<Season>();

        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  openAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(StaticDataDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          if (this.pageName === 'location') {
            this.createLocation(data);
          } else if (this.pageName === 'season') {
            this.createSeason(data);
          }
        }

      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      });
  }

  createLocation(data) {
    this.service.createLocation(data).subscribe(
      (response: StringResponse) => {
        this.showConfirmationMessageAndRetrieveData(response.response);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  createSeason(data) {
    this.service.createSeason(data).subscribe(
      (response: StringResponse) => {
        this.showConfirmationMessageAndRetrieveData(response.response);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  showConfirmationMessageAndRetrieveData(message: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(message, false, false, false),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      response => {
        this.getData();
        if (response) {
          return;
        }
      }
    );
  }

  openEdit(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = data;

    const dialogRef = this.dialog.open(StaticDataDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      response => {
        if (response) {
          if (this.pageName === 'location') {
            response.locId = data.locId;
            this.editLocation(response);
          } else if (this.pageName === 'season') {
            response.seasId = data.seasId;
            this.editSeason(response);
          }
        }
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      });
  }

  editLocation(response) {
    this.service.editLocation(response).subscribe(
      (resp: StringResponse) => {
        this.showConfirmationMessageAndRetrieveData(resp.response);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  editSeason(response) {
    this.service.editSeason(response).subscribe(
      (resp: StringResponse) => {
        this.showConfirmationMessageAndRetrieveData(resp.response);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  onDelete(data: Season | Location) {
    let text = '';
    if (this.pageName === 'location') {
      text = 'Do you want to inactivate this location?';
    } else if (this.pageName === 'season') {
      text = 'Do you want to inactivate this season?';
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(text, true, false, true),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      response => {
        if (response) {
          if (this.pageName === 'location') {
            this.deleteLocation(data);
          } else if (this.pageName === 'season') {
            this.deleteSeason(data);
          }
        }
      }
    );
  }

  deleteLocation(data) {
    this.service.deleteLocation(data).subscribe(
      (resp: StringResponse) => {
        this.showConfirmationMessageAndRetrieveData(resp.response);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  deleteSeason(data) {
    this.service.deleteSeason(data).subscribe(
      (resp: StringResponse) => {
        this.showConfirmationMessageAndRetrieveData(resp.response);
      },
      (error) => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

}
