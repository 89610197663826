<div class="landing full-width full-height">
  <div fxFlex fxLayout="column">
    <div
      fxFlex="4%"
      class="full-width full-height background-color-accent-500"
    ></div>
    <div fxFlex fxLayout="row" fxLayout.lt-md="column">
      <div
        fxFlex="30%"
        fxFlex.lt-md="40%"
        fxFlexOrder="1"
        fxFlexOrder.lt-md="2"
        class="landing-image full-height"
      >
        <div class="landing-overlay"></div>
      </div>
      <div
        fxFlex
        fxFlexOrder="2"
        fxFlexOrder.lt-md="1"
        fxLayout="column"
        fxLayoutAlign="space-around center"
        fxLayoutGap="20px"
      >
        <div
          fxFlex="30%"
          fxLayoutAlign="center center"
          class="mt-s-2"
          style="text-align: center"
        >
          <h1>Welcome to <br> {{ getEnvironment.title }}</h1>
<!--          <img class="landing-logo" src="assets/img/rose.png" alt="AG Logo" />-->
        </div>
        <div
          fxFlex="30%"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="40px"
          fxLayoutGap.lt-md="20px"
        >
          <button
            class="landing-button landing-sign-in"
            mat-raised-button
            color="primary"
            (click)="login()"
          >Sign In</button>
        </div>
        <div fxFlex fxLayoutAlign="center center">
          <mat-toolbar-row class="header-brands-toolbar" fxLayout="row">
            <div
              class="header-brands"
              fxFlex="1 1 0%"
              fxLayoutAlign="center center"
            >
              <a fxFlex="0 1 0%">
                <img [src]="getEnvironment.landingLogoPath" />
              </a>
            </div>
          </mat-toolbar-row>
        </div>
      </div>
    </div>
  </div>
</div>
