import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {HomePageComponent} from './home-page/home-page.component';
import {MsalGuard} from '@azure/msal-angular';
import {AuthGuard} from '../../core/guards/auth.guard';
import {RoleGuardService} from '../../core/guards/role-guard.service';
import {GroupGuardService} from '../../core/guards/group-guard.service';
import {ROUTE} from '../../shared/constants/routes-constants';
import * as config from '../app-config.json';
import {PublishActivityComponent} from './activity/publish-activity/publish-activity.component';
import {ManageActivityComponent} from './activity/manage-activity/manage-activity.component';
import {ChainNotificationViewComponent} from './notification/chain-notification-view/chain-notification-view.component';
import {NOTIF_CONSTS} from '../../shared/constants/notification-constants';
import {GeneralNotificationViewComponent} from './notification/general-notification-view/general-notification-view.component';
import {TerritoryNotificationViewComponent} from './notification/territory-notification-view/territory-notification-view.component';
import {NewNotificationComponent} from './notification/new-notification/new-notification.component';
import {ActivityComplianceComponent} from './compliance/activity-compliance/activity-compliance.component';
import {ActivityDetailsComponent} from './compliance/activity-details/activity-details.component';
import {StaticDataManagementComponent} from '../admin/static-data-management/static-data-management.component';
import {TrainingManagementComponent} from '../admin/training-management/training-management.component';
import {ManageResponseComponent} from '../admin/manage-response/manage-response.component';
import {UserManagementComponent} from '../admin/user-management/user-management.component';
import {FileStructureComponent} from '../admin/file-structure/file-structure.component';
import {PublishNotificationComponent} from './notification/publish-notification/publish-notification.component';
import {NewActivityComponent} from './activity/new-activity/new-activity.component';
import {RoomManagementComponent} from '../admin/room-management/room-management.component';
import {DocumentsStructureComponent} from '../admin/documents-structure/documents-structure.component';
import {GeneralDocumentsComponent} from './notification/general-documents/general-documents.component';
import {ChainDocumentsComponent} from './notification/chain-documents/chain-documents.component';
import {ActivityQuickSearchComponent} from './compliance/activity-quick-search/activity-quick-search.component';
import { ManageActivityGroupComponent } from './activity/manage-activity-group/manage-activity-group.component';
import { TerritoryChangesComponent } from '../admin/territory-changes/territory-changes.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuard, MsalGuard, RoleGuardService]
  },
  {
    path: ROUTE.ACTIVITY.NEW.PATH,
    component: NewActivityComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path:  ROUTE.ACTIVITY.PUBLISH.PATH,
    component: PublishActivityComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path:  ROUTE.ACTIVITY.MANAGE.PATH,
    component: ManageActivityComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path:  ROUTE.ACTIVITY.MANAGE_GROUP.PATH,
    component: ManageActivityGroupComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.NOTIFICATION.NEW.PATH,
    component: NewNotificationComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.NOTIFICATION.PUBLISH.PATH,
    component: PublishNotificationComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.NOTIFICATION.VIEW_GENERAL.PATH,
    component: GeneralNotificationViewComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
      type: NOTIF_CONSTS.PAGE.VIEW
    }
  },
  {
    path: ROUTE.NOTIFICATION.VIEW_GENERAL_DOCUMENTS.PATH,
    component: GeneralDocumentsComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
      type: NOTIF_CONSTS.PAGE.VIEW
    }
  },
  {
    path:  ROUTE.NOTIFICATION.VIEW_CHAIN.PATH,
    component: ChainNotificationViewComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
      type: NOTIF_CONSTS.PAGE.VIEW
    }
  },
  {
    path:  ROUTE.NOTIFICATION.VIEW_CHAIN_DOCUMENTS.PATH,
    component: ChainDocumentsComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
      type: NOTIF_CONSTS.PAGE.VIEW
    }
  },
  {
    path: ROUTE.NOTIFICATION.VIEW_TERRITORY.PATH,
    component: TerritoryNotificationViewComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
      type: NOTIF_CONSTS.PAGE.VIEW
    }
  },
  {
    path: ROUTE.NOTIFICATION.MANAGE_GENERAL.PATH,
    component: GeneralNotificationViewComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
      type: NOTIF_CONSTS.PAGE.MANAGE
    }
  },
  {
    path:  ROUTE.NOTIFICATION.MANAGE_CHAIN.PATH,
    component: ChainNotificationViewComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
      type: NOTIF_CONSTS.PAGE.MANAGE
    }
  },
  {
    path: ROUTE.NOTIFICATION.MANAGE_TERRITORY.PATH,
    component: TerritoryNotificationViewComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
      type: NOTIF_CONSTS.PAGE.MANAGE
    }
  },
  // {
  //   path: ROUTE.ACTIVITY.PHOTO_COMPLICANCE.PATH,
  //   component: ActivityComplianceComponent,
  //   canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
  //   data: {
  //     expectedGroup: config.groups.groupAllUsers,
  //   }
  // },
  {
    path: ROUTE.ACTIVITY.PHOTO_COMPLICANCE_QUICK.PATH,
    component: ActivityQuickSearchComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    }
  },
  {
    path: ROUTE.ACTIVITY.PHOTO_COMPLICANCE.PATH,
    component: ActivityDetailsComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.ADMIN.RESPONSE.PATH,
    component: ManageResponseComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.ADMIN.USER.PATH,
    component: UserManagementComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.ADMIN.TRAINING.PATH,
    component: TrainingManagementComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.ADMIN.LOCATION.PATH,
    component: StaticDataManagementComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.ADMIN.SEASON.PATH,
    component: StaticDataManagementComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.ADMIN.ATTACHMENTS.PATH,
    component: FileStructureComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.ADMIN.ROOM.PATH,
    component: RoomManagementComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.ADMIN.DOCUMENTS.PATH,
    component: DocumentsStructureComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    },
  },
  {
    path: ROUTE.ADMIN.TERRITORY_CHANGES.PATH,
    component: TerritoryChangesComponent,
    canActivate: [AuthGuard, MsalGuard, GroupGuardService, RoleGuardService],
    data: {
      expectedGroup: config.groups.groupAllUsers,
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRouting { }
