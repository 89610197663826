export class Training {
  trngId: string;
  name: string;
  url: string;

  constructor() {
    this.trngId = null;
    this.name = null;
    this.url = null;
  }
}
