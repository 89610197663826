import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {ActivityConstants} from '../../constants/ActivityConstants';
import {MatSort} from '@angular/material/sort';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MsgBannerService} from '../msg-banner/msg-banner.service';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {Chain, ChainGroupModel} from '../../models/chain';
import {takeUntil} from 'rxjs/operators';
import {Customer} from '../../models/activity/customer.model';
import {GraphService} from '../../../core/graph/graph.service';
import {AdminService} from '../../../core/services/admin.service';
import {NewActivityService} from '../../../core/services/new-activity.service';
import { concatAll } from 'rxjs-compat/operator/concatAll';

@Component({
  selector: 'app-load-store',
  templateUrl: './load-store.component.html',
  styleUrls: ['./load-store.component.scss']
})
export class LoadStoreComponent implements OnInit, OnDestroy {

  @ViewChild('chainMultiSelect', { static: true }) chainMultiSelect: MatSelect;
  public chainMultiFilterCtrl: FormControl = new FormControl();
  public filteredChainsMulti: ReplaySubject<string[]> = new ReplaySubject<
    string[]
    >(1);
  restricted = true;
  chains: Chain[] = [];
  protected _onDestroy = new Subject<void>();

  isLoading = true;
  isLoadingResults = false;
  searchDisabled = false;
  isError = false;
  showNotification = false;
  messageList = [];

  resultsLength: number;

  searchForm: FormGroup;
  dataSource = new MatTableDataSource<Customer>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selection = new SelectionModel<Customer>(true, []);
  stores: Customer[];
  displayedColumns = ActivityConstants.STORES_NEW.displayedColumns;

  sort;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'accountName': return item.nam;
          case 'chain': return item.chain;
          case 'storeCode': return item.str;
          case 'territory': return item.terr;
          case 'storeOwnRef': return item.strOwnRef;
          case 'address': return item.stAdr;
          default: return item[property];
        }
      };
    }
  }

  chainGroup: ChainGroupModel = null;

  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<LoadStoreComponent>,
              private msgBanner: MsgBannerService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private graphService: GraphService,
              private adminService: AdminService,
              private service: NewActivityService) {
    this.searchForm = this.fb.group({
      acctName: [ null, [], []],
      chain: [ null, [], []],
      storeCode: [ null, [], []],
      storeOwnRef: [ null, [], []],
      address: [ null, [], []],
      territory: [ null, [], []]
    });
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.isLoading = false;

    this.chainGroup = this.data.chainGroup;
    if (this.chainGroup != null || this.chainGroup != undefined) {
      this.searchForm.controls.chain.setValue(this.chainGroup.desc);
      this.searchForm.controls.chain.disable();
      this.searchForm.controls.chain.updateValueAndValidity();
    }

    // used to keep the state of selection
    this.selection.isSelected = this.isChecked.bind(this);

    this.getChains(this.graphService.user.displayName);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSearch() {
    this.paginator.pageIndex = 0;
    this.getStores();
  }

  getStores() {
    if (this.paginator) {
      this.getData(this.paginator.pageIndex, this.paginator.pageSize);
    } else {
      this.getData(0, 10);
    }
  }

  getData(pageIndex?: number, pageSize?: number) {
    this.isLoadingResults = true;
    this.showNotification = false;
    this.isError = false;
    this.stores = [];

    var searchFields = JSON.parse(JSON.stringify(this.searchForm.value));
    if (this.chainGroup && this.chainGroup.chains.length > 0) {
      // filter chains if user is chain restricted
      var usrChains: string[] = this.chains.length > 0 ? 
            this.chainGroup.chains.filter(chain => this.chains.map(c => c.chainId).includes(chain)) :
            this.chainGroup.chains;

      if (usrChains.length < 1) {
        this.stores = [];
        this.dataSource = new MatTableDataSource<Customer>(this.stores);
        this.isLoadingResults = false;
        this.resultsLength = 0;
        return
      }
      searchFields.chain = usrChains ? usrChains.join(',') : null;
    }

    
    this.service.searchStores(pageIndex, pageSize, searchFields).subscribe(
      (response: any) => {
        this.stores = response.content;
        this.dataSource = new MatTableDataSource<Customer>(this.stores);

        // this.selection = new SelectionModel<Store>(true, []);
        this.isLoadingResults = false;
        this.resultsLength = response.totalElements;
      }, error => {
        this.dataSource = new MatTableDataSource();

        if (error.status === 404) {
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
        this.isError = false;
        this.resultsLength = 0;
        this.isLoadingResults = false;
      }
    );
  }

  onPageChange(event: PageEvent) {
    this.selection.clear();
    this.getData(event.pageIndex, event.pageSize);
  }

  onSearchReset() {
    this.searchForm.reset();
    this.searchDisabled = false;
    this.onSearch()
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;

    const numRowsMinusExcluded = this.dataSource.data
      .filter(row => !this.disableRow(row))
      .length;

    return numSelected === numRowsMinusExcluded;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      // this.dataSource.data.forEach(row => this.selection.select(row));

      this.dataSource.data.forEach(row => {
        if (!this.disableRow(row)) {
          this.selection.select(row);
        }
      });

  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Customer): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  /** Keep the state of the checkbox when page changed */
  isChecked(row: Customer): boolean {
    const found = this.selection.selected.find(el => el.fullAcct === row.fullAcct);
    return !!found;
  }

  addStores() {
    this.dialogRef.close(this.selection.selected);
  }

  disableRow(row: Customer) {
    if (this.data.stores.includes(row.fullAcct)) {
      return true;
    }
    return false;
  }

  private getChains(displayName: string) {
    this.isLoadingResults = true;
    this.showNotification = false;
    this.adminService.getChainsByEmail(displayName).subscribe(
      response => {
        this.chains = response;
        if (response.length === 0) {
          this.restricted = false;
        } else {
          this.restricted = true;
          // this.initDropDownList();
        }

        this.onSearch();
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  private initDropDownList() {
    this.filteredChainsMulti.next(this.chains.map(chain => chain.chainId).slice(0, 50));

    this.chainMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterChainsMulti();
      });
  }

  getChainNameById(chainId: string) {
    return this.chains.find((chain: Chain) => chain.chainId === chainId).chainName;
  }

  protected filterChainsMulti() {
    if (!this.chains) {
      return;
    }
    // get the search keyword
    let search = this.chainMultiFilterCtrl.value;
    if (!search) {
      this.filteredChainsMulti.next(this.chains.map(chain => chain.chainId).slice(0, 50));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredChainsMulti.next(
      this.chains
        .filter(
          (chain) =>
            this.getChainNameById(chain.chainId)
              .concat(chain.chainId)
              .toLowerCase()
              .indexOf(search) > -1
        ).map(chain => chain.chainId)
        .slice(0, 50)
    );
  }


  closeDialog() {
    this.dialogRef.close();
  }
}
