<div
  [ngSwitch]="config.page"
>
  <app-msg-banner *ngIf="showNotification" [msgList]="messageList" (msgListChange)="messageList = $event">
  </app-msg-banner>

  <div>
    <!--Filter Menu-->
    <mat-accordion
      displayMode="flat"
      class="card card-stats"
      style="margin-top: 0 !important;"
    >
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <span class="material-icons filter-title">filter_alt</span>
          <span class="filter-title">Filter Notifications</span>
        </mat-expansion-panel-header>

        <div
          *ngIf="!isLoading"
        >
          <form
            fxLayout="column"
            (ngSubmit)="onSearch()"
            [formGroup]="config.searchForm"
          >
            <div
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutGap="2%"
              fxLayoutGap.lt-md="0"
            >
              <mat-form-field
                      fxFlex="24%"
                      fxFlex.lt-md="100%"
                      *ngIf="this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.CHAIN)"
              >
                <mat-label>Title</mat-label>
                <input formControlName="title" matInput placeholder="Title" type="text"/>
                <mat-error *ngIf="config.searchForm.controls.title.errors?.pattern && config.searchForm.controls.title.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-form-field
                fxFlex="24%"
                fxFlex.lt-md="100%"
                *ngIf="this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.CHAIN)"
              >
                <mat-label>Account</mat-label>
                <mat-select
                        formControlName="chains"
                        placeholder="Accounts"
                        [multiple]="true"
                        #chainMultiSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                            [showToggleAllCheckbox]="true"
                            (toggleAll)="toggleChainsSelectAll($event)"
                            [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                            [toogleAllCheckboxTooltipPosition]="'above'"
                            [formControl]="chainMultiFilterCtrl"
                            placeholderLabel="Find account..."
                            noEntriesFoundLabel="'No matching account found'"
                    >
                      <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                          *ngFor="let chain of filteredChainsMulti | async"
                          [value]="chain.value.roomId"
                  >
                    {{ chain.value.roomName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                fxFlex="48%"
                fxFlex.lt-md="100%"
              >
                <mat-label>Title</mat-label>
                <input formControlName="title" matInput placeholder="Title" type="text"/>
              </mat-form-field>

              <mat-form-field
                fxFlex="24%"
                fxFlex.lt-md="100%"
                *ngIf="this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.TERR)"
              >
                <mat-label>Select Territory</mat-label>
                <mat-select
                  formControlName="terrs"
                  placeholder="Territories"
                  [multiple]="true"
                  (selectionChange)="onSelectionChange($event)"
                  #territoryMultiSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [showToggleAllCheckbox]="true"
                      (toggleAll)="toggleTerrsSelectAll($event)"
                      [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                      [toogleAllCheckboxTooltipPosition]="'above'"
                      [formControl]="territoryMultiFilterCtrl"
                      placeholderLabel="Find territory..."
                      noEntriesFoundLabel="'No matching territory found'"
                    >
                      <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let territory of filteredTerritoriesMulti | async"
                    [value]="territory.value"
                  >
                    {{ territory.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                fxFlex="24%"
                fxFlex.lt-md="100%"
                *ngIf="this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.TERR)"
              >
                <mat-label>Users</mat-label>
                <mat-select
                  formControlName="users"
                  placeholder="Users"
                  [multiple]="true"
                  [disabled]="disableUsers"
                  #userMultiSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [showToggleAllCheckbox]="true"
                      (toggleAll)="toggleUserSelectAll($event)"
                      [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                      [toogleAllCheckboxTooltipPosition]="'above'"
                      [formControl]="userMultiFilterCtrl"
                      placeholderLabel="Find user..."
                      noEntriesFoundLabel="'No matching user found'"
                    >
                      <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let usr of filteredUsersMulti | async"
                    [value]="usr.usrId"
                  >
                    {{ usr.nam }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                [fxFlex]="this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.GENERAL) ? '48%' : '24%'"
                fxFlex.lt-md="100%"
              >
                <mat-label>Enter a date range for notification date</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate formControlName="startNotificationDate"
                         placeholder="Start date">
                  <input matEndDate formControlName="endNotificationDate"
                         placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error *ngIf="config.searchForm.controls.startNotificationDate.hasError('matStartDateInvalid')">
                  Invalid start date
                </mat-error>
                <mat-error *ngIf="config.searchForm.controls.endNotificationDate.hasError('matEndDateInvalid')">
                  Invalid end date
                </mat-error>
              </mat-form-field>

              <mat-checkbox
                fxHide
                fxShow.lt-md
                formControlName="expired"
              >Include expired notifications
              </mat-checkbox>
            </div>

            <div
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="space-between center"
              fxLayoutAlign.lt-md="start"
            >
              <div
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutAlign="start center"
                fxLayoutAlign.lt-md="start"
              >
                <button class="btn btn-primary" mat-raised-button style="background-color: #42a5f5" type="submit"
                        [disabled]="searchDisabled || config.searchForm.invalid">
                  SEARCH
                </button>
                <button class="btn btn-primary" mat-raised-button style="background-color:#fd2626"
                        (click)="onSearchReset()" type="button">
                  CLEAR
                </button>
              </div>


              <mat-checkbox
                fxShow
                fxHide.lt-md
                formControlName="expired"
              >Include expired notifications
              </mat-checkbox>
            </div>

          </form>
        </div>

      </mat-expansion-panel>
    </mat-accordion>

    <!--Table View-->
    <mat-accordion displayMode="flat" multi class="mat-table">

      <mat-card style="text-align: center" *ngIf="config.dataSource.data.length <= 0">
        <b>No Records Found!</b>
      </mat-card>

      <mat-expansion-panel *ngFor="let item of config.dataSource.data" hideToggle [disabled]="true"
                           [expanded]="item['expanded']" ngDefaultControl>
        <mat-expansion-panel-header style="height: auto !important;">
          <app-abstract-notification class="mat-row switch-row"
                                     *ngSwitchCase="NOTIF_CONSTS.VIEW_PAGE_LIST.includes(config.page) ? config.page : '' "
                                     (errorEmitter)="displayError($event)"
                                     [item]="item" [type]="NOTIF_CONSTS.PAGE.VIEW" (editItemEmitter)="toggle(item)"
                                     (deleteItemEmitter)="onPageChange($event)">
          </app-abstract-notification>
          <app-abstract-notification class="mat-row switch-row"
                                     *ngSwitchCase="NOTIF_CONSTS.MANAGE_PAGE_LIST.includes(config.page) ? config.page : '' "
                                     (errorEmitter)="displayError($event)"
                                     [item]="item" [type]="NOTIF_CONSTS.PAGE.MANAGE" (editItemEmitter)="toggle(item)"
                                     (deleteItemEmitter)="onPageChange($event)">
          </app-abstract-notification>
        </mat-expansion-panel-header>
        <!-- lazy -->
        <ng-template matExpansionPanelContent>
          <app-new-notification [pageConfig]="{'notificationId': item[config.idField], 'type': NOTIF_CONSTS.PAGE.VIEW}"
                                [isPublish]="false"
                                (editedEmitter)="onPageChange($event)"
                                *ngSwitchCase="NOTIF_CONSTS.VIEW_PAGE_LIST.includes(config.page) ? config.page : '' ">
          </app-new-notification>
          <app-new-notification
            [pageConfig]="{'notificationId': item[config.idField], 'type': NOTIF_CONSTS.PAGE.MANAGE}"
            [isPublish]="false"
            (closeEmitter)="onPageCollapse($event)"
            (editedEmitter)="onPageChange($event)"
            *ngSwitchCase="NOTIF_CONSTS.MANAGE_PAGE_LIST.includes(config.page) ? config.page : '' ">
          </app-new-notification>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-paginator [hidden]="!config.dataSource.data.length" [length]="resultsLength"
                   [pageSizeOptions]="[10, 25, 50, 75, 100]" [pageSize]="50" (page)="onPageChange($event)">
    </mat-paginator>

  </div>
</div>
