<div
  class="wrapper"
>
  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <mat-card
    fxLayout="column"
    fxLayoutGap="2%"
    class="mat-elevation-z2"
  >

    <!--Filter Menu-->
    <mat-accordion displayMode="flat">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <span class="material-icons filter-title">filter_alt</span>
          <span class="filter-title">Filter Notifications</span>
        </mat-expansion-panel-header>
        <div
          *ngIf="!isLoading"
        >
          <form
            fxLayout="column"
            fxLayoutGap="2%"
            (ngSubmit)="onSearch()"
            [formGroup]="searchForm"
          >
            <div
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutGap="2%"
              fxLayoutGap.lt-md="0"
            >
              <mat-form-field
                [fxFlex]="searchForm.controls.notType.value === getNotificationTypes.CHAIN ? '24%' : '48%'"
                fxFlex.lt-md="100%"
              >
                <mat-label>Title</mat-label>
                <input formControlName="title" matInput placeholder="Title" type="text"/>
                <mat-error *ngIf="searchForm.controls.title.errors?.pattern && searchForm.controls.title.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>

              <mat-form-field
                fxFlex="24%"
                fxLayout.lt-md="100%"
              >
                <mat-label>Notification Type</mat-label>
                <mat-select [formControl]="searchForm.controls.notType">
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let notificationType of notificationTypes" [value]="notificationType">
                    <span *ngIf="notificationType === NOTIF_CONSTS.NOTIF_TYPE.CHAIN">Account</span>
                    <span *ngIf="notificationType !== NOTIF_CONSTS.NOTIF_TYPE.CHAIN">{{ notificationType }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                fxFlex="24%"
                *ngIf="searchForm.controls.notType.value === getNotificationTypes.CHAIN"
              >
                <mat-label>Select Account</mat-label>
                <mat-select
                  formControlName="chains"
                  placeholder="Accounts"
                  [multiple]="true"
                  #chainMultiSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [showToggleAllCheckbox]="true"
                      (toggleAll)="toggleChainsSelectAll($event)"
                      [toggleAllCheckboxTooltipMessage]="tooltipMessage"
                      [toogleAllCheckboxTooltipPosition]="'above'"
                      [formControl]="chainMultiFilterCtrl"
                      placeholderLabel="Find account..."
                      noEntriesFoundLabel="'No matching account found'"
                    >
                      <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let chain of filteredChainsMulti | async"
                    [value]="chain.value.roomId"
                  >
                    {{ chain.value.roomName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field
                fxFlex="24%"
                fxLayout.lt-md="100%"
              >
                <mat-label>Enter a date range for notification date</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate formControlName="startNotificationDate"
                         placeholder="Start date">
                  <input matEndDate formControlName="endNotificationDate"
                         placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error *ngIf="searchForm.controls.startNotificationDate.hasError('matStartDateInvalid')">
                  Invalid start date
                </mat-error>
                <mat-error *ngIf="searchForm.controls.endNotificationDate.hasError('matEndDateInvalid')">
                  Invalid end date
                </mat-error>
                <mat-error *ngIf="searchForm.controls.startNotificationDate.errors?.pattern && searchForm.controls.startNotificationDate.dirty">
                  * Input not allowed
                </mat-error>
                <mat-error *ngIf="searchForm.controls.endNotificationDate.errors?.pattern && searchForm.controls.endNotificationDate.dirty">
                  * Input not allowed
                </mat-error>
              </mat-form-field>
            </div>

            <div
              fxLayout="row"
              fxLayout.lt-md="column"
            >
              <button
                fxFlex="100px"
                fxFlex.lt-md="100%"
                class="btn btn-primary"
                mat-raised-button
                style="background-color: #42a5f5"
                type="submit"
                [disabled]="searchDisabled || this.searchForm.invalid"
              >SEARCH</button>
              <button
                fxFlex="100px"
                fxFlex.lt-md="100%"
                class="btn btn-primary"
                mat-raised-button
                style="background-color:#fd2626"
                (click)="onSearchReset()"
                type="button"
              >CLEAR</button>
            </div>
          </form>
        </div>

      </mat-expansion-panel>
    </mat-accordion>

    <!--Table View-->
    <div
      class="scrollable"
    >
      <mat-card
        style="text-align: center"
        *ngIf="!dataSource.data.length && !isLoadingResults"
      >
        <b>No Records Found!</b>
      </mat-card>

      <table
        [hidden]="isLoading"
        [dataSource]="dataSource"
        *ngIf="dataSource.data.length && !isLoadingResults"
        mat-table
        class="mat-elevation-z2"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th *matHeaderCellDef class="w-200" mat-header-cell>Notification Title</th>
          <td *matCellDef="let row" mat-cell>
            <span *ngIf="row.title.length > 35">
              {{ row.title.substring(0, 32) }}
              <button
                mat-button
                class="more-button"
                matTooltip="{{ row.title }}"
                matTooltipClass="tooltip-description"
              >...
              </button>
            </span>
            <span *ngIf="row.title.length <= 35">
              {{ row.title }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="author">
          <th *matHeaderCellDef class="w-200" mat-header-cell>Author</th>
          <td *matCellDef="let row" mat-cell>
            <span *ngIf="row.author?.length > 10">
              {{ row.author?.substring(0, 10) }}
              <button
                mat-button
                class="more-button"
                matTooltip="{{ row.author }}"
                matTooltipClass="tooltip-description"
              >...
              </button>
            </span>
            <span
              *ngIf="row.author?.length <= 10"
            >{{ row.author }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="posted">
          <th *matHeaderCellDef class="w-150" mat-header-cell>Post Date</th>
          <td *matCellDef="let row" mat-cell>{{ row.posted | dateFormat }}</td>
        </ng-container>

        <ng-container matColumnDef="from">
          <th *matHeaderCellDef class="w-150" mat-header-cell>Valid From</th>
          <td *matCellDef="let row" mat-cell>{{ row.from | dateFormat }}</td>
        </ng-container>

        <ng-container matColumnDef="until">
          <th *matHeaderCellDef class="w-150" mat-header-cell>Valid Until</th>
          <td *matCellDef="let row" mat-cell>{{ row.until | dateFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="notType">
          <th *matHeaderCellDef class="w-150" mat-header-cell>Type</th>
          <td *matCellDef="let row" mat-cell>
            <span *ngIf="row.notType === NOTIF_CONSTS.NOTIF_TYPE.CHAIN">Account</span>
            <span *ngIf="row.notType !== NOTIF_CONSTS.NOTIF_TYPE.CHAIN">{{ row.notType }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="attach">
          <th *matHeaderCellDef class="w-75" mat-header-cell>Attachment</th>
          <td *matCellDef="let row" mat-cell>{{ returnAttachmentType(row.attach) }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.selected.length > 1 ? doNothing() : editSelected(row)"
        >
        </tr>
      </table>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="start"
      [hidden]="isLoading"
      *ngIf="dataSource.data.length && !isLoadingResults"
    >
      <mat-paginator
        fxShow.lt-md
        fxHide
        [hidden]="!dataSource.data.length"
        [length]="resultsLength"
        [pageSizeOptions]="[10, 25, 50, 75, 100]"
        [pageSize]="50"
        (page)="onPageChange($event)">
      </mat-paginator>

      <button
        fxFlex.lt-md="100%"
        (click)="publishSelected()"
        [disabled]="selection.selected.length === 0"
        [hidden]="!dataSource.data.length"
        class="btn btn-primary"
        mat-raised-button
        style="background-color: #42a5f5"
      >Publish
      </button>

      <mat-paginator
        fxShow
        fxHide.lt-md
        [hidden]="!dataSource.data.length"
        [length]="resultsLength"
        [pageSizeOptions]="[10, 25, 50, 75, 100]"
        [pageSize]="50"
        (page)="onPageChange($event)">
      </mat-paginator>
    </div>

  </mat-card>
</div>
