<div class="peaky-blinders-list ghost-ui" >
  <div class='item' *ngFor="let item of [0, 1, 2, 3, 4]">
    <div
      class="item-photo shimmer"
      fxFlex="340px" fxLayout="row" fxLayoutAlign="start center"
    >
      <div class="img-zoom-container shimmer">
        <div class="holder-image shimmer"></div>
      </div>
    </div>

    <div
      fxLayout="column"
      fxLayoutGap="10px"
      style="width: 100%; margin-left: 30px"
    >
      <div
        fxLayout="row"
        fxLayoutGap="50px"
      >
        <div
          fxLayout="row" fxLayoutAlign="start start"
          class="first-details shimmer"
        ></div>
        <div
          fxFlex fxLayout="row" fxLayoutAlign="end center"
          class="dates shimmer"
        ></div>
      </div>

      <div class="second-details shimmer"></div>
      <div class="title shimmer"></div>
      <div class="desc shimmer"></div>

      <div
        fxLayout="row" fxLayoutAlign="start start"
        style="width: 100%"
      >
        <div
          fxLayout="column"
          fxLayoutGap="5px"
          style="width: 100%"
        >
          <div class="resp shimmer"></div>
          <div class="resp1 shimmer"></div>
          <div class="resp2 shimmer"></div>
        </div>
      </div>

    </div>

  </div>
</div>
