<div
  fxLayout="column"
  fxLayoutGap="20px"
>
  <app-msg-banner *ngIf="showNotification" [msgList]="messageList" (msgListChange)="messageList = $event"></app-msg-banner>

  <div
    fxFlex
    fxLayout="row"
  >
    <div
      fxFlex
      fxLayoutAlign="start"
      class="title"
    >{{ action }} User
    </div>

    <div
      fxFlex
      fxLayoutAlign="end"
    >
      <mat-icon
        (click)="closeDialog()"
        class="close-button"
      >close
      </mat-icon>
    </div>
  </div>

  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="20px"
  >
    <form
      [formGroup]="form"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
    >
      <mat-form-field
        hintLabel="Max 40 characters"
        appearance="standard"
        fxFlex="23.5%"
        fxFlex.lt-md="100%"
      >
        <input
          type="email"
          formControlName="usrNam"
          matInput
          #input
          maxlength="40"
          placeholder="Enter User Email"
          id="email"
          [matAutocomplete]="auto"
          required
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option
            *ngFor="let user of filteredUsers"
            [value]="user.mail"
            (onSelectionChange)="saveFullName(user)"
          >
            {{ user.displayName }} ({{ user.employeeId }})
          </mat-option>
        </mat-autocomplete>
        <mat-hint align="end">{{ input.value?.length || 0 }}/40</mat-hint>
      </mat-form-field>

      <mat-form-field
        appearance="standard"
        fxFlex="23.5%"
        fxFlex.lt-md="100%"
      >
        <mat-label>Select Role</mat-label>
        <mat-select [compareWith]="compareFunction" formControlName="usrRole">
          <mat-option *ngFor="let role of roles" [value]="role">
            {{ role }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="standard"
        fxFlex="23.5%"
        fxFlex.lt-md="100%"
      >
        <mat-label>Select Account</mat-label>
        <mat-select
          formControlName="chains"
          placeholder="Accounts"
          [multiple]="true"
          #chainMultiSelect
        >
          <mat-option>
            <ngx-mat-select-search
              [showToggleAllCheckbox]="true"
              (toggleAll)="toggleChainsSelectAll($event)"
              [toggleAllCheckboxTooltipMessage]="tooltipMessage"
              [toogleAllCheckboxTooltipPosition]="'above'"
              [formControl]="chainMultiFilterCtrl"
              placeholderLabel="Find account..."
              noEntriesFoundLabel="'No matching chain found'"
            >
              <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let chain of filteredChainsMulti | async"
            [value]="chain"
          >
            {{ getCahinNameById(chain) }} ({{ chain }})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="standard"
        fxFlex="23.5%"
        fxFlex.lt-md="100%"
      >
        <mat-label>Select Territory</mat-label>
        <mat-select
          formControlName="terrs"
          placeholder="Territories"
          [multiple]="true"
          #territoryMultiSelect
        >
          <mat-option>
            <ngx-mat-select-search
              [showToggleAllCheckbox]="true"
              (toggleAll)="toggleTerrsSelectAll($event)"
              [toggleAllCheckboxTooltipMessage]="tooltipMessage"
              [toogleAllCheckboxTooltipPosition]="'above'"
              [formControl]="territoryMultiFilterCtrl"
              placeholderLabel="Find territory..."
              noEntriesFoundLabel="'No matching territory found'"
            >
              <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let territory of filteredTerritoriesMulti | async"
            [value]="territory"
          >
            {{ territory }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
      fxLayoutGap.lt-md="0"
    >
      <button
        fxFlex="100px"
        fxFlex.lt-md="100%"
        id="submit"
        class="btn btn-submit"
        (click)="onSubmit()"
        [disabled]="!form.valid"
      >{{ action }}
      </button>
      <button
        fxFlex="100px"
        fxFlex.lt-md="100%"
        id="cancel"
        class="btn btn-cancel"
        (click)="onCancel()"
      >Cancel
      </button>
    </div>

  </mat-dialog-content>
</div>
