import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Training} from '../../../shared/models/training.model';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-training-dialog',
  templateUrl: './training-dialog.component.html',
  styleUrls: ['./training-dialog.component.scss']
})
export class TrainingDialogComponent implements OnInit {

  form: FormGroup;
  action: string;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TrainingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Training
  ) {
    if (data) {
      this.form = this.fb.group({
        trngId: [data.trngId],
        name: [data.name, [Validators.required, Validators.pattern(environment.emojiRegEx)]],
        url: [data.url, Validators.required],
      });
      this.action = 'Update';
    } else {
      this.form = this.fb.group({
        trngId: [null],
        name: ['', [Validators.required, Validators.pattern(environment.emojiRegEx)]],
        url: ['', Validators.required],
      });
      this.action = 'Add';
    }
  }

  ngOnInit(): void {
  }

  onSubmit() {
    const training: Training = new Training();
    if (this.form.value) {
      Object.keys(training).forEach(key => {
        training[key] = this.form.value[key];
      });
    }
    this.dialogRef.close(training);
  }

  onCancel() {
    this.dialogRef.close();
  }

}
