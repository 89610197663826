import { Chain } from '../../../shared/models/chain';
import { StringResponse } from '../../../shared/models/string-response';
import { IPagedResponse } from '../../../shared/models/IPagedResponse';
import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PortalUser } from '../../../shared/models/portalUser';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { Dialog } from '../../../shared/models/dialog';
import { AdminService } from '../../../core/services/admin.service';


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = [
    'email',
    'role',
    'chains',
    'territories',
    'actions',
  ];

  pageEvent: PageEvent;
  pageSize = 50;
  totalRows = 0;

  showNotification = false;
  isSuccess = false;
  searchValue: string = null;

  users: PortalUser[] = [];
  dataSource = new MatTableDataSource<PortalUser>();

  chains: Chain[] = [];

  constructor(
    private dialog: MatDialog,
    private service: AdminService
  ) {}

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.dataSource.filterPredicate = (data: PortalUser, filter: string) =>
      (data.usrNam +
        data.usrRole +
        data.terrs.join() +
        this.getChains(data.chains)
      )
        .trim()
        .toLowerCase()
        .indexOf(filter) !== -1;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'email': return item.usrNam;
        case 'chains':
          return this.getChains(item.chains);
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.populateDefaultValues().then(result => {
      if (result) {
        this.getPortalUsers();
      } else {
        this.showErrorMessage();
      }
    })
  }

  ngOnDestroy(): void {

  }

  openAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.service.createUserPortal(data).subscribe(
            (response: StringResponse) => {
              this.showConfirmationMessageAndRetrieveData(response.response)
            },
            (error) => {
              this.showErrorMessage();
            }
          )
        }

      }, error => {
        this.showErrorMessage();
      });
  }

  openEdit(userData: PortalUser) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = userData;
    const index = this.dataSource.data.findIndex(data => data.usrNam === userData.usrNam);
    const dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.service.editUserPortal(data).subscribe(
            (response: StringResponse) => {
              this.showConfirmationMessageAndRetrieveData(response.response)
            },
            (error) => {
              this.showErrorMessage();
            }
          )
        }

      }, error => {
        this.showErrorMessage();
      });
  }

  showErrorMessage() {
    this.showNotification = true;
    this.isSuccess = false;
  }

  showConfirmationMessageAndRetrieveData(message: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(message, false, false, false),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      response => {
        this.getPortalUsers();
        if (response) {
          return;
        }
      }
    );
  }

  onDelete(userData: PortalUser) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog('Do you want to delete this user?', true, false, true),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      response => {
        if (response) {
          this.service.deleteUserPortal(userData).subscribe(
            (response: StringResponse) => {
              this.showConfirmationMessageAndRetrieveData(response.response);
            },
            (error) => {
              this.showErrorMessage();
            }
          )
        }
      }
    );
  }

  getChains(chains: string[]): string {
    let chainString = '';
    for (const chain of chains) {
      chainString += chain
    }
    return chainString;
  }

  applyFilter(clearKeyUp?: boolean) {
    if (clearKeyUp && this.searchValue != null && this.searchValue.length == 0) {
      this.paginator.pageIndex = 0;
      this.getPortalUsers();
    } else if (clearKeyUp == undefined) {
      this.paginator.pageIndex = 0;
      this.getPortalUsers();
    }

  }

  public getServerData(event?: PageEvent) {
    this.getPortalUsers();
    return event;
  }

  chainString(chains: string[]): string {
    if (chains) {
      return chains.map(c => this.getCahinNameById(c)).join(', ');
    }
    return '';
  }

  territoryString(territories: string[]) {
    if (territories) {
      return territories.join(', ');
    }
    return '';
  }

  getCahinNameById(chainId: string) {
    return this.chains.find((chain: Chain) => chain.chainId == chainId)?.chainName;
  }

  populateDefaultValues() {
    return new Promise((release, reject) => {
      this.service.getChains().subscribe(
        (response: any) => {
          this.chains = response;
          release(true);
        },
        (error) => {
          this.showErrorMessage();
          release(false);
        });
    })
  }
  getPortalUsers() {
    let pageIndex = this.paginator.pageIndex ? this.paginator.pageIndex : 0;
    let pageSize = this.paginator.pageSize ? this.paginator.pageSize : 5;
    this.service.getUsersPortal(pageIndex, pageSize, this.searchValue).subscribe(
      (response: IPagedResponse<PortalUser>) => {
        this.totalRows = response.totalElements
        this.dataSource = new MatTableDataSource<PortalUser>(response.content);
      },
      (error: any) => {
        this.dataSource = new MatTableDataSource<PortalUser>();
        this.totalRows = 0;
        this.showErrorMessage();
      }
    )
  }

  sortTable(sort: Sort) {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data.sort((a, b) => {
        return compare(a.usrNam, b.usrNam, true);
      });
      return;
    }
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'email': return compare(a.usrNam, b.usrNam, isAsc);
        case 'role': return compare(a.usrRole, b.usrRole, isAsc);
        case 'chains': return compare(this.chainString(a.chains), this.chainString(b.chains), isAsc);
        case 'territories': return compare(this.territoryString(a.terrs), this.territoryString(b.terrs), isAsc);
        default: return 0;
      }
    });

    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }
}
