export enum ActivityStatusEnum {
  OPEN = 1,
  PENDING = 2,
  COMPLETE = 3,
  CANCELED = 4
}

export class ActivityStatus {
  actStaId: number;
  en: string;
}
