import {AbstractControl, ValidatorFn} from '@angular/forms';
import {environment} from '../../../../environments/environment';

export class CustomFieldsValidators {
  static maxDescription(control: AbstractControl): any | null {
    return CustomFieldsValidators.maxLengthTrim(environment.lengthValidators.description)(control);
  }

  static maxTitle(control: AbstractControl): any | null {
    return CustomFieldsValidators.maxLength(environment.lengthValidators.title)(control);
  }

  static maxComment(control: AbstractControl): any | null {
    return CustomFieldsValidators.maxLength(environment.lengthValidators.comment)(control);
  }

  static maxResponseMessage(control: AbstractControl): any | null {
    return CustomFieldsValidators.maxLength(environment.lengthValidators.response)(control);
  }

  static maxTitleOfSets(control: AbstractControl): any | null {
    return CustomFieldsValidators.maxLength(environment.lengthValidators.titleOfSet)(control);
  }

  static maxLengthTrim(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const text: any = !control.value ? '' : control.value;
      return text.replaceAll('&nbsp;', ' ').replace(/<[^>]*>/g, '').replace(/\n/g, "").length <= maxLength
        ? null : { maxLength:  CustomFieldsValidators.getErrorMsg('maxLength', maxLength) };
    };
  }

  static maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const text = control.value || '';
      return text.length <= maxLength
        ? null : { maxLength: CustomFieldsValidators.getErrorMsg('maxLength', maxLength) };
    };
  }

  static getErrorMsg(type: String, msg: any) {
    switch (type) {
      case 'maxLength':
        return  'The field cannot have more than ' + msg + ' characters.';
      default:
        return  'The field didn\'t pass the validation.';
    }
  }
}
