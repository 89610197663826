import {Component, Inject, OnInit} from '@angular/core';
import {AttachmentConfig, AttachmentView} from '../../models/attachment.config';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FileNode} from '../generic-file-structure/generic-file-structure.component';

@Component({
  selector: 'app-generic-attachment-dialog',
  templateUrl: './generic-attachment-dialog.component.html',
  styleUrls: ['./generic-attachment-dialog.component.scss']
})
export class GenericAttachmentDialogComponent implements OnInit {

  uploadPath: string;
  nodes: FileNode[] = [];

  showUpload = false;

  constructor(public dialogRef: MatDialogRef<GenericAttachmentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AttachmentConfig) { }

  ngOnInit(): void {
    if (this.data.view.valueOf() === AttachmentView.TEMP) {
      this.uploadPath = '';
      this.showUpload = true;
    }
  }

  buildConfig() {
    return {
      view: this.data.view,
      isArchived: this.data.isArchived
    };
  }

  uploadView(path: string) {
    this.uploadPath = path;
    this.showUpload = true;
  }

  fileView(event: boolean) {
    this.showUpload = event;
  }

  saveFileStructureState(nodes: FileNode[]) {
    this.nodes = nodes;
  }

  closeDialog(path: string) {
    this.dialogRef.close(path);
  }
}
