import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ActivityConstants} from '../../constants/ActivityConstants';
import {MsgBannerService} from '../msg-banner/msg-banner.service';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {MatSort} from '@angular/material/sort';
import {IPagedResponse} from '../../models/IPagedResponse';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {of, ReplaySubject, Subject} from 'rxjs';
import {Chain} from '../../models/chain';
import {debounceTime, finalize, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ActivityTypes} from '../../constants/ActivityTypes';
import {GenericActivityView} from '../../models/activity/generic-activity-view.model';
import {Season} from '../../models/season.model';
import {Location} from '../../models/location.model';
import {ActivityType} from '../../models/activity/activity-type.model';
import {ActivityStatus, ActivityStatusEnum} from '../../models/activity/activity-status.model';
import {User} from '../../models/activity/user.model';
import {ActivityView} from '../../models/activity/activity-view.model';
import {NewActivityService} from '../../../core/services/new-activity.service';
import {FindActivityService} from '../../../core/services/find-activity.service';
import {UserService} from '../../../core/services/user.service';
import {AdminService} from '../../../core/services/admin.service';
import {GraphService} from '../../../core/graph/graph.service';
import {UtilsService} from '../../../core/services/utils.service';
import {ActivityDetailComponent} from '../../../modules/pages/activity/activity-detail/activity-detail.component';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../models/dialog';
import {AzureUser} from '../../models/azure-user.model';
import {AzureUsersPage} from '../../models/azure-users-page.model';
import {AG_ROLE} from '../../constants/roles';
import {RoleGuardService} from '../../../core/guards/role-guard.service';
import {CounterService} from '../../../core/services/counter.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-generic-activity-view',
  templateUrl: './generic-activity-view.component.html',
  styleUrls: ['./generic-activity-view.component.scss'],
})
export class GenericActivityViewComponent implements OnInit, OnDestroy {

  @Input() config: GenericActivityView;

  @ViewChild('chainMultiSelect', { static: true }) chainMultiSelect: MatSelect;
  public chainMultiFilterCtrl: FormControl = new FormControl();
  public filteredChainsMulti: ReplaySubject<string[]> = new ReplaySubject<
    string[]
    >(1);
  chainRestricted = true;
  chains: Chain[] = [];
  protected _onDestroy = new Subject<void>();
  public tooltipMessage = 'Select All / Unselect All';

  @ViewChild('staMultiSelect', { static: true }) staMultiSelect: MatSelect;
  public staMultiFilterCtrl: FormControl = new FormControl();
  public filteredStaMulti: ReplaySubject<number[]> = new ReplaySubject<number[]>(1);

  @ViewChild('terrMultiSelect', { static: true }) terrMultiSelect: MatSelect;
  public terrMultiFilterCtrl: FormControl = new FormControl();
  public filteredTerrsMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  terrRestricted = true;
  terrs: string[] = [];

  isLoading = true;
  searchDisabled = false;
  isLoadingResults = false;
  isError = false;
  requestSent = false;
  resultsLength: number;

  showNotification = false;
  messageList = [];

  locations: Location[] = [];
  seasons: Season[] = [];
  activityStatuses: ActivityStatus[];
  activityType: ActivityType[] = [];
  filteredUsers: User[] = [];
  filteredPortalUsers: AzureUser[] = [];

  searchForm: FormGroup;
  dataSource = new MatTableDataSource<ActivityView>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns = ActivityConstants.SEARCH.displayedColumns;
  activities: ActivityView[] = [];
  selection = new SelectionModel<ActivityView>(true, []);

  sort;
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'activityId': return this.getActivityId(item.id);
          case 'title': return item.title;
          case 'chain': return item.chain;
          case 'store': return item.str;
          case 'startDate': return item.start;
          case 'endDate': return item.end;
          case 'expirationDate': return item.exp;
          case 'type': return this.activityType.find(a => a.actTypeId === item.actType)?.en;
          case 'location': return this.locations.find(l => l.locId === item.loc)?.en;
          case 'season': return this.seasons.find(s => s.seasId === item.seas)?.en;
          case 'shipperCount': return item.shprCt;
          case 'personResponsible': return item.usrNam;
          case 'taskStatus': return this.getStatusName(item.sta);
          case 'createdBy': return item.createdBy;
          case 'publishedBy': return item.publishedBy;
          case 'attach': return this.returnAttachmentType(item.attach);
          default: return item[property];
        }
      };
    }
  }

  filterArgs = { active: true };

  constructor(private formBuilder: FormBuilder,
              private msgBanner: MsgBannerService,
              private counterService: CounterService,
              private dialog: MatDialog,
              private datePipe: DatePipe,
              private graphService: GraphService,
              private roleGuardService: RoleGuardService,
              private adminService: AdminService,
              private userService: UserService,
              private changeDetectorRef: ChangeDetectorRef,
              private findActivityService: FindActivityService,
              private newActivityService: NewActivityService) {
    this.searchForm = this.formBuilder.group({
      activityId: [ null, Validators.pattern(environment.emojiRegEx), []],
      title: ['', Validators.pattern(environment.emojiRegEx)],
      chain: [ null, Validators.pattern(environment.emojiRegEx), []],
      store: [ null, Validators.pattern(environment.emojiRegEx), []],
      territory: [ null, Validators.pattern(environment.emojiRegEx), []],
      storeOwnRef: [ null, Validators.pattern(environment.emojiRegEx), []],
      startBeginDate: [ null, Validators.pattern(environment.emojiRegEx), []],
      startFinishDate: [ null, Validators.pattern(environment.emojiRegEx), []],
      endBeginDate: [ null, Validators.pattern(environment.emojiRegEx), []],
      endFinishDate: [ null, Validators.pattern(environment.emojiRegEx), []],
      type: [ null, [], []],
      season: [ null, [], []],
      location: [ null, [], []],
      sta: [null, [], []],
      personResponsible: [ null, Validators.pattern(environment.emojiRegEx), []],
      expiredOld: [false, [], []],
      attach: [null, Validators.pattern(environment.emojiRegEx), []],
      createdBy: [null, Validators.pattern(environment.emojiRegEx), []]
    });
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;

    if (this.config.page === 'publish') {
      this.initCreatedBy();
    }

    this.searchForm.controls.personResponsible.valueChanges.pipe(
      debounceTime(300),
      tap(() => this.isLoading = false),
      switchMap(value => {
          if (value?.length < 3) {
            this.filteredUsers = [];
            return of(null);
          }
          if (value instanceof Object || value === '' || !value) {
            return of(null);
          }

          return this.userService.getUsers(value, []).pipe(
            finalize(() => this.isLoading = false)
          );
        }
      )
    ).subscribe(users => this.filteredUsers = users);

    if (this.config.page === 'publish') {
      this.loadLocation();
      this.loadSeason();
    }
    this.loadActivityType();
    this.loadActivityStatuses();

    if (this.config.page === 'manage') {
      this.displayedColumns = this.displayedColumns.concat('personResponsible');
      this.displayedColumns = this.displayedColumns.concat('activityId');
      this.displayedColumns = this.displayedColumns.concat('publishedBy');

      // remote type, season and location columns
      var typeIdx = this.displayedColumns.indexOf("type")
      typeIdx >= 0 ? this.displayedColumns.splice(typeIdx, 1) : null;
      var seasIdx = this.displayedColumns.indexOf("season")
      seasIdx >= 0 ? this.displayedColumns.splice(seasIdx, 1) : null;
      var locIdx = this.displayedColumns.indexOf("location")
      locIdx != -1 ? this.displayedColumns.splice(locIdx, 1) : null;

      this.move(this.displayedColumns, this.displayedColumns.findIndex(c => c === 'personResponsible'), 6);
      this.move(this.displayedColumns, this.displayedColumns.findIndex(c => c === 'activityId'), 1);

    } else {
      this.displayedColumns = this.displayedColumns.concat('attach');
      this.move(this.displayedColumns, this.displayedColumns.findIndex(c => c === 'attach'), 9);
    }

    Promise.all(this.getChainsAndTerrs(this.graphService.user.displayName)).then(() => {
      // this.getData();
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  publishedBy(row) {
    if (row.publishedBy === null) {
      return row.createdBy;
    }
    return row.publishedBy;
  }

  initCreatedBy() {
    this.searchForm.controls.createdBy.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => (this.isLoading = false)),
        switchMap((value) => {
          if (value?.length < 3) {
            this.filteredUsers = [];
            return of(null);
          }
          if (value instanceof Object || value === '' || !value) {
            return of(null);
          }

          return this.adminService
            .searchAzureUsers(value)
            .pipe(finalize(() => (this.isLoading = false)));
        })
      )
      .subscribe(
        (users: AzureUsersPage) => (this.filteredPortalUsers = users?.value)
      );
  }

  move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      let k = new_index - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  loadActivityType() {
    this.showNotification = false;
    this.newActivityService.getActivityType().subscribe(
      result => {
        this.activityType = result;
        if (this.config.page === 'manage') {
          // set activity type to personal by default
          this.searchForm.controls.type.setValue(ActivityTypes.PERSONAL.valueOf());
          this.searchForm.controls.type.updateValueAndValidity();
        }
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  loadLocation() {
    this.showNotification = false;
    this.newActivityService.getLocations().subscribe(
      result => {
        this.locations = result;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  loadSeason() {
    this.showNotification = false;
    this.newActivityService.getSeasons().subscribe(
      result => {
        this.seasons = result;
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  refresh(existing: ActivityView, updated: ActivityView) {
    const data = this.dataSource.data;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === existing.id) {
        data[i] = updated;
        break;
      }
    }
    this.dataSource = new MatTableDataSource<ActivityView>(data);
  }

  toggle(item) {
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].id === item.id) {
        this.dataSource.data[i]['expanded'] = true;
        continue;
      }
      this.dataSource.data[i]['expanded'] = false;
    }
  }

  onSearch() {
    this.requestSent = true;
    this.showNotification = false;
    this.paginator.pageIndex = 0;
    this.selection = new SelectionModel<ActivityView>(true, []);
    this.messageList = [];
    this.getData();
  }

  onSearchReset() {
    this.searchForm.reset();
    this.searchDisabled = false;
    this.onSearch();
  }

  onPageChange(event: PageEvent) {
    this.loadData(event.pageIndex, event.pageSize);
  }

  getData() {
    if (this.paginator) {
      this.loadData(this.paginator.pageIndex, this.paginator.pageSize);
    } else {
      this.loadData(0, 10);
    }
  }

  loadData(pageIndex?: number, pageSize?: number) {
    this.isLoadingResults = true;
    this.isError = false;
    this.activities = [];
    const taskStatus = this.config.page === 'manage' ? ActivityStatusEnum.OPEN : null;
    this.messageList = [];

    if (this.config.page === 'manage') {
      // set activity type to personal by default
      this.searchForm.controls.type.setValue(ActivityTypes.PERSONAL.valueOf());
      this.searchForm.controls.type.updateValueAndValidity();
    } else if (this.isTerrOrMerchManager()) {
      this.searchForm.controls.type.setValue(ActivityTypes.PERSONAL.valueOf());
      this.searchForm.controls.type.updateValueAndValidity();
    }

    if (this.chainRestricted && (this.searchForm.controls.chain.value === null || this.searchForm.controls.chain.value.length === 0)) {
      this.searchForm.controls.chain.setValue(this.chains.map(chain => chain.chainId));
    }

    if (this.terrRestricted && (this.searchForm.controls.territory.value === null || this.searchForm.controls.territory.value?.length === 0)) {
      this.searchForm.controls.territory.setValue(this.terrs);
    }

    if (this.searchForm.controls.sta.value === '') this.searchForm.controls.sta.setValue(null);
    if (this.searchForm.controls.startBeginDate.value != null) this.searchForm.controls.startBeginDate.setValue(this.convertDate(this.searchForm.controls.startBeginDate.value));
    if (this.searchForm.controls.startFinishDate.value != null) this.searchForm.controls.startFinishDate.setValue(this.convertDate(this.searchForm.controls.startFinishDate.value));
    if (this.searchForm.controls.endBeginDate.value != null) this.searchForm.controls.endBeginDate.setValue(this.convertDate(this.searchForm.controls.endBeginDate.value));
    if (this.searchForm.controls.endFinishDate.value != null) this.searchForm.controls.endFinishDate.setValue(this.convertDate(this.searchForm.controls.endFinishDate.value));

    if (this.config.page === 'manage') {
      this.searchForm.controls.chain.setValue(null);
      this.searchForm.controls.territory.setValue(null);
    }

    this.findActivityService.getData(pageIndex, pageSize, this.searchForm.value, taskStatus).subscribe(
      (response: IPagedResponse<ActivityView>) => {
        this.activities = response.content;
        this.dataSource = new MatTableDataSource<ActivityView>(this.activities);

        this.isLoadingResults = false;
        this.resultsLength = response.totalElements;
      }, error => {
        this.dataSource = new MatTableDataSource();

        this.isError = false;
        this.resultsLength = 0;

        if (error.status === 404) {
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.isLoadingResults = false;
        this.showNotification = true;
      }
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;

    const numRowsMinusExcluded = this.dataSource.data.length;

    return numSelected >= numRowsMinusExcluded;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ActivityView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  /** Keep the state of the checkbox when page changed */
  isChecked(row: any): boolean {
    const found = this.selection.selected.find(el => el.id === row.id);
    return !!found;
  }

  editSelected(row: ActivityView) {
    let dialogRef;
    if (this.selection.selected.length === 0) {
      const data = {
        taskId: row.id,
        page: this.config.page
      };
      dialogRef = this.dialog.open(ActivityDetailComponent, {
        data: data
      });
    }

    if (this.selection.selected.length > 1) {
      const data = {
        taskId: this.selection.selected,
        page: this.config.page
      };
      dialogRef = this.dialog.open(ActivityDetailComponent, {
        data: data
      });
    }

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.length > 0) {
        if (this.checkDeleted(result)) { // removed
          this.removeItemFromDatasource(result);
        } else { // update
          for (let i = 0; i < result.length; i++) {
            this.getData();
            this.changeDetectorRef.detectChanges();
          }
        }
        this.selection = new SelectionModel<ActivityView>(true, []);
      } else if (result) {
        this.getData();
        this.changeDetectorRef.detectChanges();
        this.selection = new SelectionModel<ActivityView>(true, []);
      }
    });
  }

  checkDeleted(result) {
    if (result && Array.isArray(result)) {
      if (result.every(i => (typeof i === 'string'))) {
        return true;
      }
    }

    return false;
  }

  getStatusName(id: number) {
    return ActivityConstants.ACTIVITY_STATUS.find(a => a.id === id).name;
  }

  publishSelected() {
    this.showNotification = false;
    if (this.selection.selected.length === 0) {
      return;
    }

    const selected = [];
    for (const sel of this.selection.selected) {
      if (this.returnAttachmentType(sel.attach) === 'File'
        && !sel.attach[0].includes(ActivityConstants.ATTACHMENT_FOLDERS.SEASONAL)
        && !sel.attach[0].includes(ActivityConstants.ATTACHMENT_FOLDERS.CHAIN)
        && !sel.attach[0].includes(ActivityConstants.ATTACHMENT_FOLDERS.EVERYDAY)) {
        selected.push(sel);
      }
    }

    if (selected.length > 0) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: new Dialog('You are publishing an activity with a temporary file. If you proceed the attachment will be lost. Do you want to proceed?',
          true, false, true),
        disableClose: true
      }).afterClosed().subscribe((res) => {
        if (res) {
          this.publishActivity();
        }
      });
      return;
    }
    this.publishActivity();
  }

  publishActivity() {
    const data: string[] = [];
    for (const activity of this.selection.selected) {
      data.push(activity.id);
    }

    this.findActivityService.publishActivity(data).subscribe(
      response => {
        this.removeItemFromDatasource(response);
        this.counterService.refreshActivityCounter(true);
      }, error => {
        if (error.status === 404 || error.status === 400) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  private removeItemFromDatasource(activityGroupIds: string[]) {
    const data = this.dataSource.data;
    for (let i = 0; i < activityGroupIds.length; i++) {
      const index = data.findIndex(d => d.id === activityGroupIds[i]);
      data.splice(index, 1);
    }

    this.getData();
    this.changeDetectorRef.detectChanges();
    this.selection = new SelectionModel<ActivityView>(true, []);
  }

  doNothing() {
    return;
  }

  getActivityId(id: string): string {
    return id.substring(id.lastIndexOf('_') + 1);
  }

  getActivityType(actType: number): string {
    return this.activityType.find(a => a.actTypeId === actType)?.en;
  }

  getSeason(seasId: number) {
    if (seasId) {
      return this.seasons.find(a => a.seasId === seasId)?.en;
    }
    return '';
  }

  getLocation(locId: number) {
    if (locId) {
      return this.locations.find(a => a.locId === locId)?.en;
    }
    return '';
  }

  getChainsAndTerrs(displayName: string): Promise<boolean>[] {
    this.showNotification = false;
    const promiseList: Promise<boolean>[] = [];

    promiseList.push(
      new Promise((release, reject) => {
        this.adminService.getChainsByEmail(displayName).subscribe(
          response => {
            this.chains = response;
            if (response.length === 0) {
              this.chainRestricted = false;
            } else {
              this.chainRestricted = true;
              this.initDropDownList();
            }
            release(true);
          }, error => {
            if (error.status === 404) {
              this.msgBanner.addMsgError(this.messageList, error.error.message);
            } else {
              this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            }
            this.showNotification = true;
            reject(error);
          }
        );
      })
    );

    promiseList.push(
      new Promise((release, reject) => {
        this.adminService.getTerrsByEmail(displayName).subscribe(
          response => {
            this.terrs = response;
            if (response.length === 0) {
              this.terrRestricted = false;
            } else {
              this.terrRestricted = true;
              this.initDropDownList();
            }
            release(true);
          }, error => {
            if (error.status === 404) {
              this.msgBanner.addMsgError(this.messageList, error.error.message);
            } else {
              this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            }
            this.showNotification = true;
            reject(error);
          }
        );
      })
    );

    return promiseList;
  }

  private initDropDownList() {
    if (this.chainRestricted) {
      this.filteredChainsMulti.next(this.chains.map(chain => chain.chainId));
      this.chainMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterChainsMulti();
        });
    }

    if (this.terrRestricted) {
      this.filteredTerrsMulti.next(this.terrs);
      this.terrMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterTerrsMulti();
        });
    }
  }

  getChainNameById(chainId: string) {
    return this.chains.find((chain: Chain) => chain.chainId === chainId).chainName;
  }

  toggleChainsSelectAll(selectAllValue: boolean) {
    this.filteredChainsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.searchForm.controls.chain.patchValue(val);
        } else {
          this.searchForm.controls.chain.patchValue([]);
        }
      });
  }

  toggleTerrsSelectAll(selectAllValue: boolean) {
    this.filteredTerrsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.searchForm.controls.territory.patchValue(val);
        } else {
          this.searchForm.controls.territory.patchValue([]);
        }
      });
  }

  protected filterChainsMulti() {
    if (!this.chains) {
      return;
    }
    // get the search keyword
    let search = this.chainMultiFilterCtrl.value;
    if (!search) {
      this.filteredChainsMulti.next(this.chains.map(chain => chain.chainId));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredChainsMulti.next(
      this.chains
        .filter(
          (chain) =>
            this.getChainNameById(chain.chainId)
              .concat(chain.chainId)
              .toLowerCase()
              .indexOf(search) > -1
        ).map(chain => chain.chainId)
    );
  }

  protected filterTerrsMulti() {
    if (!this.terrs) {
      return;
    }
    // get the search keyword
    let search = this.terrMultiFilterCtrl.value;
    if (!search) {
      this.filteredTerrsMulti.next(this.terrs);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the terrs
    this.filteredTerrsMulti.next(
      this.terrs
        .filter((territory) => territory.toLowerCase().indexOf(search) > -1)
    );
  }

  valueMapper(user: User): string {
    return user?.nam;
  }

  toggleStatusSelectAll(selectAllValue: boolean) {
    this.filteredStaMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.searchForm.controls.sta.patchValue(val);
        } else {
          this.searchForm.controls.sta.patchValue([]);
        }
      });
  }

  loadActivityStatuses() {
    this.newActivityService.getActivityStatuses().subscribe(
      result => {
        this.activityStatuses = result;

        this.initActivityStatuses();
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  private initActivityStatuses() {
    this.filteredStaMulti.next(this.activityStatuses.map(sta => sta.actStaId).slice(0, 50));

    this.staMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterStaMulti();
      });
  }

  getActivityStatusById(staId: number) {
    return this.activityStatuses.find((sta: ActivityStatus) => sta.actStaId === staId).en;
  }

  protected filterStaMulti() {
    if (!this.activityStatuses) {
      return;
    }
    // get the search keyword
    let search = this.staMultiFilterCtrl.value;
    if (!search) {
      this.filteredStaMulti.next(this.activityStatuses.map(sta => sta.actStaId).slice(0, 50));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredStaMulti.next(
      this.activityStatuses
        .filter(
          (sta) =>
            sta.en.toLowerCase().indexOf(search) > -1
        ).map(sta => sta.actStaId)
        .slice(0, 50)
    );
  }

  convertDate(value: any) {
    if (value instanceof Date) {
      return value;
    }
    if (!value._i['year'] && value._i.getTime() === value._d.getTime()) {
      return value.toDate();
    }
    return UtilsService.dateFromServer(value.toDate());
  }

  onChange(event: MatSelectChange) {
    if (event.value?.length === 0) {
      this.searchForm.controls.sta.setValue(null);
    }
  }

  get activityTypes() {
    return ActivityTypes;
  }

  returnAttachmentType(attach: string[]) {
    return attach && attach.length > 0 && attach[0].startsWith('http') ? 'URL' :
      attach && attach.length > 0 && !attach[0].startsWith('http') ? 'File' : '';
  }

  isTerrOrMerchManager() {
    if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS)) {
      return true;
    }
    return false;
  }

  get getEnvironment() {
    return environment;
  }

  get getActivityTypes() {
    return ActivityTypes;
  }

  openDialog(message: string, showYes: boolean, isLoading: boolean, showNo: boolean): any {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(message, showYes, isLoading, showNo),
      disableClose: true,
    });
  }

  deleteSelected() {
    this.showNotification = false;
    const dialogRef = this.openDialog(
      'Do you want to delete these activities?',
      true,
      false,
      true
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {

        var deletedIds: string[] = this.selection.selected.map(a => a.id);
        this.findActivityService.deleteActivityFromGroup(deletedIds).subscribe(
          (response) => {
            this.openDialog('Activities successfully deleted!', false, false, false);
            this.getData();
            this.changeDetectorRef.detectChanges();
            this.selection = new SelectionModel<ActivityView>(true, []);
          },
          (error) => {
            this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            this.showNotification = true;
          }
        );
      }
    });
  }
}
