import {Component, EventEmitter, Injectable, Input, OnInit, Output} from '@angular/core';
import {AttachmentConfig, AttachmentView} from '../../models/attachment.config';
import {AttachmentService} from '../../../core/services/attachment.service';
import {MsgBannerService} from '../msg-banner/msg-banner.service';
import {BehaviorSubject} from 'rxjs';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AwsFile} from '../../models/aws-file.model';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../models/dialog';
import {MatDialog} from '@angular/material/dialog';
import {AG_ROLE} from '../../constants/roles';
import {RoleGuardService} from '../../../core/guards/role-guard.service';
import { SelectionModel } from '@angular/cdk/collections';

export class FileNode {
  children: FileNode[];
  item: FileAws;
}

export class FileAws {
  name: string;
  path: string;
  fileSize: number;
  lastModifiedDate: Date;
  lastModifiedBy: string;
  type: string;
  isRoot: boolean;
  isNew: boolean;
}

export class FileFlatNode {
  expandable: boolean;
  editable: boolean;
  item: FileAws;
  level: number;
  loadingBar: boolean;
  loaded: boolean;
}

@Injectable()
export class FileTreeDatabase {
  dataChange = new BehaviorSubject<FileNode[]>([]);

  get data(): FileNode[] {
    return this.dataChange.value;
  }

  constructor() {
  }

  initialize(newData) {
    const data = this.arrangeIntoTree(newData);
    this.dataChange.next(data);
  }

  reInit(data) {
    this.dataChange.next(data);
  }

  updateTree(newData, parentNode: FileNode) {
    const node = this.arrangeIntoTree(newData);
    const existingNode = this.findCurrentNode(node, parentNode);
    if (existingNode) {
      parentNode.children = existingNode.children;
    }
    this.data.sort((d1 , d2) => this.sortFn(d1, d2));
    this.dataChange.next(this.data);
  }

  findCurrentNode(nodes: FileNode[], currentNode: FileNode): FileNode {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].item.name === currentNode.item.name) {
        return nodes[i];
      }
      if (nodes[i].children.length > 0) {
        return this.findCurrentNode(nodes[i].children, currentNode);
      }
    }
  }

  arrangeIntoTree(paths): FileNode[] {
    const tree = [];

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i].paths;
      let currentLevel = tree;
      for (let j = 0; j < path.length; j++) {
        const part = path[j];

        const existingPath = this.findWhere(currentLevel, 'name', part);

        if (existingPath) {
          currentLevel = existingPath.children;
        } else {
          const fileNode: FileNode = {
            children: [],
            item: {
              name: part,
              path: paths[i].path,
              fileSize: paths[i].fileSize,
              lastModifiedDate: paths[i].lastModifiedDate,
              lastModifiedBy: paths[i].lastModifiedBy,
              type: paths[i].fileSize === 0 && paths[i].path.endsWith('/') ? 'folder' : 'file',
              isRoot: paths[i].isRoot,
              isNew: paths[i].isNew
            }
          };

          currentLevel.push(fileNode);
          currentLevel = fileNode.children;
        }
      }
    }
    return tree;
  }

  findWhere(array, key, value) {
    let t = 0;
    while (t < array.length && array[t].item[key] !== value) {
      t++;
    }

    if (t < array.length) {
      return array[t];
    } else {
      return false;
    }
  }

  sortFn (first, second) {
    if (first.item.type === 'folder') {
      if (first.item.name < second.item.name) {
        return -1;
      } else if (first.item.name > second.item.name) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  insertItem(parent: FileNode, item: FileNode) {
    if (parent?.children) {
      parent.children.push(item);
      parent.children.sort((c1 , c2) => this.sortFn(c1, c2));
      this.dataChange.next(this.data);
    } else {
      this.data.push(item);
      this.data.sort((d1 , d2) => this.sortFn(d1, d2));
      this.dataChange.next(this.data);
    }
  }

  sortData() {
    this.data.sort((d1 , d2) => this.sortFn(d1, d2));
    this.dataChange.next(this.data);
  }

  updateItem(node: FileNode, folderName: string) {
    node.item.name = folderName;
    node.item.path = node.item.path + folderName + '/';
    this.dataChange.next(this.data);
  }

  deleteItem(parent: FileNode, child: FileNode) {
    if (parent) {
      const index = parent.children.findIndex(p => p === child);
      if (index !== -1) {
        parent.children.splice(index, 1);
      }
    } else {
      const index = this.data.findIndex(p => p === child);
      if (index !== -1) {
        this.data.splice(index, 1);
      }
    }
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-generic-file-structure',
  templateUrl: './generic-file-structure.component.html',
  styleUrls: ['./generic-file-structure.component.scss']
})
export class GenericFileStructureComponent implements OnInit {

  @Input() config: AttachmentConfig;
  @Input() fileStructure: FileNode[];
  @Output() uploadEmitter: EventEmitter<string> = new EventEmitter();
  @Input() uploadPath: string;
  @Output() fileStructureState: EventEmitter<FileNode[]> = new EventEmitter();
  @Output() closeEmitter: EventEmitter<string> = new EventEmitter();

  // error list
  messageList = [];
  showNotification = false;

  searchValue: string = null;
  loadingMessage: string;

  isLoadingTree = false;

  renameForm: FormGroup;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<FileFlatNode, FileNode>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<FileNode, FileFlatNode>();
  /** The new item's name */
  newItemName = '';
  treeControl: FlatTreeControl<FileFlatNode>;
  treeFlattener: MatTreeFlattener<FileNode, FileFlatNode>;
  dataSource: MatTreeFlatDataSource<FileNode, FileFlatNode>;

  getLevel = (node: FileFlatNode) => node.level;

  isExpandable = (node: FileFlatNode) => node.expandable;

  getChildren = (node: FileNode): FileNode[] => node.children;

  hasChild = (_: number, _nodeData: FileFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: FileFlatNode) => _nodeData.item.name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: FileNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new FileFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = node.item?.type === 'folder';
    flatNode.editable = false;
    flatNode.loadingBar = flatNode.loadingBar != null ? flatNode.loadingBar : false;
    flatNode.loaded = flatNode.loaded != null ? flatNode.loaded : false;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  // admin documents bulk delete
  isDocumentBulkDeleteEnabled = false;
  selection = new SelectionModel<FileFlatNode>(true, []);
  MAX_DOCUMENTS_BULK_DELETE = 10;

  constructor(private attachmentService: AttachmentService,
              private msgBanner: MsgBannerService,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private roleGuardService: RoleGuardService,
              private _database: FileTreeDatabase) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<FileFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(newData => {
      this.dataSource.data = newData;
    });

    this.renameForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('^.*\\.pdf$')], []]
    });
  }

  ngOnInit(): void {
    this.loadFileSystem();
  }

  loadFileSystem() {
    this.loadingMessage = 'Loading File System...';
    if (!this.fileStructure || (this.fileStructure && this.fileStructure.length === 0)) {
      this.getFileSystem();
    } else {
      this._database.reInit(this.fileStructure);
      this.expandNodesWithChildes();
      this.reloadUploadedPath();
    }
  }

  expandNodesWithChildes() {
    for (const flatNode of this.treeControl.dataNodes) {
      const node = this.flatNodeMap.get(flatNode);
      if (node.children && node.children.length > 0) {
        this.treeControl.expand(flatNode);
      }
    }
  }

  reloadUploadedPath() {
    const index = this.treeControl.dataNodes.findIndex(node => node.item.path === this.uploadPath);
    if (index !== -1) {
      const node: FileFlatNode = this.treeControl.dataNodes[index];
      const nestedNode: FileNode = this.flatNodeMap.get(node);
      this.loadContent(node, nestedNode);
    }
  }

  closeDialog() {
    this.closeEmitter.emit(null);
  }

  get attachmentView() {
    return AttachmentView;
  }

  get isBulkDelete(): boolean {
    return this.config.view === this.attachmentView.ADMIN_DOCUMENTS
      || this.config.view === this.attachmentView.ADMIN;
  }

  applyFilter() {
    if (this.isDocumentBulkDeleteEnabled) {
      this.isDocumentBulkDeleteEnabled = false;
      this.selection = new SelectionModel<FileFlatNode>(true, []);
    }

    if (!this.searchValue || this.searchValue.length === 0) {
      this.getFileSystem();
    } else {
      this.search();
    }
  }

  search() {
    this.isLoadingTree = true;
    this.loadingMessage = 'Loading File System...';
    this.showNotification = false;
    this.attachmentService.search(this.searchValue, this.config.view.valueOf(), this.config.isArchived).subscribe(
      response => {
        const paths = this.getFileSystemAsTree(response);
        this._database.initialize(paths);
        this.expandNodesWithChildes();
        this.isLoadingTree = false;
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
        this.isLoadingTree = false;
      }
    );
  }

  getFileSystem() {
    this.isLoadingTree = true;
    this.loadingMessage = 'Loading File System...';
    this.showNotification = false;

    this.attachmentService.getFileSystem(null, this.config.view.valueOf(), this.config.isArchived).subscribe(
      response => {
        this.isLoadingTree = false;
        const paths = this.getFileSystemAsTree(response);
        this._database.initialize(paths);
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
        this.isLoadingTree = false;
      }
    );
  }

  private getFileSystemAsTree(response: AwsFile[]) {
    const paths = [];

    response.forEach(file => {
      let pathArray;
      const fileName = file.name;
      if (fileName.endsWith('/')) {
        pathArray = {
          paths: fileName.slice(0, fileName.length - 1).split('/'),
          path: fileName,
          fileSize: file.size,
          lastModifiedBy: file.lastModifiedBy,
          lastModifiedDate: file.lastModifiedDate,
          isRoot: file.isRoot,
          isNew: file.isNew
        };
      } else {
        pathArray = {
          paths: fileName.split('/'),
          path: fileName,
          fileSize: file.size,
          lastModifiedBy: file.lastModifiedBy,
          lastModifiedDate: file.lastModifiedDate,
          isRoot: file.isRoot,
          isNew: file.isNew
        };
      }

      paths.push(pathArray);
    });

    return paths;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: FileFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    const fileAws: FileAws = {
      name: '',
      path: node ? parentNode.item.path : '',
      fileSize: 0,
      lastModifiedDate: new Date(),
      lastModifiedBy: '',
      type: 'folder',
      isRoot: false,
      isNew: false
    };
    this._database.insertItem(parentNode!, {
      children: [],
      item: fileAws
    });
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: FileFlatNode, folderName: string) {
    this.showNotification = false;
    const parentNode = this.getParentNode(node);
    const nestedParentNode = this.flatNodeMap.get(parentNode);
    if (parentNode) {
      for (const item of nestedParentNode.children) {
        if (folderName === item.item.name) {
          this.msgBanner.addMsgWarning(this.messageList, 'Folder already exists!');
          this.showNotification = true;
          return;
        }
      }
    } else {
      for (const item of this.dataSource.data) {
        if (folderName === item.item.name) {
          this.msgBanner.addMsgWarning(this.messageList, 'Folder already exists!');
          this.showNotification = true;
          return;
        }
      }
    }

    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode!, folderName);

    this.isLoadingTree = true;
    this.attachmentService.addObject(nestedNode, this.config.view.valueOf()).subscribe(
      response => {
        if (parentNode) {
          this.loadContent(parentNode, nestedParentNode);
        } else {
          this._database.sortData();
          this.isLoadingTree = false;
        }
      }, error => {
        if (error.status === 400) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  getParentNode(node: FileFlatNode): FileFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  loadContent(node: FileFlatNode, nestedNode: FileNode) {
    node.loadingBar = true;
    this.attachmentService.getFileSystem(nestedNode.item.path, this.config.view.valueOf(), this.config.isArchived).subscribe(
      response => {
        const paths = this.getFileSystemAsTree(response);
        this._database.updateTree(paths, nestedNode);

        node.loadingBar = false;
        node.loaded = true;
        this.isLoadingTree = false;
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
        node.loadingBar = false;
        this.isLoadingTree = false;
      }
    );
  }

  downloadFile(node: FileFlatNode) {
    this.showNotification = false;

    // will display a message on dialog, not on admin because already have a spinner
    if (this.config.view.valueOf() !== this.attachmentView.ADMIN) {
      this.isLoadingTree = true;
    }
    this.loadingMessage = 'Downloading File...';
    this.attachmentService.downloadFile(node.item.path, this.config.view.valueOf()).subscribe(
      response => {
        this.attachmentService.download(response);
        this.isLoadingTree = false;
      }, error => {
        if (error.status === 400) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
        this.isLoadingTree = false;
      }
    );
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  cancelRename(node: FileFlatNode) {
    node.editable = !node.editable;
    this.renameForm.controls.name.setValue('');
  }

  rename(node: FileFlatNode) {
    this.showNotification = false;
    if (this.renameForm.invalid) {
      return;
    }
    const nestedNode = this.flatNodeMap.get(node);
    const newName = this.renameForm.controls.name.value;
    const oldName = node.item.name;

    if (newName === oldName) {
      node.editable = !node.editable;
      return;
    }

    this.isLoadingTree = true;
    this.attachmentService.copyObjects(nestedNode.item.path, newName, oldName, this.config.view.valueOf()).subscribe(
      response => {
        const parentNode = this.getParentNode(node);
        const nestedParentNode = this.flatNodeMap.get(parentNode);
        if (parentNode) {
          this.loadContent(parentNode, nestedParentNode);
        } else {
          nestedNode.item.path = nestedNode.item.path.replace(oldName, newName);
          nestedNode.item.name = nestedNode.item.name.replace(oldName, newName);
          this.loadContent(node, nestedNode);
        }
      }, error => {
        if (error.status === 400) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
        this.isLoadingTree = false;
      }
    );
  }

  editFileName(node: FileFlatNode) {
    for (const flatNode of this.treeControl.dataNodes) {
      flatNode.editable = false;
    }

    this.renameForm.controls.name.setValue(node.item.name);
    node.editable = !node.editable;
  }

  deleteObjects(node: FileFlatNode) {
    let message = 'This folder may contains other files. Do you want to delete this folder?';
    if (node.item.type === 'file') {
      message = 'Do you want to delete this file: ' + node.item.name + '?';
    }
    this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(message, true, false, true),
    }).afterClosed().subscribe((result) => {
      if (result) {
        const nestedNode = this.flatNodeMap.get(node);

        this.isLoadingTree = true;
        this.attachmentService.deleteObject(nestedNode.item.path, this.config.view.valueOf()).subscribe(
          response => {
            if (this.searchValue && this.searchValue.length > 0) {
              this.search();
            } else {
              const parentNode = this.getParentNode(node);
              const nestedParentNode = this.flatNodeMap.get(parentNode);
              if (parentNode) {
                this.loadContent(parentNode, nestedParentNode);
              } else {
                this.loadContent(node, nestedNode);
              }
            }
          }, error => {
            if (error.status === 400) {
              this.msgBanner.addMsgError(this.messageList, error.error.message);
            } else {
              this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            }
            this.showNotification = true;
            this.isLoadingTree = false;
          }
        );
      }
    });
  }

  deleteNode(node: FileFlatNode) {
    const childNode = this.flatNodeMap.get(node);
    const parentFlatNode = this.getParentNode(node);
    const parentNode = this.flatNodeMap.get(parentFlatNode);
    this._database.deleteItem(parentNode!, childNode!);
  }

  hasChildren(node: FileFlatNode) {
    const nestedNode = this.flatNodeMap.get(node);
    if (nestedNode.children.length > 0) {
      return true;
    }
    return false;
  }

  getFolderContent(node: FileFlatNode) {
    const nestedNode = this.flatNodeMap.get(node);

    this.showNotification = false;
    if (nestedNode.children.length > 0) {
      return;
    }

    this.loadContent(node, nestedNode);
  }

  uploadFile(node: FileFlatNode) {
    this.uploadEmitter.emit(node.item.path);
    this.fileStructureState.emit(this._database.data);
  }

  linkFile(node: FileFlatNode) {
    let path = node.item.path;
    if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS)) {
      path = 'TERRITORY/' + node.item.path;
    }
    this.closeEmitter.emit(path);
  }
  
  enableBulkDelete() {
    this.isDocumentBulkDeleteEnabled = true;
  }

  disableBulkDelete() {
    this.isDocumentBulkDeleteEnabled = false;
    this.selection = new SelectionModel<FileFlatNode>(true, []);
  }

  getBulkDeleteDocumentsMessage(): string {
    let files = this.selection.selected.map(node => {
      let path: string[] = node.item.path.split('/');
      return "<li>" + path[path.length - 1] + "</li>";
    }).join(" ");

    return "<span>" + 'Do you want to delete these files? ' + 
              `<ul class="text-align-start">` + files + "</ul>" + 
            "</span>";
  }

  onBulkDelete() {
    if (this.selection.selected.length == 0) {
      this.displayConfirmationDialog("No document has been selected!");
      return;
    }

    let message = this.getBulkDeleteDocumentsMessage();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: 'auth',
        data: new Dialog(message, true, false, true),
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoadingTree = true;
        let paths = this.selection.selected.map(node => node.item.path);
        this.disableBulkDelete();

        this.attachmentService.bulkDeleteObjects(paths, this.config.view.valueOf()).subscribe(
          response => {
            this.displayConfirmationDialog("Files have been successfully deleted!");
            this.loadFileSystem();
            
          }, error => {
            if (error.status === 400) {
              this.displayConfirmationDialog(error.error.message);
            } else {
              this.displayConfirmationDialog('An error has occurred. Please contact your administrator!');
            }
            this.isLoadingTree = false;
            this.loadFileSystem();
          }
        );
      }
    });
  }

  isSelectionSizeExceeded(node: FileFlatNode) {
    let isSelected = this.selection.isSelected(node);
    let selectionSizeExceeded = this.selection.selected.length >= this.MAX_DOCUMENTS_BULK_DELETE;
    return !isSelected && selectionSizeExceeded;
  }

  displayConfirmationDialog(message: string) {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog(message, false, false),
      disableClose: true,
    });
  }
}
