import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import {ChooseFile} from '../../shared/models/activity/choose-file.model';
import {PersonResponsibleFile} from '../../shared/models/activity/import-person-responsible.model';

@Injectable({
  providedIn: 'root'
})
export class ReaderService {

  constructor() { }

  readFromFile(fileName: File): Promise<ChooseFile[]> {
    const stores: ChooseFile[] = [];
    const fileReader = new FileReader();
    fileReader.readAsText(fileName);

    return new Promise((resolve, reject) => {
      fileReader.onload = (e) => {
        const lines = (fileReader.result as string).split('\n');
        // putting all the chain-stores in the array ukAccountsToCheck to be sent to the backend
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].trim().includes(',') && lines[i].trim().length > 11) { // is shipper
            stores.push({
              fullAccount: lines[i].trim().substring(0, 10),
              shipper: Number(lines[i].trim().substring(11))
            });
          } else {
            stores.push({
              fullAccount: lines[i].trim().substring(0, 10),
            });
          }
        }
        resolve(stores);
      };
    });
  }

  readFromXlsxFile(event): Promise<ChooseFile[]> {
    const stores: ChooseFile[] = [];
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    return new Promise((resolve, reject) => {
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        const data: Array<any> = XLSX.utils.sheet_to_json(ws, {header: 1});
        for (let i = 0; i < data.length; i++) {
          if (data[i] && data[i].length > 0) {
            if (Array.isArray(data[i])) { // is shipper
              stores.push({
                fullAccount: data[i][0].toString(),
                shipper: data[i][1]
              });
            } else {
              stores.push({
                fullAccount: data[i][0].toString(),
              });
            }
          }
        }
        resolve(stores);
      };
    });
  }

  readUsersFromXlsxFile(event): Promise<PersonResponsibleFile[]> {
    const users: PersonResponsibleFile[] = [];
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    return new Promise((resolve, reject) => {
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        const data: Array<any> = XLSX.utils.sheet_to_json(ws, {header: 1});
        for (let i = 1; i < data.length; i++) {
          users.push({
            usrId: data[i][0].toString(),
          });
        }
        resolve(users);
      };
    });
  }
}
