<mat-card
  fxLayout="column"
  [ngClass]="{
      'mat-elevation-z2': config.data !== null,
      'mat-elevation-z0': config.data === null
  }"
  [ngStyle]="{ 'padding': config.data === null ? '0' : '' }"
>
  <app-msg-banner *ngIf="showNotification" [msgList]="messageList" (msgListChange)="messageList = $event"></app-msg-banner>

  <form
    [formGroup]="responseSetForm"
    fxLayout="column"
    fxLayoutGap="2%"
  >
    <mat-form-field
      appearance="standard"
    >
      <mat-label>Title</mat-label>
      <input
        matInput
        formControlName="title"
        type="text"
        required
        [maxLength]="getLength().titleOfSet"
      />
      <mat-hint>{{ responseSetForm.controls.title.value ? responseSetForm.controls.title.value.length : 0 }}/{{ getLength().titleOfSet }}</mat-hint>
      <mat-error *ngIf="responseSetForm.controls.title.errors?.required && responseSetForm.controls.title.dirty">
        Title is Mandatory.
      </mat-error>
      <mat-error *ngIf="responseSetForm.controls.title.errors?.pattern && responseSetForm.controls.title.dirty">
        * Input not allowed
      </mat-error>
      <mat-error *ngIf="responseSetForm.controls.title.errors?.maxLength && responseSetForm.controls.title.touched">
        {{responseSetForm.controls.title.errors?.maxLength}}
      </mat-error>
    </mat-form-field>

    <app-load-responses #child [config]="config"></app-load-responses>
  </form>

  <div
    *ngIf="config.data !== null"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="2%"
    fxLayoutGap.lt-md="0"
    fxLayoutAlign="center"
  >
    <button
      fxFlex.lt-md="100%"
      fxFlex="125px"
      mat-raised-button
      (click)="change(child, 'update')"
      class="btn btn-actions update"
      type="button"
      [disabled]="this.responseSetForm.invalid"
    >Update Set
    </button>
    <button
      fxFlex.lt-md="100%"
      fxFlex="125px"
      mat-raised-button
      (click)="cancel()"
      class="btn btn-actions delete" type="button"
    >Cancel
    </button>
  </div>

  <div
    *ngIf="config.data === null"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <button
      fxFlex.lt-md="100%"
      fxFlex="125px"
      mat-raised-button
      (click)="change(child, 'create')"
      class="btn btn-actions update"
      type="button"
      [disabled]="this.responseSetForm.invalid"
    >
      Create Set
    </button>
  </div>
</mat-card>
