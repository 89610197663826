import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StringResponse } from '../../shared/models/string-response';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {

  constructor(private http: HttpClient) {}

  public checkAppVersionChange() {
    this.http.get<StringResponse>(`${environment.apiResourceUri}app-version/ui?epoch=${new Date().getTime()}`).subscribe(
      (response: StringResponse) => {
        const currentVersion = response.response;
        if (currentVersion == null) { return; }

        const cachedVersion = localStorage.getItem('appVersion');
        if (currentVersion === cachedVersion) { return; }

        localStorage.setItem('appVersion', currentVersion.toString());
        document.location.reload();
      }
    );
  }
}
