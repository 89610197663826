import { Component, OnInit } from '@angular/core';
import {FileNode} from '../../../../shared/components/generic-file-structure/generic-file-structure.component';
import {AttachmentView} from '../../../../shared/models/attachment.config';

@Component({
  selector: 'app-general-documents',
  templateUrl: './general-documents.component.html',
  styleUrls: ['./general-documents.component.scss']
})
export class GeneralDocumentsComponent implements OnInit {

  uploadPath: string;
  nodes: FileNode[] = [];

  showUpload = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  buildConfig() {
    return {
      view: AttachmentView.VIEW_GENERAL_DOCUMENTS,
      isArchived: false
    };
  }

  uploadView(path: string) {
    this.uploadPath = path;
    this.showUpload = true;
  }

  saveFileStructureState(nodes: FileNode[]) {
    this.nodes = nodes;
  }

  fileView(event: boolean) {
    this.showUpload = event;
  }
}
