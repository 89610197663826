import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from '../../../core/services/admin.service';
import { GraphService } from '../../../core/graph/graph.service';
import { ChainGroupModel } from '../../models/chain';

@Component({
  selector: 'app-load-store-chain',
  templateUrl: './load-store-chain.component.html',
  styleUrls: ['./load-store-chain.component.scss']
})
export class LoadStoreChainComponent implements OnInit {

	chainGroupForm: FormGroup;
	chainGroups: ChainGroupModel[] = [];

	constructor(public dialogRef: MatDialogRef<LoadStoreChainComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private fb: FormBuilder,
				private adminService: AdminService,
				private graphService: GraphService) { 
		this.chainGroupForm = this.fb.group({
			group: [null, [Validators.required], []]
		});
	}

	ngOnInit(): void {
		this.getChainGroups();
	}

	getChainGroups() {
		// by email 
		const email = this.graphService.user.displayName;
		this.adminService.getChainGroupsByEmail(email).subscribe(
			(result : ChainGroupModel[]) => {
				this.chainGroups = result;
			}, 
			(error: any) => {
				console.log(error);
			}
		);
	}

	onOk() {
		if (this.chainGroupForm.invalid)
			return

		this.dialogRef.close(this.chainGroupForm.controls.group.value);
	}

	onCancel() {
		this.dialogRef.close(null);
	}
}
