import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Season} from '../../../shared/models/season.model';
import {Location} from '../../../shared/models/location.model';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-static-data-dialog',
  templateUrl: './static-data-dialog.component.html',
  styleUrls: ['./static-data-dialog.component.scss']
})
export class StaticDataDialogComponent implements OnInit {

  form: FormGroup;
  action: string;
  application: string;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<StaticDataDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.form = this.fb.group({
        id: [data.id, []],
        en: [data.en, [Validators.required, Validators.pattern(environment.emojiRegEx)]],
        active: [data.active, Validators.required],
      });
      this.action = 'Update';
    } else {
      this.form = this.fb.group({
        id: [null, []],
        en: ['', [Validators.required, Validators.pattern(environment.emojiRegEx)]],
        active: [false, Validators.required],
      });
      this.action = 'Add';
    }
  }

  ngOnInit(): void {
    this.application = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
  }

  onSubmit() {
    let data: Location | Season = null;
    if (this.form.value) {
      data = new Location(this.form.value);
    }
    this.dialogRef.close(data);
  }

  onCancel() {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
