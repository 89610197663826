import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../models/dialog';
import {environment} from '../../../../environments/environment';
import {MsgBannerService} from '../msg-banner/msg-banner.service';
import {CustomFieldsValidators} from '../custom-validators/CustomFieldsValidators';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {
  // error list
  messageList = [];
  showNotification = false;

  form: FormGroup;

  commReqValue: boolean;
  imgReqValue: boolean;

  constructor(public dialogRef: MatDialogRef<EditDialogComponent>,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private msgBanner: MsgBannerService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    const data = JSON.parse(JSON.stringify(this.data));
    this.form = this.fb.group({
      response: [data.desc, [Validators.required, Validators.pattern(environment.emojiRegEx)]],
      complianceFlag: [data.complInd, Validators.required],
      commentReq: [data.cmntReq],
      comment: [data.cmntPpt, [CustomFieldsValidators.maxComment, Validators.pattern(environment.emojiRegEx)]],
      imageReq: [data.imgReq]
    });
    this.commReqValue = this.form.controls.commentReq.value;
    this.imgReqValue = this.form.controls.imageReq.value;
    this.setFormValidaty();
  }

  setFormValidaty() {
    if (this.data.isSelected === true) {
      this.form.controls.complianceFlag.disable();
      this.form.controls.complianceFlag.updateValueAndValidity();
      this.form.controls.imageReq.disable();
      this.form.controls.imageReq.updateValueAndValidity();
      this.form.controls.commentReq.disable();
      this.form.controls.commentReq.updateValueAndValidity();
      this.form.controls.comment.disable();
      this.form.controls.comment.updateValueAndValidity();
    }
  }

  save() {
    if (this.form.invalid) {
      this.msgBanner.addMsgError(this.messageList, 'Please complete all required fields!');
      this.showNotification = true;
      return;
    }
    if (!this.form.controls.commentReq.value) {
      this.form.controls.comment.setValue(null);
    }

    this.dialogRef.close({
      complInd: this.form.controls.complianceFlag.value,
      desc: this.form.controls.response.value,
      cmntReq: this.commReqValue,
      imgReq: this.imgReqValue,
      cmntPpt: this.form.controls.comment.value
    });
  }

  close() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog('Do you confirm canceling your modifications?', true, false),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.dialogRef.close(false);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  getLength() {
    return environment.lengthValidators;
  }
}
