import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalCustomNavigationClient,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular';
import {Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {Location} from '@angular/common';
import {filter, takeUntil} from 'rxjs/operators';
import {AuthenticationResult, EventMessage, EventType, InteractionStatus, Logger, LogLevel} from '@azure/msal-browser';
import {User} from '../shared/models/user';
import {LoadingService} from '../core/interceptors/loading-service';
import {GraphService} from '../core/graph/graph.service';
import {ConfirmationDialogComponent} from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import { AppVersionService } from '../core/services/app-version.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
  implements OnInit, OnDestroy {
  title = 'UKG Merchandising Portal';
  isIframe: boolean;
  private loginSubscription: Subscription;
  private tokenSubscription: Subscription;
  user: User = {
    displayName: '',
    groupIDs: [],
    currentPath: '/',
  };
  isLoading: Subject<boolean> = this.loadingService.isLoading;

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private loadingService: LoadingService,
    private msalBroadcastService: MsalBroadcastService,
    private graphService: GraphService,
    private dialog: MatDialog,
    private router: Router,
    private location: Location,
    private titleService: Title,
    private appVersionService: AppVersionService
  ) {
    // Custom navigation set for client-side navigation.
    // See performance doc for details:
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/v2-docs/performance.md
    const customNavigationClient = new MsalCustomNavigationClient(authService, this.router, this.location);
    this.authService.instance.setNavigationClient(customNavigationClient);

    this.isIframe = window !== window.parent && !window.opener;

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loadingService.show();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loadingService.hide();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(environment.title);

    const acct = this.authService.instance.getAllAccounts();
    if (!acct || acct?.length === 0) {
      this.router.navigate(['/landing']);
      return;
    }

    this.appVersionService.checkAppVersionChange();

    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.loginSubscription = this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        console.log('login success ' + JSON.stringify(payload));

        this.authService.instance.setActiveAccount(payload.account);
        this.graphService.user.displayName = payload.idTokenClaims['preferred_username'];

        if (payload.idTokenClaims['groups']) {
          this.graphService.user.groupIDs = <string[]>(
            (<unknown>payload.idTokenClaims['groups'])
          );
        }
        this.user = this.graphService.user;
        this.setLoginDisplay();
      });

    function loggerCallback(logLevel: LogLevel, message: string) {
      console.log('client logging' + message);
    }

    this.authService.setLogger(
      new Logger(
        {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: true
        }
      )
    );

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        if (
          !payload.toString().includes(
            'User login is required. For silent calls, request must contain either sid or login_hint'
          )
        ) {
          console.log('Acquire Token Failure callback');
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            maxWidth: '400px',
            disableClose: true,
            data: {
              title: 'Confirm Action',
              message:
                'An authentication exception occurred. You need to log out of your American Greetings account and then log back in.',
              noButtonShow: true,
              yesButtonShow: true,
            },
          });
          dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult) {
              this.logout();
              return;
            }
          });
        }
      });

    this.tokenSubscription = this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          console.log('Acquire Token Success callback');

          if (this.user.displayName.length === 0) {
            this.graphService.user.displayName = payload.idTokenClaims['preferred_username'];
            if (payload.idTokenClaims['groups']) {
              this.graphService.user.groupIDs = <string[]>(
                (<unknown>payload.idTokenClaims['groups'])
              );
            }
            this.user = this.graphService.user;
            console.log(this.user);
          }
        }
      );

    if (this.graphService.user.displayName.length === 0) {
      const accounts = this.authService.instance.getAllAccounts();

      if (accounts.length > 0) {
        this.graphService.user.displayName = accounts[0].idTokenClaims['preferred_username'];
        if (accounts[0].idTokenClaims['groups']) {
          this.graphService.user.groupIDs = <string[]>(
            (<unknown>accounts[0].idTokenClaims['groups'])
          );
        }
        this.user = this.graphService.user;
        console.log(this.user);
      }
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      this.graphService.sendClickEvent(this.user);
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: 'landing'
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy() {
    // disconnect from broadcast service on component destroy
    this._destroying$.next(null);
    this._destroying$.complete();
    if (this.loginSubscription) {
      console.log('Unsubscribe Login Subscription');
      this.loginSubscription.unsubscribe();
    }
    if (this.tokenSubscription) {
      console.log('Unsubscribe Token Subscription');
      this.tokenSubscription.unsubscribe();
    }
  }
}
