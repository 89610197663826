import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Room} from '../../../shared/models/room.model';
import {MatSort, Sort} from '@angular/material/sort';
import {Chain} from '../../../shared/models/chain';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {AdminService} from '../../../core/services/admin.service';
import {IPagedResponse} from '../../../shared/models/IPagedResponse';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../../shared/models/dialog';
import {RoomDialogComponent} from '../room-dialog/room-dialog.component';
import {StringResponse} from '../../../shared/models/string-response';
import {MsgBannerService} from '../../../shared/components/msg-banner/msg-banner.service';

@Component({
  selector: 'app-room-management',
  templateUrl: './room-management.component.html',
  styleUrls: ['./room-management.component.scss']
})
export class RoomManagementComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = [
    'roomName',
    'path',
    'chains',
    'actions',
  ];

  pageEvent: PageEvent;
  pageSize = 50;
  totalRows = 0;

  // error list
  messageList = [];
  showNotification = false;

  isSuccess = false;
  searchValue: string = null;

  rooms: Room[] = [];
  dataSource = new MatTableDataSource<Room>();

  chains: Chain[] = [];

  constructor(
    private dialog: MatDialog,
    private msgBanner: MsgBannerService,
    private service: AdminService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSource.filterPredicate = (data: Room, filter: string) =>
      (data.roomName +
        this.getChains(data.chains)
      )
        .trim()
        .toLowerCase()
        .indexOf(filter) !== -1;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'roomName': return item.roomName;
        case 'chains':
          return this.getChains(item.chains);
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.populateDefaultValues().then(result => {
      if (result) {
        this.getRooms();
      } else {
        this.showErrorMessage();
      }
    });
  }

  ngOnDestroy(): void {

  }

  getChains(chains: string[]): string {
    let chainString = '';
    for (const chain of chains) {
      chainString += chain;
    }
    return chainString;
  }

  applyFilter(clearKeyUp?: boolean) {
    if (clearKeyUp && this.searchValue != null && this.searchValue.length === 0) {
      this.paginator.pageIndex = 0;
      this.getRooms();
    } else if (clearKeyUp === undefined) {
      this.paginator.pageIndex = 0;
      this.getRooms();
    }
  }

  openAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    this.dialog.open(RoomDialogComponent, dialogConfig).afterClosed().subscribe(
      data => {
        if (data) {
          this.service.createRoom(data).subscribe(
            (response: StringResponse) => {
              this.showConfirmationMessageAndRetrieveData(response.response);
            },
            (error) => {
              if (error.status === 400) {
                this.msgBanner.addMsgError(this.messageList, error.error.message);
              } else {
                this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
              }
              this.showNotification = true;
            }
          );
        }
      }, error => {
        this.showErrorMessage();
      });
  }

  sortTable(sort: Sort) {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data.sort((a, b) => {
        return compare(a.roomName, b.roomName, true);
      });
      return;
    }
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'roomName': return compare(a.roomName, b.roomName, isAsc);
        case 'chains': return compare(this.chainString(a.chains), this.chainString(b.chains), isAsc);
        default: return 0;
      }
    });

    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  chainString(chains: string[]): string {
    if (chains) {
      // return chains.map(c => this.getChainNameById(c)).join(', ');
      return chains.join(', ');
    }
    return '';
  }

  getChainNameById(chainId: string) {
    return this.chains.find((chain: Chain) => chain.chainId === chainId)?.chainName;
  }

  populateDefaultValues() {
    return new Promise((release, reject) => {
      this.service.getChains().subscribe(
        (response: any) => {
          this.chains = response;
          release(true);
        }, (error) => {
          this.showErrorMessage();
          release(false);
        });
    });
  }

  getRooms() {
    const pageIndex = this.paginator.pageIndex ? this.paginator.pageIndex : 0;
    const pageSize = this.paginator.pageSize ? this.paginator.pageSize : 5;
    this.service.getRooms(pageIndex, pageSize, this.searchValue).subscribe(
      (response: IPagedResponse<Room>) => {
        this.totalRows = response.totalElements;
        this.dataSource = new MatTableDataSource<Room>(response.content);
      },
      (error: any) => {
        this.dataSource = new MatTableDataSource<Room>();
        this.totalRows = 0;
        this.showErrorMessage();
      }
    );
  }

  showErrorMessage() {
    this.showNotification = true;
    this.isSuccess = false;
  }

  showConfirmationMessageAndRetrieveData(message: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(message, false, false, false),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      response => {
        this.getRooms();
        if (response) {
          return;
        }
      }
    );
  }

  openEdit(room: Room) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = room;
    const index = this.dataSource.data.findIndex(data => data.roomId === room.roomId);
    this.dialog.open(RoomDialogComponent, dialogConfig).afterClosed().subscribe(
      data => {
        if (data) {
          this.service.editRoom(data).subscribe(
            (response: StringResponse) => {
              this.showConfirmationMessageAndRetrieveData(response.response);
            },
            (error) => {
              if (error.status === 400) {
                this.msgBanner.addMsgError(this.messageList, error.error.message);
              } else {
                this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
              }
              this.showNotification = true;
            }
          );
        }
      }, error => {
        this.showErrorMessage();
      });
  }

  onDelete(room: Room) {
    this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog('Do you want to delete this room?', true, false, true),
      disableClose: true,
    }).afterClosed().subscribe(
      response => {
        if (response) {
          this.service.deleteRoom(room).subscribe(
            (resp: StringResponse) => {
              this.showConfirmationMessageAndRetrieveData(resp.response);
            },
            (error) => {
              this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
              this.showNotification = true;
            }
          );
        }
      }
    );
  }

  public getServerData(event?: PageEvent) {
    this.getRooms();
    return event;
  }
}
