export const NOTIF_CONSTS = {
  NOTIF_TYPE: {
    GENERAL: 'General',
    CHAIN: 'Chain',
    TERR: 'Territory'
  },
  PAGE: {
    MANAGE: 'manage',
    VIEW: 'view'
  },
  VIEW_PAGE_LIST: ['General-view', 'Chain-view', 'Territory-view'],
  MANAGE_PAGE_LIST: ['General-manage', 'Chain-manage', 'Territory-manage'],
  PUBLISH_HEADER: {
    displayedColumns: [
      'select',
      'title',
      'author',
      'posted',
      'from',
      'until',
      'notType',
      'attach'
    ]
  },
  ATTACHMENT_FOLDERS: {
    GENERAL: 'GENERAL/',
    CHAIN: 'CHAIN/',
    TERRITORY: 'TERRITORY/'
  }
};
