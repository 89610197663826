import {Component, OnInit} from '@angular/core';
import {FileNode} from '../../../shared/components/generic-file-structure/generic-file-structure.component';
import {AttachmentView} from '../../../shared/models/attachment.config';

@Component({
  selector: 'app-documents-structure',
  templateUrl: './documents-structure.component.html',
  styleUrls: ['./documents-structure.component.scss']
})
export class DocumentsStructureComponent implements OnInit {

  uploadPath: string;
  nodes: FileNode[] = [];

  showUpload = false;

  constructor() {
  }

  ngOnInit(): void {
    this.initViewForSafari();
  }

  initViewForSafari() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  buildConfig() {
    return {
      view: AttachmentView.ADMIN_DOCUMENTS,
      isArchived: false
    };
  }

  uploadView(path: string) {
    this.uploadPath = path;
    this.showUpload = true;
  }

  saveFileStructureState(nodes: FileNode[]) {
    this.nodes = nodes;
  }

  fileView(event: boolean) {
    this.showUpload = event;
  }

}
