import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {GraphService} from '../graph/graph.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {MsalService} from '@azure/msal-angular';
import {ROUTE} from '../../shared/constants/routes-constants';
import {AG_ROLE} from '../../shared/constants/roles';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  private authorizePromise: Promise<boolean>;
  userRoles: string[] = [];
  existingRoles = [
    AG_ROLE.UK_MERCH_ADMIN.valueOf(),
    AG_ROLE.UK_CUST_OPS.valueOf(),
    AG_ROLE.UK_TERR_MANAGER.valueOf(),
    AG_ROLE.UK_ACCT_MANAGER.valueOf(),
    AG_ROLE.JS_IT.valueOf(),
    AG_ROLE.JS_MERCH_ADMIN.valueOf(),
    AG_ROLE.JS_MERCHANDISER_MANAGERS.valueOf(),
    AG_ROLE.JS_ACCT_MANAGER.valueOf()
  ];

  constructor(
    private graphService: GraphService,
    private http: HttpClient,
    private authService: MsalService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  showOnceRoleAssignedError = true;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.graphService.user.currentPath = route['_routerState'].url.slice(1);

    if (this.userRoles.length === 0) {
      return this.receiveUserRole().then(result => {
        if (!result) {
          return false;
        }

        const groups: string[] = this.userRoles;
        if (groups.length === 1 && groups[0] === '') {
          this.errorNoRolesAssigned();
          return false;
        }

        return this.handleAccess(false, this.graphService.user.currentPath);
      });
    }

    return this.handleAccess(false, this.graphService.user.currentPath);
  }

  receiveUserRole(): Promise<boolean> {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.graphService.user.displayName = accounts[0].idTokenClaims['preferred_username'];
      let params = new HttpParams();
      params = params.append('usr', this.graphService.user.displayName);

      this.authorizePromise = this.http.get<string[]>(
        environment.apiResourceUri + 'admin/role?' + params.toString()
      ).toPromise()
        .then((response) => {
          this.userRoles = response;
          if ((response.length === 0) || (response.length === 1 && response[0] === '')
            || !this.existingRoles.includes(response[0])) {
            this.errorNoRolesAssigned();
          }
          return true;
        }).catch(() => {
          this.router.navigate(['/not-authorized']);
          return false;
        });
    }

    return this.authorizePromise;
  }

  errorNoRolesAssigned() {
    if (this.showOnceRoleAssignedError) {
      this.showOnceRoleAssignedError = false;

      const data = 'There are no roles assigned for the user: ' + this.graphService.user.displayName + '\n Please contact your administrator!';
      this.router.navigate(['not-authorized'], { state: { data } });
    }
  }

  handleAccess(directiveCall: boolean, specificPath?: string) {
    let path = this.graphService.user.currentPath;
    if (specificPath) {
      path = specificPath;
    }
    const roleMap = new Map<string, string[]>();

    Object.keys(ROUTE).forEach(key => {
      Object.keys(ROUTE[key]).forEach(childKey => {
        roleMap.set(ROUTE[key][childKey].PATH, ROUTE[key][childKey].ROLE);
      });
    });

    const expectedRoles = roleMap.get(path);
    if (expectedRoles && !expectedRoles.some(r => this.hasRole(r))) {
      if (!directiveCall) {
        this.router.navigate(['/not-authorized']);
      }
      return false;
    }

    return true;
  }

  isAuthorized(): boolean {
    return this.graphService.user &&
      this.userRoles &&
      this.userRoles.length > 0 &&
      this.userRoles[0] !== '';
  }

  hasRole(role: string): boolean {
    return this.isAuthorized() && this.userRoles.find(r => r === role.valueOf()) !== undefined;
  }

  hasRoles(roles: string[], directiveCall: boolean): boolean {
    if (roles && roles.length > 0) {
      let hasCustomAccess = false;
      if (this.handleAccess(directiveCall) &&
        roles.filter(customRole => this.userRoles.indexOf(customRole) > -1).length > 0) {
        hasCustomAccess = true;
      }
      return hasCustomAccess;
    }
    return this.handleAccess(directiveCall);
  }
}
