<mat-dialog-content>
  <app-msg-banner *ngIf="showNotification" [msgList]="messageList"
                  (msgListChange)="messageList = $event"></app-msg-banner>

  <div fxLayout="row">
    <div fxFlex>
      <div fxLayout="row" fxLayoutAlign="start center">
        <h1>Link URL</h1>
      </div>
    </div>
  </div>

  <form
    [formGroup]="form"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="center stretch"
    fxLayoutGap="5%"
    class="dialog-form"
  >
    <mat-form-field
      hintLabel="Max 200 characters"
      fxFlex
      fxFlex.lt-md="100"
    >
      <input
        type="url"
        formControlName="url"
        matInput
        #input
        maxlength="200"
        placeholder="Enter URL"
        required
      />
      <mat-hint align="end">{{ input.value?.length || 0 }}/{{ input.maxLength }}</mat-hint>
    </mat-form-field>
  </form>

  <div
    fxLayout="row"
    fxLayoutAlign="center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="center stretch"
    fxLayoutGap="10px"
  >
    <button
      id="submit"
      class="btn btn-submit"
      (click)="onSubmit()"
      [disabled]="!form.valid"
      fxFlex="100px"
      fxFlex.lt-md="100"
    >
      Link Url
    </button>
    <button
      id="cancel"
      class="btn btn-cancel"
      (click)="onCancel()"
      fxFlex="100px"
      fxFlex.lt-md="100"
    >
      Cancel
    </button>
  </div>


</mat-dialog-content>
