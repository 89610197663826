<div>
  <button
    mat-button
    type="button"
    class="attachment-button"
    disableRipple
    *ngIf="attachments && attachments.length < config.limit && !isUrl && config.view === attachmentView.ROOM_DOCUMENTS"
    (click)="linkFile()"
  >
    <mat-icon>attachment</mat-icon>
    <span>Reference Document</span>
  </button>

  <button
    mat-button
    type="button"
    class="attachment-button"
    disableRipple
    *ngIf="attachments && attachments.length < config.limit && !isUrl && config.view !== attachmentView.ROOM_DOCUMENTS"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>attachment</mat-icon>
    <span>Attachment</span>
  </button>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngIf="attachments && attachments.length >= 0 && attachments.length < 1 && !isUrl && config.view !== attachmentView.ROOM_DOCUMENTS"
      (click)="addUrl()"
    >
      <span>Link URL</span>
    </button>
    <button
      *appUserRole="buildGuardParamBasedRole()"
      mat-menu-item
      (click)="uploadFile()"
    >
      <span *ngIf="config.view !== attachmentView.NOTIFICATION_TERRITORY && config.view !== attachmentView.ACTIVITY">Add temporary attachment</span>
      <span *ngIf="config.view === attachmentView.NOTIFICATION_TERRITORY || config.view === attachmentView.ACTIVITY">Attach File</span>
    </button>
    <button
      *appUserRole="buildGuardParamAdminRole()"
      mat-menu-item
      (click)="linkFile()"
    >
      <span>Attach File</span>
    </button>
  </mat-menu>

  <div
    style="padding: 0 10px"
    *ngIf="attachments && attachments.length > 0"
    fxLayout="column"
  >
    <div
      fxLayout="row"
      *ngFor="let attachment of attachments; let i = index"
    >
      <span
        class="attachment-button"
        fxLayoutAlign="start center"
      >
        <b *ngIf="!isUrl && config.view === attachmentView.ROOM_DOCUMENTS">Folder:&nbsp;</b>
        <b *ngIf="!isUrl && config.view !== attachmentView.ROOM_DOCUMENTS">File:&nbsp;</b>
        <b *ngIf="isUrl">URL:&nbsp;</b>
        <a
          [ngClass]="config.view === attachmentView.ROOM_DOCUMENTS ? 'ellipsis-name-without-cursor' : 'ellipsis-name'"
          (click)="openLink(i)"
        >
          <span *ngIf="attachment.length > 70">
                        {{ attachment.substring(0, 70) }}
            <button
              mat-button
              class="more-button"
              matTooltip="{{ attachment }}"
              matTooltipClass="tooltip-description">
            ...</button>
          </span>
          <span *ngIf="attachment.length <= 70">
            {{ attachment }}
          </span>
        </a>
      </span>
      <button
        *ngIf="isUrl"
        fxLayoutAlign="center center"
        type="button"
        (click)="edit(i, attachment)"
        mat-icon-button
      >
        <mat-icon style="color: #42a5f5;">edit</mat-icon>
      </button>
      <button
        fxLayoutAlign="center center"
        type="button"
        (click)="delete(i)"
        mat-icon-button
      >
        <mat-icon style="color: red">delete</mat-icon>
      </button>

      <div
        fxFlex fxLayoutAlign="start center"
        class="loading-bar"
      >
        <mat-progress-bar
          *ngIf="isLoading[i]"
          mode="indeterminate"
          class="example-tree-progress-bar"
        ></mat-progress-bar>
      </div>
    </div>
  </div>

</div>
