import {ROUTE} from '../../constants/routes-constants';
import {AG_ROLE} from '../../constants/roles';
import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {GraphService} from '../../../core/graph/graph.service';
import {MsalService} from '@azure/msal-angular';
import {CounterService} from '../../../core/services/counter.service';
import {RoleGuardService} from '../../../core/guards/role-guard.service';
import {environment} from '../../../../environments/environment';
import * as path from 'path';

declare const $: any;

declare interface ParentRouteInfo {
  title: string;
  path: string;
  icon: string;
  class: string;
  expanded: Boolean;
  childs: RouteInfo[];
}

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  expanded: Boolean;
  childs: RouteInfo[];
  class: string;
}

export const ROUTES: ParentRouteInfo[] = [
  {
    title: 'Activities',
    path: 'activity',
    icon: 'event_note',
    class: '',
    expanded: false,
    childs: [
      {
        path: ROUTE.ACTIVITY.NEW.PATH,
        title: 'Create',
        icon: 'add',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ACTIVITY.PUBLISH.PATH,
        title: 'Publish',
        icon: 'send',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ACTIVITY.MANAGE.PATH,
        title: 'Personal Activities',
        icon: 'group',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ACTIVITY.MANAGE_GROUP.PATH,
        title: 'Manage Compliance',
        icon: 'settings',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ACTIVITY.PHOTO_COMPLICANCE_QUICK.PATH,
        title: 'View Compliance',
        icon: 'fact_check',
        class: '',
        expanded: false,
        childs: []
      }
    ],
  },
  {
    title: 'Notifications',
    icon: 'notifications',
    path: 'notification',
    class: '',
    expanded: false,
    childs: [
      {
        path: ROUTE.NOTIFICATION.NEW.PATH,
        title: 'Create',
        icon: 'add',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.NOTIFICATION.PUBLISH.PATH,
        title: 'Publish',
        icon: 'send',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: 'notification/manage',
        title: 'Manage',
        icon: 'settings',
        expanded: false,
        class: 'child-parent',
        childs: [
          {
            path: ROUTE.NOTIFICATION.MANAGE_GENERAL.PATH,
            title: 'General',
            icon: '',
            class: '',
            expanded: false,
            childs: []
          },
          {
            path: ROUTE.NOTIFICATION.MANAGE_CHAIN.PATH,
            title: 'Account',
            icon: '',
            class: '',
            expanded: false,
            childs: []
          },
          {
            path: ROUTE.NOTIFICATION.MANAGE_TERRITORY.PATH,
            title: 'Territory',
            icon: '',
            class: '',
            expanded: false,
            childs: []
          }
        ]
      },
      {
        path: 'notification/view',
        title: 'View',
        icon: 'plagiarism',
        class: 'child-parent',
        expanded: false,
        childs: [
          {
            path: ROUTE.NOTIFICATION.VIEW_GENERAL.PATH,
            title: 'General',
            icon: '',
            class: '',
            expanded: false,
            childs: []
          },
          {
            path: ROUTE.NOTIFICATION.VIEW_CHAIN.PATH,
            title: 'Account',
            icon: '',
            class: '',
            expanded: false,
            childs: []
          },
          {
            path: ROUTE.NOTIFICATION.VIEW_TERRITORY.PATH,
            title: 'Territory',
            icon: '',
            class: '',
            expanded: false,
            childs: []
          }
        ]
      },
      {
        path: 'notification/documents',
        title: 'Documents',
        icon: 'description',
        class: 'child-parent',
        expanded: false,
        childs: [
          {
            path: ROUTE.NOTIFICATION.VIEW_GENERAL_DOCUMENTS.PATH,
            title: 'General',
            icon: '',
            class: '',
            expanded: false,
            childs: []
          },
          {
            path: ROUTE.NOTIFICATION.VIEW_CHAIN_DOCUMENTS.PATH,
            title: 'Account',
            icon: '',
            class: '',
            expanded: false,
            childs: []
          }
        ]
      }
    ],
  },
  {
    title: 'Admin',
    path: 'admin',
    icon: 'admin_panel_settings',
    class: '',
    expanded: false,
    childs: [
      {
        path: ROUTE.ADMIN.RESPONSE.PATH,
        title: 'Activity Responses',
        icon: 'question_answer',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ADMIN.USER.PATH,
        title: 'Users Permissions',
        icon: 'people',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ADMIN.TRAINING.PATH,
        title: 'Training Materials',
        icon: 'snippet_folder',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ADMIN.LOCATION.PATH,
        title: 'Locations',
        icon: 'location_on',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ADMIN.SEASON.PATH,
        title: 'Seasons',
        icon: 'edit_calendar',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ADMIN.ATTACHMENTS.PATH,
        title: 'Attachments',
        icon: 'attachment',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ADMIN.ROOM.PATH,
        title: 'Room Management',
        icon: 'room_preferences',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ADMIN.DOCUMENTS.PATH,
        title: 'Documents',
        icon: 'description',
        class: '',
        expanded: false,
        childs: []
      },
      {
        path: ROUTE.ADMIN.TERRITORY_CHANGES.PATH,
        title: 'Territory Changes',
        icon: 'manage_history',
        class: '',
        expanded: false,
        childs: []
      }
    ],
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input('drawer') drawer;

  menuItems: ParentRouteInfo[];
  public permissionsReceived = true;
  loggedIn = false;
  panelOpenState = false;

  clickEventSubscription: Subscription;

  publishActivityCount = 0;
  publishNotificationCount = 0;

  constructor(private graphService: GraphService,
              private counterService: CounterService,
              private roleGuardService: RoleGuardService,
              private authService: MsalService) {
    this.clickEventSubscription = this.graphService
      .getClickEvent()
      .subscribe((response) => {
        this.loggedIn = true;
      });

    this.counterService
      .listenRefreshActivityCounter()
      .subscribe(r => {
        this.getPublishActivityCount();
      });

    this.counterService
      .listenRefreshNotificationCounter()
      .subscribe(r => {
        this.getPublishNotificationCount();
      });
  }

  ngOnInit() {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts?.length > 0) {
      this.loggedIn = true;
    }
    this.menuItems = ROUTES.filter((menuItem) => menuItem);

    if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_MERCH_ADMIN)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCH_ADMIN)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_IT)) {
      this.getPublishActivityCount();
      this.getPublishNotificationCount();
    }
  }

  getPublishActivityCount() {
    this.counterService.getActivityPublishCount().subscribe(
      response => {
        this.publishActivityCount = response;
      }, error => {
        console.error(error);
      }
    );
  }

  getPublishNotificationCount() {
    this.counterService.getNotificationPublishCount().subscribe(
      response => {
        this.publishNotificationCount = response;
      }, error => {
        console.error(error);
      }
    );
  }

  get getRoute() {
    return ROUTE;
  }

  togglePanel() {
    this.panelOpenState = !this.panelOpenState;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  closeSideBar(): void {
    // this.appComponent.toggleSideBar();
  }

  toggle(item) {
    for (let i = 0; i < ROUTES.length; i++) {
      if (ROUTES[i].title === item.title) {
        ROUTES[i].expanded = !ROUTES[i].expanded;
        continue;
      }
      ROUTES[i].expanded = false;
    }
  }

  toggleChild(item) {
    for (let i = 0; i < ROUTES.length; i++) {
      for (let j = 0; j < ROUTES[i].childs.length; j++) {
        if (ROUTES[i].childs[j] === item) {
          ROUTES[i].childs[j].expanded = !ROUTES[i].childs[j].expanded;
          continue;
        }
        ROUTES[i].childs[j].expanded = false;
      }
    }
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
  }

  buildRoleGuardParam(path) {
    return {
      customeRole: [],
      path: path
    };
  }

  buildAdminRoleGuardParam() {
    return {
      customeRole: [ AG_ROLE.UK_MERCH_ADMIN, AG_ROLE.JS_MERCH_ADMIN, AG_ROLE.JS_IT ],
      path: 'analytics'
    };
  }

  buildGuardParamBasedRole(item) {
    let customRoles = [];
    if (item.path === 'notification/view' || item.path === 'notification/documents') {
      customRoles = [
        AG_ROLE.UK_ACCT_MANAGER,
        AG_ROLE.UK_CUST_OPS,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCHANDISER_MANAGERS,
        AG_ROLE.JS_ACCT_MANAGER
      ];
    }
    if (item.path === 'notification/manage') {
      customRoles = [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.UK_TERR_MANAGER,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT,
        AG_ROLE.JS_MERCHANDISER_MANAGERS
      ];
    }
    if (item.path === 'admin') {
      customRoles = [
        AG_ROLE.UK_MERCH_ADMIN,
        AG_ROLE.JS_MERCH_ADMIN,
        AG_ROLE.JS_IT
      ];
    }
    return {
      customeRole: customRoles,
      path: ''
    };
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: 'landing'
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  get getEnvironment() {
    return environment;
  }

  goToAnalytics() {
    window.open(environment.reportingUrl);
  }
}
