import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {IPagedResponse} from '../../../shared/models/IPagedResponse';
import {MatTableDataSource} from '@angular/material/table';
import {AdminService} from '../../../core/services/admin.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {StringResponse} from '../../../shared/models/string-response';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../../shared/models/dialog';
import {Training} from '../../../shared/models/training.model';
import {TrainingDialogComponent} from '../training-dialog/training-dialog.component';
import {MsgBannerService} from '../../../shared/components/msg-banner/msg-banner.service';

@Component({
  selector: 'app-training-management',
  templateUrl: './training-management.component.html',
  styleUrls: ['./training-management.component.scss']
})
export class TrainingManagementComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = [
    'name',
    'url',
    'actions'
  ];

  // error list
  messageList = [];
  showNotification = false;

  searchValue: string = null;

  dataSource = new MatTableDataSource<Training>();
  pageEvent: PageEvent;
  pageSize = 50;
  totalRows = 0;

  constructor(private service: AdminService,
              private msgBanner: MsgBannerService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSource.filterPredicate = (data: Training, filter: string) =>
      (data.name)
        .trim()
        .toLowerCase()
        .indexOf(filter) !== -1;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getTrainings();
  }

  applyFilter(clearKeyUp?: boolean) {
    if (clearKeyUp && this.searchValue != null && this.searchValue.length === 0) {
      this.paginator.pageIndex = 0;
      this.getTrainings();
    } else if (clearKeyUp === undefined) {
      this.paginator.pageIndex = 0;
      this.getTrainings();
    }
  }

  getTrainings() {
    this.showNotification = false;
    const pageIndex = this.paginator ? this.paginator.pageIndex : 0;
    const pageSize = this.paginator ? this.paginator.pageSize : 50;
    this.service.getTrainings(pageIndex, pageSize, this.searchValue).subscribe(
      (response: IPagedResponse<Training>) => {
        this.totalRows = response.totalElements;
        this.dataSource = new MatTableDataSource<Training>(response.content);
      },
      (error: any) => {
        this.dataSource = new MatTableDataSource<Training>();
        this.totalRows = 0;

        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  openAdd() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    this.messageList = [];
    this.showNotification = false;

    const dialogRef = this.dialog.open(TrainingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.service.createTraining(data).subscribe(
            (response: StringResponse) => {
              this.showConfirmationMessageAndRetrieveData(response.response);
            },
            (error) => {
              if (error.status === 400) {
                this.msgBanner.addMsgError(this.messageList, error.error.message);
              } else {
                this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
              }
              this.showNotification = true;
            }
          );
        }

      }, error => {
        if (error.status === 400) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      });
  }

  showConfirmationMessageAndRetrieveData(message: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(message, false, false, false),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      response => {
        this.getTrainings();
        if (response) {
          return;
        }
      }
    );
  }

  openEdit(trainingData: Training) {
    this.messageList = [];
    this.showNotification = false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = trainingData;
    const index = this.dataSource.data.findIndex(data => data.name === trainingData.name);
    const dialogRef = this.dialog.open(TrainingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.service.editTraining(data).subscribe(
            (response: StringResponse) => {
              this.showConfirmationMessageAndRetrieveData(response.response);
            },
            (error) => {
              if (error.status === 400) {
                this.msgBanner.addMsgError(this.messageList, error.error.message);
              } else {
                this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
              }
              this.showNotification = true;
            }
          );
        }

      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      });
  }

  onDelete(trainingData: Training) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog('Do you want to delete this training?', true, false, true),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      response => {
        if (response) {
          this.service.deleteTraining(trainingData).subscribe(
            (resp: StringResponse) => {
              this.showConfirmationMessageAndRetrieveData(resp.response);
            },
            (error) => {
              this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
              this.showNotification = true;
            }
          );
        }
      }
    );
  }

  public getServerData(event?: PageEvent) {
    this.getTrainings();
    return event;
  }

  sortTable(sort: Sort) {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data.sort((a, b) => {
        return compare(a.name, b.name, true);
      });
      return;
    }
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'url': return compare(a.url, b.url, isAsc);
        default: return 0;
      }
    });

    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  openLink(url) {
    if (url.includes('http')) {
      window.open(url);
    }
  }
}
