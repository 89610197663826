<mat-dialog-content
  class="modalSearch"
  [formGroup]="form"
>
  <app-msg-banner *ngIf="showNotification" [msgList]="messageList" (msgListChange)="messageList = $event"></app-msg-banner>
  <div
    mat-dialog-title
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayoutAlign="start">Edit Response</div>
    <div fxLayoutAlign="end">
      <button
        mat-icon-button
        (click)="closeDialog()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div
    fxLayout="column"
    fxLayoutGap="2%"
  >
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
    >
      <mat-form-field
        fxFlex
        fxFlex.lt-md="100%"
        appearance="standard"
      >
        <mat-label>Select a compliance flag</mat-label>
        <mat-select formControlName="complianceFlag" required>
          <mat-option [value]="true">Y</mat-option>
          <mat-option [value]="false">N</mat-option>
        </mat-select>
      </mat-form-field>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
      >
        <mat-checkbox style="margin-right: 2%" formControlName="imageReq" [(ngModel)]="imgReqValue">
          Image required
        </mat-checkbox>
        <mat-checkbox #commReq formControlName="commentReq" [(ngModel)]="commReqValue">
          Comment required
        </mat-checkbox>
      </div>
    </div>

    <mat-form-field
      fxFlex
      fxFlex.lt-md="100%"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start"
      *ngIf="commReq.checked"
    >
      <mat-label>Comment Prompt</mat-label>
      <input
        matInput
        formControlName="comment"
        type="text"
        [maxLength]="getLength().comment"
      />
      <mat-hint>{{ form.controls.comment.value ? form.controls.comment.value.length : 0 }}/{{ getLength().comment }}</mat-hint>
      <mat-error *ngIf="form.controls.comment.errors?.pattern && form.controls.comment.dirty">
        * Input not allowed
      </mat-error>
      <mat-error *ngIf="form.controls.comment.errors?.maxLength && form.controls.comment.touched">
        {{form.controls.comment.errors?.maxLength}}
      </mat-error>
    </mat-form-field>

    <mat-form-field
      fxFlex
      fxFlex.lt-md="100%"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start"
      appearance="standard"
    >
      <mat-label>Response Message</mat-label>
      <textarea
        formControlName="response"
        matInput
        required
        [maxLength]="getLength().response"
        type="text"
        rows="4"
      ></textarea>
      <mat-hint>{{ form.controls.response.value ? form.controls.response.value.length : 0 }}/{{ getLength().response }}</mat-hint>
      <mat-error *ngIf="form.controls.response.errors?.pattern && form.controls.response.dirty">
        * Input not allowed
      </mat-error>
    </mat-form-field>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center center"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
      fxLayoutGap.lt-md="0"
    >
      <button fxFlex="90px" fxFlex.lt-md="100%" mat-button class="btn btn-primary"
              style="background-color: #42a5f5"
              [disabled]="this.form.invalid"
              (click)="save()">
        Save
      </button>
      <button fxFlex="90px" fxFlex.lt-md="100%" mat-button (click)="close()"
              style="background-color: #f44336"
              class="btn btn-primary">
        Close
      </button>
    </div>
  </div>
</mat-dialog-content>
