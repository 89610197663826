import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-photo-dialog',
  templateUrl: './photo-dialog.component.html',
  styleUrls: ['./photo-dialog.component.scss']
})
export class PhotoDialogComponent implements OnInit {

  images: string[] = [];

  constructor(public dialogRef: MatDialogRef<PhotoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.data.imgs.forEach(img =>
      this.images.push(this.getFullImage(img))
    );
  }

  getFullImage(image: string) {
    const url = localStorage.getItem(environment.imagesBucket);
    if (url) {
      return JSON.parse(url).url.replace('*', image);
    }
  }

  errorHandler(event, task) {
    if (task !== null) {
      const errorImgUrl = event.target.attributes['src'].nodeValue;
      for (let i = 0; i < task.imgs.length; i++) {
        if (task.imgs[i] === errorImgUrl) {
          task.imgs[i]['errorPicture'] = true;
        }
      }
    }
    event.target.src = 'assets/img/image_not_availible.jpg';
  }
}
