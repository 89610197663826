import {UtilsService} from '../../../../core/services/utils.service';
import {NOTIF_CONSTS} from '../../../../shared/constants/notification-constants';
import {CustomFieldsValidators} from '../../../../shared/components/custom-validators/CustomFieldsValidators';
import {Dialog} from '../../../../shared/models/dialog';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {Chain} from '../../../../shared/models/chain';
import {take, takeUntil} from 'rxjs/operators';
import {GraphService} from '../../../../core/graph/graph.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AG_ROLE} from '../../../../shared/constants/roles';
import {AttachmentConfig, AttachmentView} from '../../../../shared/models/attachment.config';
import {GenericAttachmentComponent} from '../../../../shared/components/generic-attachment/generic-attachment.component';
import {Notification} from '../../../../shared/models/notification/notification.model';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {ConfirmationDialogComponent} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {environment} from '../../../../../environments/environment';
import {RoleGuardService} from '../../../../core/guards/role-guard.service';
import {RestrictedAccessObject} from '../../../../shared/models/notification/restricted-access-object.model';
import {User} from '../../../../shared/models/activity/user.model';
import {UserService} from '../../../../core/services/user.service';


const CONFIG = {
  toolbar: [
    [ 'Bold', 'Italic', 'Underline', '-', 'NumberedList', 'BulletedList' ]
  ],
  width: '100%',
  height: '100px',
  allowedContent: false,
  forcePasteAsPlainText: true,
  removePlugins: ['exportpdf', 'elementspath', 'magicline'],
  extraPlugins: 'wordcount',
  wordcount: {
    showParagraphs: false,
    showWordCount: false,
    showCharCount: true,
    countSpacesAsChars: true,
    countHTML: false,
    maxWordCount: -1,
    maxCharCount: environment.lengthValidators.description,
  },
  removeButtons: 'Source,Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Copy,Paste,PasteText,'             +
    'PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,'        +
    'Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,'    +
    'Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Link,'    +
    'Unlink,Image,Flash,Table,Smiley,SpecialChar,PageBreak,Iframe,Styles,TextColor,Maximize,ShowBlocks,BGColor,' +
    'About,Format,Font,FontSize,HorizontalRule,Anchor'
};

@Component({
  selector: 'app-new-notification',
  templateUrl: './new-notification.component.html',
  styleUrls: ['./new-notification.component.scss']
})
export class NewNotificationComponent implements OnInit, OnDestroy {

  @Input() pageConfig;
  @Input() isPublish;
  @Output() editedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(GenericAttachmentComponent) attachmentContainer: GenericAttachmentComponent;

  readonly NOTIF_CONSTS = NOTIF_CONSTS;
  attachmentConfig: AttachmentConfig = new AttachmentConfig();

  @ViewChild('roomMultiSelect', { static: true }) roomMultiSelect: MatSelect;
  public roomMultiFilterCtrl: FormControl = new FormControl();
  public filteredRoomsMulti: ReplaySubject<RestrictedAccessObject[]> = new ReplaySubject<RestrictedAccessObject[]>(1);
  public tooltipMessage = 'Select All / Unselect All';

  @ViewChild('terrMultiSelect', { static: true }) terrMultiSelect: MatSelect;
  public terrMultiFilterCtrl: FormControl = new FormControl();
  public filteredTerrsMulti: ReplaySubject<RestrictedAccessObject[]> = new ReplaySubject<RestrictedAccessObject[]>(1);

  @ViewChild('userMultiSelect', { static: true }) userMultiSelect: MatSelect;
  public userMultiFilterCtrl: FormControl = new FormControl();
  public filteredUsersMulti: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  disableUsers = true;

  rooms: RestrictedAccessObject[] = [];
  terrs: RestrictedAccessObject[] = [];
  users: User[];
  protected _onDestroy = new Subject<void>();
  displayAudiences = true;

  constructor(
    public notificationService: NotificationService,
    private userService: UserService,
    private graphService: GraphService,
    private roleGuardService: RoleGuardService,
    private msgBanner: MsgBannerService,
    public dialog: MatDialog
  ) {
    this.form = new FormGroup({
      id: new FormControl(null, []),
      title: new FormControl(null, [Validators.required, CustomFieldsValidators.maxTitle, Validators.pattern(environment.emojiRegEx)]),
      desc: new FormControl(null, [Validators.required, CustomFieldsValidators.maxDescription, Validators.pattern(environment.emojiRegEx)]),
      notType: new FormControl(null, [Validators.required]),
      lang: new FormControl(null, [Validators.required]),
      author: new FormControl(this.graphService.user.displayName, [Validators.required]),
      posted: new FormControl(null, [Validators.required, Validators.pattern(environment.emojiRegEx)]),
      from: new FormControl(null, [Validators.required, Validators.pattern(environment.emojiRegEx)]),
      until: new FormControl(null, [Validators.required, Validators.pattern(environment.emojiRegEx)]),
      audience: new FormControl([], []),
      rooms: new FormControl([], []),
      terr: new FormControl(null, []),
      users: new FormControl([], []),
      attach: new FormControl(null, []),
    });
  }

  pageTitle = 'Create Notification';

  config = CONFIG;

  selectable = true;

  showNotification = false;
  messageList = [];

  notificationTypes = [];
  languages = [];
  audienceList = [];

  attachments: string[] = [];
  isUrl = false;

  form: FormGroup;

  ngOnInit(): void {
    if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_MERCH_ADMIN) || this.roleGuardService.userRoles.includes(AG_ROLE.JS_IT)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCH_ADMIN)) {
      this.attachmentConfig.view = AttachmentView.NOTIFICATION;
    } else if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS)) {
      this.attachmentConfig.view = AttachmentView.TEMP;
    } else {
      this.attachmentConfig.view = AttachmentView.TEMP;
    }
    this.attachmentConfig.isArchived = false;
    this.attachmentConfig.limit = 5;

    this.fetchData();
    this.onPopulateLanguageList();
    this.onPopulateNotificationTypeList();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onPopulateTerrList() {
    this.notificationService.getTerrsByEmail(this.graphService.user.displayName, this.form.controls.id.value).subscribe(
      (response) => {
        this.terrs = response.values;
        if (response.values.length !== 0) {
          this.initTerrDropDownList();
        }
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  onPopulateChainList() {
    this.notificationService.getRoomsByEmail(this.graphService.user.displayName, this.form.controls.id.value).subscribe(
      (response) => {
        this.rooms = response.values;
        if (response.values.length !== 0) {
          this.initChainDropDownList();
        }
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  onPopulateLanguageList() {
    this.notificationService.getLanguages().subscribe(
      (response: any) => {
        this.languages = response.body;
        if (this.languages?.length > 0) {
          this.form.controls.lang.setValue(this.languages[0]);
        }
      },
      (error: any) => {
        console.log(error);
        this.msgBanner.addMsgError(this.messageList, error.message);
        this.showNotification = true;
      }
    );
  }

  onPopulateNotificationTypeList() {
    this.notificationService.getNotificationTypes().subscribe(
      (response: any) => {
        if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_CUST_OPS)
          || this.roleGuardService.userRoles.includes(AG_ROLE.UK_ACCT_MANAGER)
          || this.roleGuardService.userRoles.includes(AG_ROLE.JS_ACCT_MANAGER)) {
          this.notificationTypes = response.body.filter(r => r !== NOTIF_CONSTS.NOTIF_TYPE.TERR);
        } else if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER)
          || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS)) {
          this.notificationTypes = response.body.filter(r => r === NOTIF_CONSTS.NOTIF_TYPE.TERR);
        } else {
          this.notificationTypes = response.body;
        }
      },
      (error: any) => {
        console.log(error);
        this.msgBanner.addMsgError(this.messageList, error.message);
        this.showNotification = true;
      }
    );
  }

  onPrepopulateEditNotificationForm() {
    this.loadingEmitter.emit(true);
    if (this.pageConfig && this.pageConfig.notificationId) {
      this.pageTitle = this.pageConfig.type === NOTIF_CONSTS.PAGE.MANAGE ? 'Edit Notification' : 'View Notification';
      this.notificationService.getNotificationById(this.pageConfig.notificationId).subscribe(
        (response: any) => {
          Object.keys(this.form.value).forEach(key => {
            if (['posted', 'until', 'from'].includes(key)) {
              this.form.controls[key].setValue(UtilsService.dateFromServer(response.body[key]));
            } else {
              this.form.controls[key].setValue(response.body[key]);
            }
            this.form.controls[key].updateValueAndValidity();
          });

          if (this.form.controls.notType.value !== NOTIF_CONSTS.NOTIF_TYPE.GENERAL) {
            this.displayAudiences = false;
          }

          this.attachments = this.form.controls.attach.value ? this.form.controls.attach.value : [];
          this.isUrl = this.form.controls.attach.value && this.form.controls.attach.value.length > 0
            ? this.form.controls.attach.value[0]?.includes('http') : false;

          this.attachmentContainer.attachments = this.form.controls.attach.value;
          if (this.pageConfig.type === NOTIF_CONSTS.PAGE.VIEW) {
            this.form.disable();
          }
          if (this.form.status === 'INVALID') {
            setTimeout(() => {
              this.form.controls['desc'].setValue(response.body['desc']);
            }, 500);
          }
          if (this.form.controls.notType.value === NOTIF_CONSTS.NOTIF_TYPE.CHAIN) {
            this.onPopulateChainList();
          } else if (this.form.controls.notType.value === NOTIF_CONSTS.NOTIF_TYPE.TERR) {
            this.onPopulateTerrList();
            this.findUsersByTerritoryOnUpdate(this.form.controls.terr.value);
            this.disableUsers = false;
          }

          const body = response.body;
          this.attachmentConfig.view = body.terrs && body.terrs.length > 0 && body.attach && body.attach.length > 0 &&
            !body.attach[0].startsWith('TERRITORY/') && !body.attach[0].startsWith('CHAIN/') && !body.attach[0].startsWith('GENERAL/') ?
            AttachmentView.NOTIFICATION_TERRITORY : AttachmentView.NOTIFICATION;

          this.loadingEmitter.emit(false);
        },
        (error) => {
          this.msgBanner.addMsgError(this.messageList, error.message);
          this.showNotification = true;
          this.loadingEmitter.emit(false);
        }
      );
    } else {
      this.displayAudiences = false;
    }
  }

  private fetchData() {
    this.notificationService.getAudience().subscribe(
      response => {
        this.audienceList = response.body;
        this.onPrepopulateEditNotificationForm();
      }, error => {
        this.msgBanner.addMsgError(this.messageList, error.message);
        this.showNotification = true;
      }
    );
  }

  isSelected(offer: any): boolean {
    const index = this.form.controls.audience.value?.findIndex((audience) => audience === offer);
    return index >= 0;
  }

  toggleOffer(offer: any): void {
    const index = this.form.controls.audience.value.findIndex((audience) => audience === offer);

    if (index >= 0) {
      this.form.controls.audience.value.splice(index, 1);
    } else {
      this.form.controls.audience.value.push(offer);
    }
  }

  onSend(): void {
    this.loadingEmitter.emit(true);
    this.form.controls.attach.setValue(this.attachmentContainer.attachments &&
      this.attachmentContainer.attachments.length > 0 ? this.attachmentContainer.attachments : null);
    this.triggerValidationFields();
    const notification = new Notification();
    if (!this.form.invalid) {
      Object.keys(this.form.value).forEach(key => {
        if (['posted', 'until', 'from'].includes(key)) {

          notification[key] = UtilsService.dateToServerDate(this.convertDate(this.form.controls[key].value));
          console.log(this.form.controls[key].value + '  ->  ' +  notification[key]);
        } else {
          notification[key] = this.form.controls[key].value;
        }
      });

      if (notification.notType === NOTIF_CONSTS.NOTIF_TYPE.GENERAL) {
        notification.rooms = null;
        notification.terr = null;
        notification.users = null;
      } else if (notification.notType === NOTIF_CONSTS.NOTIF_TYPE.CHAIN) {
        notification.audience = null;
        notification.terr = null;
        notification.users = null;
      } else if (notification.notType === NOTIF_CONSTS.NOTIF_TYPE.TERR) {
        notification.audience = null;
        notification.rooms = null;
      }

      let isPublish = false;
      if (this.checkRoles()) {
        isPublish = true;
      }

      if (this.isPublish) {
        isPublish = true;
      }

      this.notificationService.createNotification(notification, isPublish).subscribe(
        (response) => {
          if (!this.pageConfig) {
            this.resetForm();
          }
          this.openResultDialog(response.response, false);
          this.editedEmitter.emit(response);
          this.loadingEmitter.emit(false);
        },
        (error) => {
          console.log(error);
          this.msgBanner.addMsgError(this.messageList, error.message);
          this.showNotification = true;
          this.loadingEmitter.emit(false);
        }
      );
    } else {
      this.openResultDialog('All required (*) fields must be completed and fields must not exceed maximum length.', false);
    }
  }

  resetForm() {
    this.form.reset();
    this.form.controls.audience.setValue([]);
    this.form.controls.author.setValue(this.graphService.user.displayName);

    if ((this.pageConfig && this.pageConfig.notificationId)) {
      this.closeEmitter.emit(this.form.value);
    }
    this.onPrepopulateEditNotificationForm();
    this.attachmentContainer.attachments = [];
    this.attachmentContainer.isUrl = false;

    this.disableUsers = true;
  }

  triggerValidationFields() {
    Object.keys(this.form.value).forEach(key => {
      this.form.controls[key].markAsTouched();
      this.form.controls[key].updateValueAndValidity();
    });
  }

  openResultDialog(data, showYesButton): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog(data, showYesButton, false),
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed ', result);
    });
  }

  getLength() {
    return environment.lengthValidators;
  }

  convertDate(value: any) {
    if (value instanceof Date) {
      return value;
    }
    return UtilsService.dateFromServer(value.toDate());
  }

  private initChainDropDownList() {
    this.filteredRoomsMulti.next(this.rooms.slice(0, 50));

    this.roomMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterChainsMulti();
      });
  }

  toggleChainsSelectAll(selectAllValue: boolean) {
    this.filteredRoomsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.form.controls.rooms.patchValue(val.map(chain => chain.value.roomId));
        } else {
          this.form.controls.rooms.patchValue([]);
        }
      });
  }

  toggleUserSelectAll(selectAllValue: boolean) {
    this.filteredUsersMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.form.controls.users.patchValue(val.map(user => user.usrId));
        } else {
          this.form.controls.users.patchValue([]);
        }
      });
  }

  protected filterChainsMulti() {
    if (!this.rooms) {
      return;
    }
    // get the search keyword
    let search = this.roomMultiFilterCtrl.value;
    if (!search) {
      this.filteredRoomsMulti.next(this.rooms);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredRoomsMulti.next(
      this.rooms
        .filter(
          (chain) =>
            chain.value.roomName
              .toLowerCase()
              .indexOf(search) > -1
        ).slice(0, 50)
    );
  }

  private initTerrDropDownList() {
    this.filteredTerrsMulti.next(this.terrs.slice(0, 50));

    this.terrMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTerrsMulti();
      });
  }

  protected filterTerrsMulti() {
    if (!this.terrs) {
      return;
    }
    // get the search keyword
    let search = this.terrMultiFilterCtrl.value;
    if (!search) {
      this.filteredTerrsMulti.next(this.terrs);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the territories
    this.filteredTerrsMulti.next(
      this.terrs
        .filter((territory) => territory.value.toLowerCase().indexOf(search) > -1)
        .slice(0, 50)
    );
  }

  onChange(event: MatSelectChange) {
    switch (event.value) {
      case 'General':
        this.displayAudiences = true;
        this.form.controls.audience.setValidators(Validators.required);
        this.form.controls.audience.updateValueAndValidity();
        this.form.controls.rooms.setValidators([]);
        this.form.controls.rooms.updateValueAndValidity();
        this.form.controls.terr.setValidators([]);
        this.form.controls.terr.updateValueAndValidity();
        this.form.controls.users.setValidators([]);
        this.form.controls.users.updateValueAndValidity();
        break;
      case 'Chain':
        this.displayAudiences = false;
        this.form.controls.rooms.setValidators(Validators.required);
        this.form.controls.rooms.updateValueAndValidity();
        this.form.controls.audience.setValidators([]);
        this.form.controls.audience.updateValueAndValidity();
        this.form.controls.terr.setValidators([]);
        this.form.controls.terr.updateValueAndValidity();
        this.form.controls.users.setValidators([]);
        this.form.controls.users.updateValueAndValidity();
        if (!this.rooms || this.rooms.length === 0) {
          this.onPopulateChainList();
        }
        break;
      case 'Territory':
        this.displayAudiences = false;
        this.form.controls.terr.setValidators(Validators.required);
        this.form.controls.terr.updateValueAndValidity();
        this.form.controls.users.setValidators(Validators.required);
        this.form.controls.users.updateValueAndValidity();
        this.form.controls.audience.setValidators([]);
        this.form.controls.audience.updateValueAndValidity();
        this.form.controls.rooms.setValidators([]);
        this.form.controls.rooms.updateValueAndValidity();
        if (!this.terrs || this.terrs.length === 0) {
          this.onPopulateTerrList();
        }
        break;
    }
  }

  changeConfig() {
    if (this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS)) {
      if (this.form.controls.notType.value === NOTIF_CONSTS.NOTIF_TYPE.TERR) {
        this.attachmentConfig.view = AttachmentView.NOTIFICATION_TERRITORY;
        this.attachmentContainer.attachments = [];
      } else {
        if (this.attachmentConfig.view !== AttachmentView.TEMP) {
          this.attachmentContainer.attachments = [];
        }
        this.attachmentConfig.view = AttachmentView.TEMP;
      }

    }
    this.attachmentConfig.isArchived = false;
    this.attachmentConfig.limit = 5;
  }

  deleteNotification() {
    this.showNotification = false;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog('Do you want to delete this notification?', true, false, true),
      disableClose: true,
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.loadingEmitter.emit(true);
        this.notificationService.deletePublishNotificationById(this.pageConfig.notificationId).subscribe(
          (response) => {
            this.openResultDialog(response.response, false);

            this.loadingEmitter.emit(false);
            this.closeEmitter.emit(true);
          }, error => {
            this.msgBanner.addMsgError(this.messageList, error.error.message);
            this.showNotification = true;
            this.loadingEmitter.emit(false);
          });
      }
    });
  }

  retrieveUsersByTerritory(event: MatSelectChange) {
    this.disableUsers = false;

    const terrs: string[] = Array.of(event.value);
    this.userService.getUsersByTerritory(terrs).subscribe(
      (response) => {
        this.users = response;
        if (response?.length !== 0) {
          this.sortUsersList(response);
          this.initUserDropDownList();
          this.form.controls.users.setValue(response.map(u => u.usrId));
        }
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  findUsersByTerritoryOnUpdate(value: string) {
    this.disableUsers = false;

    const terrs: string[] = Array.of(value);
    this.userService.getUsersByTerritory(terrs).subscribe(
      (response) => {
        this.users = response;
        if (response?.length !== 0) {
          this.sortUsersList(response);
          this.initUserDropDownList();
        }
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  private initUserDropDownList() {
    this.filteredUsersMulti.next(this.users);

    this.userMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsersMulti();
      });
  }

  protected filterUsersMulti() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userMultiFilterCtrl.value;
    if (!search) {
      this.filteredUsersMulti.next(this.users);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the territories
    this.filteredUsersMulti.next(
      this.users.filter((user) =>
        user.usrId.toLowerCase().indexOf(search) > -1
        || user.nam.toLowerCase().indexOf(search) > -1)
    );
  }

  private checkRoles() {
    return this.roleGuardService.userRoles.includes(AG_ROLE.UK_MERCH_ADMIN)
      || (this.roleGuardService.userRoles.includes(AG_ROLE.UK_TERR_MANAGER)
        && this.form.controls.notType.value === NOTIF_CONSTS.NOTIF_TYPE.TERR)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_IT)
      || this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCH_ADMIN)
      || (this.roleGuardService.userRoles.includes(AG_ROLE.JS_MERCHANDISER_MANAGERS)
        && this.form.controls.notType.value === NOTIF_CONSTS.NOTIF_TYPE.TERR);
  }

  sortUsersList(users: User[]) {
    users.sort(function(user1, user2) {
      if (user1.nam < user2.nam) return -1;
      if (user1.nam > user2.nam) return 1;
      return 0;
    });
  }
}
