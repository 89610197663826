<div
  fxLayout="column"
  fxLayoutGap="20px"
>

  <div fxLayout="row" fxLayoutAlign="end start">
    <mat-icon
      style="cursor:pointer;"
      (click)="dialogRef.close()"
    >close</mat-icon>
  </div>

  <mat-dialog-content>
      <nz-picture-viewer [images]="images" [download]="false"></nz-picture-viewer>
  </mat-dialog-content>

</div>
