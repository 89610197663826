import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication} from '@azure/msal-browser';
import {environment} from '../../environments/environment';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import * as config from './app-config.json';
import {CoreModule} from '../core/core.module';
import {SharedModule} from '../shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ImgViewerConfig, ImgViewerModule, ImgViewerType} from 'ng-picture-viewer';
import {IvViewerType} from 'ng-picture-viewer/src/interfaces/iv-viewer.type';
import {LoadingService} from '../core/interceptors/loading-service';
import {PagesModule} from './pages/pages.module';
import {LoadingInterceptor} from '../core/interceptors/loading-interceptor';
import {CommonModule} from '@angular/common';

export const imageViewerType: ImgViewerType = {
  zoomInToolTip: 'Zoom In',
  zoomOutToolTip: 'Zoom Out',
  rotateLeftToolTip: 'Rotate Left',
  rotateRightToolTip: 'Rotate Right',
  resetToolTip: 'Reset',
  fullScreenToolTip: 'Full Screen',
  downloadToolTip: 'Download'
};
export const ivViewerType: IvViewerType = null;
export const imgViewerConfig: ImgViewerConfig = {
  imageViewerType, ivViewerType
};

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('client logging' + message);
}

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: config.auth.authority,
      redirectUri: window.location.origin + environment.baseHref,
      postLogoutRedirectUri: window.location.origin + environment.baseHref,
      navigateToLoginRequestUrl: false,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: true,
      },
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiResourceUri, [environment.backendApiScope]);
  protectedResourceMap.set(config.resources.graphApi.resourceUri, [config.resources.graphApi.resourceScope]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/users', ['user.read', 'user.ReadBasic.all']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    PagesModule,
    MsalModule,
    BrowserAnimationsModule,
    ImgViewerModule.forRoot(imgViewerConfig)
  ],
  providers: [
    [ LoadingService ],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
