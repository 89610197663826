import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {UtilsService} from './utils.service';
import {IPagedResponse} from '../../shared/models/IPagedResponse';
import {Notification} from '../../shared/models/notification/notification.model';
import {RestrictedAccessObject} from '../../shared/models/notification/restricted-access-object.model';
import {RestrictedAccess} from '../../shared/models/restricted-access.model';
import {PublishNotification} from '../../shared/models/notification/publish-notification.model';
import {StringResponse} from '../../shared/models/string-response';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
  ) { }

  createNotification(notification: Notification, isPublish: boolean): Observable<any> {
    return this.http.post<Notification>(
      environment.apiResourceUri + (isPublish ? 'notifications/upsert' : 'publish/notifications/upsert'),
      notification
    );
  }

  getNotificationsByType(pageIndex: number, pageSize: number, notificationType, searchFields: any): Observable<IPagedResponse<Notification>> {
    const url = environment.apiResourceUri + 'notifications/view';
    let params: HttpParams = new HttpParams();
    params = params.append('page', pageIndex.toString());
    params = params.append('size', pageSize.toString());
    params = params.append('notificationType', notificationType);

    if (searchFields) {
      Object.keys(searchFields).forEach(key => {
        if (searchFields[key] !== null && searchFields[key] !== undefined && searchFields[key] !== '') {
          if (key === 'startNotificationDate' || key === 'endNotificationDate') {
            params = params.append(key, this.datePipe.transform(UtilsService.dateFromServer(searchFields[key]), 'yyyy-MM-dd'));
          } else {
            params = params.append(key, searchFields[key]);
          }
        }
      });
    }
    return this.http.get<any>(url, {
      observe: 'response',
      params,
    }).pipe(
      map((res) => {
        return {
          content: res.body.content,
          totalElements: res.body.totalElements
        };
      })
    );
  }

  getNotificationById(notificationId: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('notificationId', notificationId);
    return this.http.get<any>(environment.apiResourceUri + 'notifications', {
      observe: 'response',
      params,
    });
  }

  getAudience(): Observable<any> {
    return this.http.get<any>(environment.apiResourceUri + 'notifications/audience', {
      observe: 'response'
    });
  }

  getLanguages(): Observable<any> {
    return this.http.get<any>(environment.apiResourceUri + 'notifications/language', {
      observe: 'response'
    });
  }

  getNotificationTypes(): Observable<any> {
    return this.http.get<any>(environment.apiResourceUri + 'notifications/notification-type', {
      observe: 'response'
    });
  }

  deleteNotificationById(notificationId: String): Observable<any> {
    const url = environment.apiResourceUri + 'notifications/delete';

    return this.http.post<any>(
      url,
      notificationId
    );
  }

  getRoomsByEmail(email: string, id: string): Observable<RestrictedAccess> {
    let params = new HttpParams();
    if (id != null) {
      params = params.append('id', id);
    }
    return this.http.get<RestrictedAccess>(environment.apiResourceUri + 'notifications/' + email + '/chains', { params });
  }

  getTerrsByEmail(email: string, id: string): Observable<RestrictedAccess> {
    let params = new HttpParams();
    if (id != null) {
      params = params.append('id', id);
    }
    return this.http.get<RestrictedAccess>(environment.apiResourceUri + 'notifications/' + email + '/terrs', { params });
  }

  getPublishNotifications(pageIndex: number, pageSize: number, searchFields: any): Observable<IPagedResponse<PublishNotification>> {
    const url = environment.apiResourceUri + 'publish/notifications';
    let params: HttpParams = new HttpParams();
    params = params.append('page', pageIndex.toString());
    params = params.append('size', pageSize.toString());

    if (searchFields) {
      Object.keys(searchFields).forEach(key => {
        if (searchFields[key]) {
          if (key === 'startNotificationDate' || key === 'endNotificationDate') {
            params = params.append(key, this.datePipe.transform(UtilsService.dateFromServer(searchFields[key]), 'yyyy-MM-dd'));
          } else {
            params = params.append(key, searchFields[key]);
          }
        }
      });
    }
    return this.http.get<any>(url, {
      observe: 'response',
      params,
    }).pipe(
      map((res) => {
        return {
          content: res.body.content,
          totalElements: res.body.totalElements
        };
      })
    );
  }

  deletePublishNotificationById(notificationId: string): Observable<any> {
    const url = environment.apiResourceUri + 'publish/notifications/delete';

    let params = new HttpParams();
    params = params.append('notId', notificationId);

    return this.http.delete<any>(url, { params });
  }

  publishNotification(data: string[]): Observable<StringResponse> {
    const url = environment.apiResourceUri + 'publish/notifications';
    return this.http.post<StringResponse>(url, data);
  }
}
