import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Chain} from '../../shared/models/chain';
import {environment} from '../../../environments/environment';
import {ChainGroupModel} from '../../shared/models/activity/chain-group.model';
import {QuickSearchModel} from '../../shared/models/activity/quick-search.model';
import {Observable} from 'rxjs';
import {DefaultResponse} from '../../shared/models/activity/default-response.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityQuickSearchService {
  private header = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient) {}

  getChains() {
    return this.http.get<ChainGroupModel[]>(
      environment.apiResourceUri + 'chain-groups'
    );
  }

  getTitles (chainGrp: number, chains: string[], terrs: string[], expired: any): Observable<QuickSearchModel[]> {
    let params = null;
    params = new HttpParams();

    params = params.append('chainGrpId', chainGrp);
    params = params.append('chains', chains);
    params = params.append('expired', expired === '' ? false : expired);

    if (terrs && terrs?.length > 0) {
      params = params.append('terrs', terrs);
    }

    return this.http.get<QuickSearchModel[]>(
      environment.apiResourceUri + 'activities/compliance/quick-search', {params}
    );

}

}
