import { Component, OnInit } from '@angular/core';
import { FindActivityService } from '../../../core/services/find-activity.service';
import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';

@Component({
	selector: 'app-territory-changes',
	templateUrl: './territory-changes.component.html',
	styleUrls: ['./territory-changes.component.scss']
})
export class TerritoryChangesComponent implements OnInit {

	isLoadingResults = false;
	terrChangesMessage = "Sync Territory Changes.";

	messageList = []
	showNotification = false;

	constructor (private msgBanner: MsgBannerService,
				 private activityService: FindActivityService) 
	{ }

	ngOnInit(): void { }

	syncTerritoryChanges() {
		this.showNotification = false;
		this.isLoadingResults = true;

		this.activityService.propagateAccountTerritoryChanges().subscribe(
			(response) => {
				this.terrChangesMessage = "Territories have been updated.";
				this.isLoadingResults = false;
			},
			(error) => {
				this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
				this.showNotification = true;
				this.isLoadingResults = false;
				this.terrChangesMessage = "Sync Territory Changes.";
			}
		);
	}
}
