import { Component, OnInit } from '@angular/core';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthenticationResult, EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-module-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  loginDisplay = false;

  constructor(private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private router: Router) { }

  ngOnInit(): void {
    const acct = this.authService.instance.getAllAccounts();
    if (acct && acct.length > 0) {
      this.router.navigate(['/home']);
      return;
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        // redirect
        this.router.navigate(['/home']);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  get getEnvironment() {
    return environment;
  }

  login() {
    this.authService.loginRedirect();
  }
}
