import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AwsFile} from '../../shared/models/aws-file.model';
import {HttpClient, HttpEvent, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StringResponse} from '../../shared/models/string-response';
import {FileNode} from '../../shared/components/generic-file-structure/generic-file-structure.component';
import {AttachmentView} from '../../shared/models/attachment.config';
import {Chain} from '../../shared/models/chain';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(private http: HttpClient) {
  }

  getFileSystem(folder: string, type: number, isArchived: boolean): Observable<AwsFile[]> {
    let params = new HttpParams();
    if (folder) {
      params = params.append('folder', folder);
    }
    params = params.append('type', type.toString());
    params = params.append('isArchived', String(isArchived));
    return this.http.get<AwsFile[]>(environment.apiResourceUri + 'attachments', { params: params });
  }

  search(searchBy: string, type: number, isArchived: boolean): Observable<AwsFile[]> {
    let params = new HttpParams();
    if (searchBy) {
      params = params.append('searchBy', searchBy);
    }
    params = params.append('type', type.toString());
    params = params.append('isArchived', String(isArchived));
    return this.http.get<AwsFile[]>(environment.apiResourceUri + 'attachments/files', { params: params });
  }

  addObject(fileNode: FileNode, type: number): Observable<StringResponse> {
    let params = new HttpParams();
    params = params.append('type', type.toString());
    return this.http.post<StringResponse>(environment.apiResourceUri + 'attachments', fileNode.item.path, { params: params });
  }

  deleteObject(path: string, type: number): Observable<StringResponse> {
    let params = new HttpParams();
    params = params.append('type', type.toString());
    return this.http.put<StringResponse>(environment.apiResourceUri + 'attachments', path, { params: params });
  }

  bulkDeleteObjects(paths: string[], type: number): Observable<StringResponse> {
    let params = new HttpParams();
    params = params.append('type', type.toString());
    return this.http.put<StringResponse>(environment.apiResourceUri + 'attachments/bulk-delete', paths, { params: params });
  }

  copyObjects(path: string, newName: string, oldName: string, type: number): Observable<StringResponse> {
    return this.http.patch<StringResponse>(environment.apiResourceUri + 'attachments/' + oldName + '/' + newName + '/' + type, path);
  }

  uploadFile(file: File, path: string, type: number): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('path', path);
    formData.append('type', type.toString());

    const req = new HttpRequest('POST', `${environment.apiResourceUri}attachments/files`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  downloadFile(path: string, type: number): Observable<HttpResponse<Blob>> {
    let params = new HttpParams();
    type = path.lastIndexOf('/') === -1 ? AttachmentView.TEMP : type;
    params = params.append('type', type.toString());
    return this.http.post(environment.apiResourceUri + 'attachments/name', path, {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  public download(response: HttpResponse<Blob>) {
    const header = response.headers;
    const fileName = header.get('content-disposition').split('"')[1];

    const newBlob = new Blob([response.body], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob, fileName);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    window.open(data);

    // const link = document.createElement('a');
    // link.href = data;
    // link.download = fileName;
    // // this is necessary as link.click() does not work on the latest firefox
    // link.dispatchEvent(
    //   new MouseEvent('click', {
    //     bubbles: true,
    //     cancelable: true,
    //     view: window
    //   })
    // );
    //
    // setTimeout(function() {
    //   // For Firefox it is necessary to delay revoking the ObjectURL
    //   window.URL.revokeObjectURL(data);
    //   link.remove();
    // }, 100);
  }

  checkForUpdate(file: File, uploadPath: string, view: number): Observable<boolean> {
    return this.http.put<boolean>(`${environment.apiResourceUri}attachments/name/${view}/${file.name}`, uploadPath);
  }
}
