import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../models/dialog';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivityConstants} from '../../constants/ActivityConstants';
import {EditDialogComponent} from '../edit-dialog/edit-dialog.component';
import {copyArrayItem} from '@angular/cdk/drag-drop';
import {CustomFieldsValidators} from '../custom-validators/CustomFieldsValidators';
import {environment} from '../../../../environments/environment';
import {generateUUID} from '@microsoft/microsoft-graph-client/lib/es/middleware/MiddlewareUtil';
import {LoadResponsesConfig} from '../../models/activity/load-responses-config.model';
import {Response} from '../../models/activity/response.model';
import { FindActivityService } from '../../../core/services/find-activity.service';

@Component({
  selector: 'app-load-responses',
  templateUrl: './load-responses.component.html',
  styleUrls: ['./load-responses.component.scss']
})
export class LoadResponsesComponent implements OnInit {
  @Input() config: LoadResponsesConfig;
  @Input() parentActGrpId: string;

  disableAdd = true;
  disable = false;
  showAnswers = false;

  dataSource = new MatTableDataSource<Response>();
  displayedColumns: string[] = ActivityConstants.RESPONSES_FIND.displayedColumns;
  form: FormGroup;

  yes;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private findActivityService: FindActivityService
  ) {
    this.form = this.fb.group({
      response: ['', [Validators.required, Validators.pattern(environment.emojiRegEx), CustomFieldsValidators.maxResponseMessage]],
      complianceFlag: ['', Validators.required],
      commentReq: [''],
      comment: ['', [CustomFieldsValidators.maxComment, Validators.pattern(environment.emojiRegEx)]],
      imageReq: ['']
    });
  }

  ngOnInit(): void {
    if (this.config.data !== null) {
      const data = [];
      for (let i = 0; i < this.config.data.length; i++) {
        copyArrayItem(this.config.data, data, i, i);
      }
      this.dataSource = new MatTableDataSource<Response>(data);
      this.showAnswers = true;
    }
  }

  onResponseTyped(response) {
    this.disableAdd = response === '';
  }

  openDialog(message: string, showYes: boolean, isLoading: boolean, showNo: boolean): any {
    return this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: new Dialog(message, showYes, isLoading, showNo),
    });
  }

  verifyResponseUnicity(response: string, dataArray: Response[]) {
    if (dataArray == null) {
      return true;
    }
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].desc === response) {
        this.openDialog('That response is already added', false, false, false);
        this.form.reset();
        return false;
      }
    }
    return true;
  }

  enableButtonsByClassName(className: string, color, backgroundColor): void {
    const buttons = document.getElementsByClassName(
      className
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].removeAttribute('disabled');
      buttons[i].style.backgroundColor = backgroundColor;
      buttons[i].style.color = color;
    }
  }

  onClickAddResponse(response, complianceFlag, imgReq, commReq): void {
    if (this.form.invalid) {
      this.openDialog('Please complete all required fields.', false, false, false);
      return;
    }
    if (complianceFlag.value === undefined) {
      this.openDialog('You have not selected a compliance flag', false, false, false);
    } else {
      if (response === '') {
        this.openDialog('You have not written a response', false, false, false);
      } else {
        response = response.trim();
        if (this.verifyResponseUnicity(response, this.dataSource.data)) {
          this.enableButtonsByClassName('redButton', '#ec1c45', '#fff');
          // Add new response to table
          this.dataSource.data.push({
            respId: null,
            desc: response,
            complInd: complianceFlag.value === 'Y',
            cmntReq: commReq.checked,
            cmntPpt: commReq.checked ? this.form.controls.comment.value : null,
            imgReq: imgReq.checked
          });

          // Refresh table
          this.dataSource.data = this.dataSource.data;
          if (this.dataSource.data.length) {
            this.showAnswers = true;
          }

          // Refresh response form
          this.form.reset();
          complianceFlag.value = undefined;
          this.onResponseTyped('');
        } else {
          complianceFlag.value = undefined;
        }
      }
    }
  }

  // checks if the response is already selected for an activity
  checkAndEditResponse(element: any) {
    if (element.respId === null) {
      this.editResponse(element, false);
      return;
    }
    
    this.findActivityService.checkIfResponseIsSelected(this.parentActGrpId, element.respId).subscribe(
      (response: boolean) => {
        this.editResponse(element, response);
      },
      (error) => {
        this.openDialog('An error has occurred. Please contact your administrator!',
          false,
          false,
          false
        );
        return;
      }
    );
  }

  editResponse(element: any, isSelected: boolean) {
    if (element.respId === null) {
      element.respId = generateUUID();
    }
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: 'auto',
      data: {
        complInd: element.complInd,
        desc: element.desc,
        cmntReq: element.cmntReq,
        imgReq: element.imgReq,
        cmntPpt: element.cmntPpt,
        isSelected: isSelected
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSource.data.find(a => a.respId === element.respId).desc = result.desc;
        this.dataSource.data.find(a => a.respId === element.respId).complInd = result.complInd;
        this.dataSource.data.find(a => a.respId === element.respId).cmntReq = result.cmntReq;
        this.dataSource.data.find(a => a.respId === element.respId).imgReq = result.imgReq;
        this.dataSource.data.find(a => a.respId === element.respId).cmntPpt = result.cmntPpt;
      }
    });
  }

  checkAndDeleteResponse(element: any) {
    if (element.respId === null) {
      this.deleteResponse(element);
      return;
    }

    this.findActivityService.checkIfResponseIsSelected(this.parentActGrpId, element.respId).subscribe(
      (response: boolean) => {
        if (response) {
          this.openDialog('This response is already used and it can not be removed!',
            false,
            false,
            false
          );
          return;
        }

        this.deleteResponse(element);
      },
      (error) => {
        this.openDialog('An error has occurred. Please contact your administrator!',
          false,
          false,
          false
        );
        return;
      }
    );
  }

  deleteResponse(element: any): void {
    if (element.respId === null) {
      element.respId = generateUUID();
    }
    if (this.dataSource.data.length === 1) {
      this.openDialog('This activity/set requires at least one response. Please add another response before deleting this one!',
        false,
        false,
        false
      );
      return;
    }
    const dialogRef = this.openDialog('Do you want to delete this response?',
      true,
      false,
      true
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        // Delete response from the response table source
        for (let i = 0; i < this.dataSource.data.length; i++) {
          if (this.dataSource.data[i].respId === element.respId) {
            this.dataSource.data.splice(i, 1);
            this.dataSource.data = this.dataSource.data;
            break;
          }
        }
        // If last response was deleted
        if (this.dataSource.data.length === 0) {
          this.showAnswers = false;
          dialogRef.close();
          this.openDialog('No more responses to show.', false, false, false);
        }
        dialogRef.close();
        return;
      }
    });
  }

  reset() {
    this.dataSource = new MatTableDataSource<Response>();
    this.showAnswers = false;
    this.form.reset();
  }

  getLength() {
    return environment.lengthValidators;
  }
}
