import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AttachmentConfig, AttachmentView} from '../../models/attachment.config';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Training} from '../../models/training.model';
import {MsgBannerService} from '../msg-banner/msg-banner.service';

@Component({
  selector: 'app-link-url',
  templateUrl: './link-url.component.html',
  styleUrls: ['./link-url.component.scss']
})
export class LinkUrlComponent implements OnInit {

  // error list
  messageList = [];
  showNotification = false;

  form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public config: AttachmentConfig,
              private fb: FormBuilder,
              private msgBanner: MsgBannerService,
              private dialogRef: MatDialogRef<LinkUrlComponent>) {
    this.form = this.fb.group({
      url: [ config.text, [Validators.required] ],
    });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    this.showNotification = false;
    if (this.form.invalid) {
      this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
      this.showNotification = true;
    }
    this.dialogRef.close(this.form.controls.url.value);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

}
