<div
  class="item"
  fxLayout="row"
  [ngClass]="{
    'non-compliant-activity': activity.isCompliant === false,
    'compliant-activity': activity.isCompliant === true,
    selected: activity.isSelected
  }"
>
  <mat-checkbox
    class="checkbox"
    (click)="$event.stopPropagation()"
    (change)="$event ? selection.toggle(activity) : null"
    [checked]="selection.isSelected(activity)"
    [aria-label]="checkboxLabel(activity)"
  ></mat-checkbox>
  <div
    class="photo-container"
  >
    <button
      mat-icon-button
      class="arrow-button"
      style="padding-left: 15px;"
      [ngStyle]="{'visibility': currentImageIndex == 0 ? 'hidden' : ''}"
      [disabled]="currentImageIndex == 0"
      (click)="previous()"
    >
      <mat-icon>arrow_back_ios</mat-icon>
    </button>

    <div
      class="item-photo"
      fxFlex="340px" fxLayout="row" fxLayoutAlign="start center"
    >
      <div (click)="openPhotoDialog(activity)" class="img-zoom-container">
        <div [ngClass]="activity.isLoadingPicture ? 'holder-image' : ''" #img>
          <img
            *ngIf="activity.imgs && activity.imgs.length > 0"
            [ngStyle]="{'display': activity.isLoadingPicture ? 'none' : ''}"
            [src]="getFullImage(activity?.imgs[currentImageIndex])"
            (load)="onLoadFinish()"
          />

          <img
            *ngIf="!activity.imgs || activity.imgs?.length === 0"
            [ngStyle]="{'display': activity.isLoadingPicture ? 'none' : ''}"
            class="img-not-available"
            src="../../../../../assets/img/image_not_available.png"
          />
        </div>

        <img
          *ngIf="activity.isLoadingPicture"
          class="loading"
          src="../../../../../assets/img/rose.gif"
        />
      </div>

    </div>

    <button
      mat-icon-button
      class="arrow-button"
      style="padding-right: 15px;"
      [ngStyle]="{'visibility': !activity.imgs || activity.imgs?.length === 0 || currentImageIndex === activity.imgs.length - 1 ? 'hidden' : ''}"
      [disabled]="!activity.imgs || activity.imgs?.length === 0 || currentImageIndex === activity.imgs.length - 1"
      (click)="next()"
    >
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>

  </div>

  <div
    fxLayout="column"
    fxLayoutGap="10px"
    style="width: 100%"
  >
    <div
      fxLayout="row" fxLayoutAlign="start start"
      class="no-bt-margin"
    >
      <div
        fxLayout="row wrap" fxLayoutAlign="start start"
      >
        <div
          *ngFor="let detail of activityDetails"
        >
          <div *ngIf="detail.value" class="medium-details">
            <img *ngIf="detail.icon === 'expired'" class="expired-icon" [matTooltip]="detail.value" src="../../../../../assets/img/expired.png"/>
            <mat-icon *ngIf="detail.icon !== 'expired'" class="details-icon">{{ detail.icon }}</mat-icon>
            <span *ngIf="detail.icon !== 'expired'" class="top-row">{{ detail.value }}</span>
            <span *ngIf="detail.delim" class="delim"></span>
          </div>
        </div>

        <div *ngIf="activity.attach && activity.attach.length > 0">
          <mat-icon
            *ngIf="activity.attach.length > 1"
            class="details-icon"
            style="cursor: pointer; color: black;"
            [matMenuTriggerFor]="menu"
            matRipple
          >attach_file</mat-icon>
          <mat-menu #menu="matMenu">
            <div *ngFor="let attach of activity.attach">
              <button
                mat-menu-item
                (click)="downloadFile(attach)"
              >
                <mat-icon
                  style="color: black;"
                >insert_drive_file</mat-icon>
                <span>{{ attach.substring(attach.lastIndexOf('/') + 1) }}</span>
              </button>
            </div>
          </mat-menu>

          <mat-icon
            *ngIf="activity.attach.length === 1"
            class="details-icon"
            style="cursor: pointer; color: black;"
            matRipple
            (click)="downloadFirstAttach()"
          >attach_file</mat-icon>
          <span class="top-row">{{ activity.attach.length }}</span>
          <span *ngIf="computeStoreAddress().length > 0" class="delim"></span>
        </div>

      </div>

      <div
        fxFlex
        fxLayout="column"
        fxLayoutAlign="end end"
        class="top-row-date"
      >
        <div *ngIf="activity.imgs && activity.imgs.length > 0">
          Taken on: {{ getTakenOnDate() }}
        </div>

        <div>
          Updated on: {{ getUpdatedDate(activity.updated) }}
        </div>
      </div>
    </div>

    <div *ngIf="computeStoreAddress().length > 0" class="medium-details">
      <mat-icon class="details-icon"> place </mat-icon>
      <span class="top-row"> {{ computeStoreAddress() }} </span>
    </div>

    <div
      fxLayout="row" fxLayoutAlign="start start"
      class="title"
    >
      {{ activity.title }}
    </div>

    <div
      fxLayout="row" fxLayoutAlign="start start"
      class="desc"
    >
      <span [innerHTML]="activity.desc"></span>
    </div>

    <div
      fxFlex
      fxLayout="row"
    >
      <div
        fxLayout="row" fxLayoutAlign="start start"
        style="min-width: 80%"
      >
        <div
          fxLayout="column"
          style="min-width: 100%"
        >
          <div
            fxLayout="column"
          >
            <div
              class="compliant-row"
              *ngIf="activity.isCompliant === true"
            >
              COMPLETE
            </div>
            <div
              class="non-compliant-row"
              *ngIf="activity.isCompliant === false"
            >
              PENDING
            </div>
            <div
              class="status-row"
              *ngIf="activity.isCompliant !== true && activity.isCompliant !== false"
            >
              {{ getActivityStatus(activity.sta) }}
            </div>

            <div class="compliant-message-container">
              <div class="compliant-message normal">
                {{ selectedResponse?.desc }}
              </div>
            </div>
          </div>

          <div
            class="scroll-view"
          >
            <div
              *ngIf="activity.cmnt"
              class="compliant-message-container italic"
            >
              <div class="compliant-message" *ngIf="selectedResponse?.cmntReq">
                Comment: {{ activity.cmnt }}
              </div>
            </div>

            <div
              *ngIf="exists(activity.actualShprCt)"
              class="compliant-message-container"
            >
              <div class="compliant-message">
                {{ getEnvironment.shipperName }}: {{ activity.actualShprCt }} of {{ activity.shprCt }}
              </div>
            </div>
          </div>
          </div>
      </div>

      <div
        fxFlex
        fxLayout="column"
        fxLayoutAlign="end end"
        fxLayoutGap="5px"
      >
        <button
          *appUserRole="buildGuardParamBasedRole()"
          mat-raised-button
          class="btn-open"
          color="primary"
          (click)="moveToOpen()"
        >
          OPEN
        </button>
        <button
          *appUserRole="buildGuardParamBasedRole()"
          mat-raised-button
          class="btn-complete"
          color="primary"
          (click)="
            reinitializeData();
            getCompliantOrNonCompliantResponses(true, compliantResponsesList);
            openComplianceDialog(compliantResponsesList, 'Y')
          "
        >
          COMPLETE
        </button>
        <button
          *appUserRole="buildGuardParamBasedRole()"
          mat-raised-button
          class="btn-pending"
          color="primary"
          (click)="
            reinitializeData();
            getCompliantOrNonCompliantResponses(false, nonCompliantResponsesList);
            openComplianceDialog(nonCompliantResponsesList, 'N')
          "
        >
          PENDING
        </button>
      </div>
    </div>

  </div>
</div>
