<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayout.xs="column"
  fxLayoutAlign.xs="space-between start"
  class="activity-btns"
>
  <button
    fxFlex="12%;"
    fxFlex.xs="46%"
    mat-button
    [disableRipple]="true"
    (click)="fileInput.click()"
    class="btn-primary activity-btn" type="button"
  >
    <mat-icon>attach_file</mat-icon>Person Responsible File
    <input #fileInput type="file" accept=".xlsx" name="file" id="file"
           class="file-choose-input" (change)="onFileSelected($event)"/>
  </button>
</div>
