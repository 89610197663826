export class GenericLineItemViewConfig {
    public page: string;

    public idField: string;
    public searchForm: any;
    public searchFieldsConfig: any[];
    public displayedColumns;
    public headerValues;
    public abstract: any;
    public dataSource: any;

    constructor() {}
}
