import {NOTIF_CONSTS} from '../../constants/notification-constants';
import {NotificationService} from '../../../core/services/notification.service';
import {MsgBannerService} from '../msg-banner/msg-banner.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivityConstants} from '../../constants/ActivityConstants';
import {GenericLineItemViewConfig} from '../../models/GenericLineItemViewConfig.model';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {GraphService} from '../../../core/graph/graph.service';
import {RestrictedAccessObject} from '../../models/notification/restricted-access-object.model';
import {User} from '../../models/activity/user.model';
import {UserService} from '../../../core/services/user.service';

@Component({
  selector: 'app-generic-line-item-view',
  templateUrl: './generic-line-item-view.component.html',
  styleUrls: ['./generic-line-item-view.component.scss']
})
export class GenericLineItemViewComponent implements OnInit, OnDestroy {
  @ViewChild('territoryMultiSelect', { static: true })
  territoryMultiSelect: MatSelect;
  @ViewChild('chainMultiSelect', { static: true })
  chainMultiSelect: MatSelect;
  public tooltipMessage = 'Select All / Unselect All';

  @ViewChild('userMultiSelect', { static: true }) userMultiSelect: MatSelect;
  public userMultiFilterCtrl: FormControl = new FormControl();
  public filteredUsersMulti: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  disableUsers = true;
  users: User[];

  @Input() config: GenericLineItemViewConfig;
  @Input() loadDataCallback: Function;

  panelOpenState = true;
  panelOpenStateSecond = true;
  isLoading = true;
  searchDisabled = false;
  isLoadingResults = false;
  isError = false;
  resultsLength: number;
  restricted = false;

  showNotification = false;
  messageList = [];

  public chainMultiFilterCtrl: FormControl = new FormControl();
  public filteredChainsMulti: ReplaySubject<RestrictedAccessObject[]> = new ReplaySubject<RestrictedAccessObject[]>(1);
  public territoryMultiFilterCtrl: FormControl = new FormControl();
  public filteredTerritoriesMulti: ReplaySubject<RestrictedAccessObject[]> = new ReplaySubject<RestrictedAccessObject[]>(1);
  protected _onDestroy = new Subject<void>();

  chains: RestrictedAccessObject[] = [];
  territories: RestrictedAccessObject[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns = ActivityConstants.SEARCH.displayedColumns;

  readonly NOTIF_CONSTS = NOTIF_CONSTS;

  notTypes = [
    NOTIF_CONSTS.NOTIF_TYPE.GENERAL,
    NOTIF_CONSTS.NOTIF_TYPE.CHAIN,
    NOTIF_CONSTS.NOTIF_TYPE.TERR
  ];

  constructor(
    private msgBanner: MsgBannerService,
    private userService: UserService,
    public notificationService: NotificationService,
    private graphService: GraphService) {
  }

  ngOnInit(): void {
    this.panelOpenState = true;
    this.config.dataSource.paginator = this.paginator;

    if (this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.GENERAL)) {
      this.isLoading = false;
      this.onSearch();
    }

    if (this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.CHAIN) || this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.TERR)) {
      Promise.all(this.populateDefaultValues()).then(() => {
        if (this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.CHAIN)) {
          this.filteredChainsMulti.next(this.chains.slice(0, 50));
          this.chainMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterChainsMulti();
            });
        } else if (this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.TERR)) {
          this.filteredTerritoriesMulti.next(this.territories.slice(0, 50));
          this.territoryMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterTerritoriesMulti();
            });
        }

        this.setInitialValue();
        this.onSearch();

        this.isLoading = false;
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  populateDefaultValues(): Promise<boolean>[] {
    const promiseList: Promise<boolean>[] = [];
    if (this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.CHAIN)) {
      promiseList.push(
        new Promise((release, reject) => {
          this.notificationService.getRoomsByEmail(this.graphService.user.displayName, null).subscribe(
            (response) => {
              this.chains = response.values;
              this.restricted = response.restricted;
              release(true);
            },
            (error) => {
              reject(error);
            });
        })
      );
    } else if (this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.TERR)) {
      promiseList.push(
        new Promise((release, reject) => {
          this.notificationService.getTerrsByEmail(this.graphService.user.displayName, null).subscribe(
            (response) => {
              this.territories = response.values;
              this.restricted = response.restricted;
              release(true);
            },
            (error) => {
              reject(error);
            });
        })
      );
    }

    return promiseList;
  }

  refresh(existing: any, updated: any) {
    for (let i = 0; i < this.config.dataSource.data.length; i++) {
      if (this.config.dataSource.data[i][this.config.idField] === existing[this.config.idField]) {
        this.config.dataSource.data[i] = updated;
        break;
      }
    }
  }

  toggle(item) {
    for (let i = 0; i < this.config.dataSource.data.length; i++) {
      if (this.config.dataSource.data[i][this.config.idField] === item[this.config.idField]) {
        this.config.dataSource.data[i]['expanded'] = !this.config.dataSource.data[i]['expanded'];
        continue;
      }
      this.config.dataSource.data[i]['expanded'] = false;
    }
  }

  onSearch() {
    this.showNotification = false;
    this.getData();
  }

  onSearchReset() {
    this.config.searchForm.reset();
    this.config.searchForm.controls?.expired.setValue(false);
    this.searchDisabled = false;
    this.disableUsers = true;
    this.onSearch();
  }

  onPageChange(event: PageEvent) {
    this.getData();
  }

  onPageCollapse(item) {
    this.toggle(item);
  }

  getData() {
    if (this.paginator) {
      this.loadData(this.paginator.pageIndex, this.paginator.pageSize);
    } else {
      this.loadData(0, 50);
    }
  }

  loadData(pageIndex?: number, pageSize?: number) {
    this.isLoadingResults = true;
    this.isError = false;

    this.checkRestricted();

    this.loadDataCallback(pageIndex ? pageIndex : 0, pageSize ? pageSize : 50, this.config.searchForm.value).subscribe(
      (response: any) => {
        this.config.dataSource = new MatTableDataSource(response.content);
        this.config.dataSource.data.forEach(item => {
          item['expanded'] = false;
        });

        this.isLoadingResults = false;
        this.resultsLength = response.totalElements;
      }, error => {
        this.config.dataSource = new MatTableDataSource();

        this.isError = false;
        this.resultsLength = 0;

        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  protected setInitialValue() {
    if (this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.CHAIN)) {
      this.filteredChainsMulti
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          if (this.chainMultiSelect) {
            this.chainMultiSelect.compareWith = (a: string, b: string) =>
              a && b && a === b;
          }
        });
    } else if (this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.TERR)) {
      this.filteredTerritoriesMulti
        .pipe(take(1), takeUntil(this._onDestroy))
        .subscribe(() => {
          if (this.territoryMultiSelect) {
            this.territoryMultiSelect.compareWith = (a: string, b: string) =>
              a && b && a === b;
          }
        });
    }
  }

  toggleChainsSelectAll(selectAllValue: boolean) {
    this.filteredChainsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.config.searchForm.controls.chains.patchValue(val.map(chain => chain.value.roomId));
        } else {
          this.config.searchForm.controls.chains.patchValue([]);
        }
      });
  }

  toggleUserSelectAll(selectAllValue: boolean) {
    this.filteredUsersMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.config.searchForm.controls.users.patchValue(val.map(user => user.usrId));
        } else {
          this.config.searchForm.controls.users.patchValue([]);
        }
      });
  }

  protected filterChainsMulti() {
    if (!this.chains) {
      return;
    }
    // get the search keyword
    let search = this.chainMultiFilterCtrl.value;
    if (!search) {
      this.filteredChainsMulti.next(this.chains.slice(0, 50));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredChainsMulti.next(
      this.chains
        .filter(
          (chain) =>
            chain.value.roomName
              .toLowerCase()
              .indexOf(search) > -1
        ).slice(0, 50)
    );
  }

  toggleTerrsSelectAll(selectAllValue: boolean) {
    this.filteredTerritoriesMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.config.searchForm.controls.terrs.patchValue(val.map(terr => terr.value));
        } else {
          this.config.searchForm.controls.terrs.patchValue([]);
        }
      });
  }

  protected filterTerritoriesMulti() {
    if (!this.territories) {
      return;
    }
    // get the search keyword
    let search = this.territoryMultiFilterCtrl.value;
    if (!search) {
      this.filteredTerritoriesMulti.next(this.territories.slice(0, 50));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the territories
    this.filteredTerritoriesMulti.next(
      this.territories
        .filter((territory) => territory.value.toLowerCase().indexOf(search) > -1)
        .slice(0, 50)
    );
  }

  private checkRestricted() {
    if (this.restricted
      && this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.CHAIN)
      && (this.config.searchForm.controls.chains.value === null
        || this.config.searchForm.controls.chains.value.length === 0)) {
      this.config.searchForm.controls.chains.setValue(this.chains.map(chain => chain.value.id));
      this.config.searchForm.controls.chains.updateValueAndValidity();
    }

    if (this.restricted
      && this.config.page.startsWith(NOTIF_CONSTS.NOTIF_TYPE.TERR)
      && (this.config.searchForm.controls.terrs.value === null
        || this.config.searchForm.controls.terrs.value.length === 0)) {
      this.config.searchForm.controls.terrs.setValue(this.territories.map(terr => terr.value));
      this.config.searchForm.controls.terrs.updateValueAndValidity();
    }
  }

  displayError(event: string) {
    if (event) {
      this.messageList = [];
      this.msgBanner.addMsgError(this.messageList, event);
      this.showNotification = true;
    } else {
      this.showNotification = false;
    }
  }

  findUsersByTerritory(terrs: string[]) {
    this.userService.getUsersByTerritory(terrs).subscribe(
      (response) => {
        this.users = response;
        if (response?.length !== 0) {
          response.sort(function(user1, user2) {
            if (user1.nam < user2.nam) return -1;
            if (user1.nam > user2.nam) return 1;
            return 0;
          });
          this.initUserDropDownList();
        }
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  private initUserDropDownList() {
    this.filteredUsersMulti.next(this.users);

    this.userMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsersMulti();
      });
  }

  protected filterUsersMulti() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userMultiFilterCtrl.value;
    if (!search) {
      this.filteredUsersMulti.next(this.users);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the territories
    this.filteredUsersMulti.next(
      this.users.filter((user) =>
        user.usrId.toLowerCase().indexOf(search) > -1
        || user.nam.toLowerCase().indexOf(search) > -1)
    );
  }

  onSelectionChange(event: MatSelectChange) {
    const terrs: string[] = Array.from(this.config.searchForm.controls.terrs.value);

    if (terrs && terrs.length === 0) {
      this.config.searchForm.controls.users.setValue(null);
      this.disableUsers = true;
      return;
    }

    this.disableUsers = false;
    this.findUsersByTerritory(terrs);
  }
}
