export enum AG_ROLE {
  UK_MERCH_ADMIN = "Merchandiser Admin",
  UK_CUST_OPS = "Customer Ops(SAM team)",
  UK_ACCT_MANAGER = "Account Business Managers",
  UK_TERR_MANAGER = "Territory Manager",
  JS_MERCH_ADMIN = 'Merch Admin',
  JS_IT = 'IT',
  JS_MERCHANDISER_MANAGERS = 'Merchandiser Managers',
  JS_ACCT_MANAGER = 'Account Manager'
}
