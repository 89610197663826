import { AbstractNotification } from '../../../../shared/models/notification/abstract-notification.model';
import { NOTIF_CONSTS } from '../../../../shared/constants/notification-constants';
import { Dialog } from '../../../../shared/models/dialog';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../../../core/services/notification.service';
import { Component, OnInit, Input, NgModule, Output, EventEmitter } from '@angular/core';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {Chain} from '../../../../shared/models/chain';
import {AttachmentView} from '../../../../shared/models/attachment.config';
import {AttachmentService} from '../../../../core/services/attachment.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Room} from '../../../../shared/models/room.model';

@Component({
  selector: 'app-abstract-notification',
  templateUrl: './abstract-notification.component.html',
  styleUrls: [
      '../../../../shared/components/generic-line-item-view/generic-line-item-view.component.scss',
      './abstract-notification.component.scss'
    ]
})
export class AbstractNotificationComponent implements OnInit {

  @Input() item: AbstractNotification;
  @Input() type;
  @Output() editItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() errorEmitter: EventEmitter<string> = new EventEmitter<string>();

  readonly NOTIF_CONSTS = NOTIF_CONSTS;

  constructor(
              private notificationService: NotificationService,
              private attachmentService: AttachmentService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onEditItem() {
    this.editItemEmitter.emit(this.item);
  }

  onDeteleItemCall() {
    console.log(this.item);
    this.notificationService.deleteNotificationById(this.item.id).subscribe(
      (response) => {
        this.deleteItemEmitter.emit(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onDeleteItem() {
    this.openConfirmationDialog('Are you sure you want to delete this notification?', true, true);
  }

  onOpenAttachment() {
    console.log('Attachment: ', this.item.attach);
  }

  openConfirmationDialog(data, showYesButton, showNoButton): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog(data, showYesButton, false, showNoButton),
      disableClose: true,
    });
    dialogRef.beforeClosed().subscribe((result) => {
      console.log('Dialog closed ', result);
      if (result === true) {
        this.onDeteleItemCall();
      }
    });
  }

  chainAsString(chains: Room[]) {
    return chains?.map(ch => ch.roomName).join(', ');
  }

  usersAsString(users: String[]) {
    return users?.join(', ');
  }

  downloadFile(attach: string) {
    this.errorEmitter.emit(null);
    if (attach) {
      if (attach.startsWith('http')) {
        window.open(attach);
      } else {
        this.download(attach);
      }
    }
  }

  download(attach: string) {
    const attachView = this.item.terr &&
      !attach.startsWith('TERRITORY/') && !attach.startsWith('CHAIN/') && !attach.startsWith('GENERAL/') ?
      AttachmentView.NOTIFICATION_TERRITORY.valueOf() : AttachmentView.NOTIFICATION.valueOf();
    this.attachmentService.downloadFile(attach, attachView).subscribe(
      response => {
        this.attachmentService.download(response);
      }, error => {
        let errorText = '';
        if (error.status === 400) {
          errorText = error.error.message;
        } else if (error.status === 404) {
          errorText = 'File not found. Please contact your administrator.';
        } else {
          errorText = 'An error has occurred. Please contact your administrator.';
        }
        this.snackBar.open(errorText, 'Close', {
          duration: 10 * 1000
        });
      }
    );
  }
}
