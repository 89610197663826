<div
  class="wrapper"
>

  <mat-card class="mat-elevation-z2">

    <app-generic-file-structure
      *ngIf="!showUpload"
      [config]="buildConfig()"
      (uploadEmitter)="uploadView($event)"
      [uploadPath]="uploadPath"
      (fileStructureState)="saveFileStructureState($event)"
      [fileStructure]="nodes"
    ></app-generic-file-structure>

    <app-generic-upload-file
      *ngIf="showUpload"
      [config]="buildConfig()"
      [uploadPath]="uploadPath"
      (fileEmitter)="fileView($event)"
    ></app-generic-upload-file>
  </mat-card>
</div>
