import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ActivityComplianceService} from '../../../../core/services/activity-compliance.service';
import {ActivityQuickSearchService} from '../../../../core/services/activity-quick-search.service';
import {ChainGroupModel} from '../../../../shared/models/activity/chain-group.model';
import {QuickSearchModel} from '../../../../shared/models/activity/quick-search.model';
import {MatTableDataSource} from '@angular/material/table';
import {formatDate} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminService} from '../../../../core/services/admin.service';
import {GraphService} from '../../../../core/graph/graph.service';
import { MsgBannerService } from '../../../../shared/components/msg-banner/msg-banner.service';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-activity-quick-search',
  templateUrl: './activity-quick-search.component.html',
  styleUrls: ['./activity-quick-search.component.scss']
})
export class ActivityQuickSearchComponent implements OnInit {
  dataSource = new MatTableDataSource<QuickSearchModel>();
  chainGroups: ChainGroupModel[] = [];
  activityTitles: QuickSearchModel[] = [];
  dateTime: string;

  formSearch: FormGroup;
  formData: any;

  contentEditable: Boolean = false;
  messageList = [];
  showNotification = false;
  notFound = false;

  @ViewChild('terrMultiSelect', { static: true }) terrMultiSelect: MatSelect;
  public terrMultiFilterCtrl: FormControl = new FormControl();
  public filteredTerrsMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  terrRestricted = false;
  terrs: string[] = [];
  public tooltipMessage = 'Select All / Unselect All';

  protected _onDestroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private graphService: GraphService,
    private activityQuickSearchService: ActivityQuickSearchService,
    private activityComplianceService: ActivityComplianceService,
    private router: Router,
    private msgBanner: MsgBannerService
  ) {
    this.createSearchForm();
  }

  ngOnInit(): void {
    if (history.state.data) {
      this.formData = history.state.data;
    }

    Promise.all(this.getDefaultValues()).then(() => {
      this.getRestrictedChains(this.graphService.user.displayName);
      this.createSearchForm();
      this.getSavedOptions();
    });

    this.dateTime = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
  }

  getRestrictedChains(displayName: string) {
    this.adminService.getChainsByEmail(displayName).subscribe(
      response => {
        const chains = response;
        if (chains && chains.length > 0) {
          this.chainGroups.forEach(grp => {
            const chainArray = JSON.parse(JSON.stringify(grp.chains));
            for (let i = 0; i < chainArray.length; i++) {
              const idx = chains.findIndex(chn => chn.chainId === chainArray[i]);
              if (idx === -1) {
                grp.chains.splice(i, 1);
              }
            }
          });
        }
      }, error => {
        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  getDefaultValues(): Promise<boolean>[] {
    this.showNotification = false;
    const promiseList: Promise<boolean>[] = [];

    promiseList.push(
      new Promise((release, reject) => {
        this.adminService.getTerrsByEmail(this.graphService.user.displayName).subscribe(
          response => {
            this.terrs = response;
            if (response.length === 0) {
              this.terrRestricted = false;
              this.formSearch.controls.territory.setValidators([]);
              this.formSearch.controls.territory.updateValueAndValidity();
            } else {
              this.terrRestricted = true;
              this.formSearch.controls.territory.setValidators(Validators.required);
              this.formSearch.controls.territory.updateValueAndValidity();
              this.initTerrDropdown();
            }
            release(true);
          }, error => {
            if (error.status === 404) {
              this.msgBanner.addMsgError(this.messageList, error.error.message);
            } else {
              this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            }
            this.showNotification = true;
            reject(false);
          }
        );
      })
    );

    promiseList.push(
      new Promise((release, reject) => {
        this.adminService.getChainGroupsByEmail(this.graphService.user.displayName).subscribe(
          (res: ChainGroupModel[]) => {
            this.chainGroups = res;
            release(true);
          }, error => {
            this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            this.showNotification = true;
            reject(false);
          });
      })
    );

    return promiseList;
  }

  getSavedOptions() {
    const terrs = JSON.parse(localStorage.getItem('terrs'));
    if (terrs && terrs?.length > 0) {
      const terrForm: string[] = [];
      terrs.forEach(t => {
        if (this.terrs.includes(t)) {
          terrForm.push(t);
        }
      });

      if (terrForm.length > 0) {
        this.formSearch.controls.territory.setValue(terrForm);
        this.formSearch.controls.territory.updateValueAndValidity();
      }
    }
  }

  initTerrDropdown() {
    if (this.terrRestricted) {
      this.filteredTerrsMulti.next(this.terrs);
      this.terrMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterTerrsMulti();
        });
    }
  }

  protected filterTerrsMulti() {
    if (!this.terrs) {
      return;
    }
    // get the search keyword
    let search = this.terrMultiFilterCtrl.value;
    if (!search) {
      this.filteredTerrsMulti.next(this.terrs);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the terrs
    this.filteredTerrsMulti.next(
      this.terrs
        .filter((territory) => territory.toLowerCase().indexOf(search) > -1)
    );
  }

  toggleTerrsSelectAll(selectAllValue: boolean) {
    this.filteredTerrsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.formSearch.controls.territory.patchValue(val);
        } else {
          this.formSearch.controls.territory.patchValue([]);
        }
      });
  }

  initRestrictedFilters() {
    if (this.terrRestricted && (!this.formSearch.controls.territory.value || this.formSearch.controls.territory.value?.length === 0)) {
      this.formSearch.controls.territory.setValue(this.terrs);
    }
  }

  getTitles() {
    this.activityTitles = [];
    this.showNotification = false;
    this.messageList = [];
    this.notFound = false;

    this.initRestrictedFilters();
    this.cacheTerritory();

    this.activityQuickSearchService.getTitles(
      this.formSearch.controls.chainGroup.value.groupId,
      this.formSearch.controls.chainGroup.value.chains,
      this.formSearch.controls.territory.value,
      this.formSearch.controls.expired.value
    ).subscribe(
      res => {
          res.forEach(r => {
            if (this.dateTime >= formatDate(r.start, 'yyyy-MM-dd', 'en_US').toString()
              && this.dateTime <= formatDate(r.exp, 'yyyy-MM-dd', 'en_US').toString()) {
              r.color = 'green';
              this.activityTitles.push(r);
            } else if (this.dateTime < formatDate(r.start, 'yyyy-MM-dd', 'en_US').toString()) {
              r.color = 'orange';
              this.activityTitles.push(r);
            } else if (this.dateTime > formatDate(r.exp, 'yyyy-MM-dd', 'en_US').toString()) {
              r.color = 'red';
              this.activityTitles.push(r);
            }
          });

          this.sortTitles();
      }, (error) => {
        if (error.status === 404) {
          this.notFound = true;
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  sortTitles() {
    const green: QuickSearchModel[] = this.activityTitles.filter(a => a.color === 'green');
    green?.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());

    const orange: QuickSearchModel[] = this.activityTitles.filter(a => a.color === 'orange');
    orange?.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());

    const red: QuickSearchModel[] = this.activityTitles.filter(a => a.color === 'red');
    red?.sort((a, b) => new Date(b.exp).getTime() - new Date(a.exp).getTime());

    this.activityTitles = green.concat(orange).concat(red);
  }

  onSearchReset() {
    this.formSearch.reset();
    this.activityTitles = [];
    this.formSearch.controls.expired.setValue(false);
  }

  cacheTerritory() {
    const terrs = this.formSearch.controls.territory.value;
    localStorage.setItem('terrs', JSON.stringify(terrs));
  }

  getColor() {
    this.activityTitles.forEach((r) => {
      if (this.dateTime >= formatDate(r.start, 'yyyy-MM-dd', 'en_US').toString()
        && this.dateTime <= formatDate(r.exp, 'yyyy-MM-dd', 'en_US').toString()) {
        r.color = 'green';
      } else if (this.dateTime < formatDate(r.start, 'yyyy-MM-dd', 'en_US').toString()) {
        r.color = 'orange';
      } else if (this.dateTime > formatDate(r.exp, 'yyyy-MM-dd', 'en_US').toString()) {
        r.color = 'red';
      }
    });
    return this.activityTitles.sort((a, b) =>
      0 - (a.color > b.color ? -1 : 1)
    );
  }

  private createSearchForm(): void {
    if (this.formData) {
      let prevChainGrpId = this.formData.chainGrpId;
      if (prevChainGrpId) {
        this.formSearch.controls.chainGroup.setValue(this.chainGroups.find(chainGrp => chainGrp.groupId === prevChainGrpId));
      }
      this.formSearch.controls.expired.setValue(this.formData.expired);
      this.formSearch.controls.territory.setValue(this.formData.terr);
      this.getTitles();
    } else {
      this.formSearch = this.fb.group({
        chainGroup: '',
        territory: '',
        expired: false
      });
    }
  }

  clickAdvancedButton() {
    const data = {
      route: 'advanced'
    };
    this.router.navigate(['activity/compliance'], {state: { data }});
  }

  clickCard(q: any) {
    const data = {
      title: q.title,
      chainGrpId: this.formSearch.controls.chainGroup.value.groupId,
      terr: this.formSearch.controls.territory.value,
      expired: this.formSearch.controls.expired.value,
      exact: true,
      route: 'quick'
    };
    this.router.navigate(['/activity/compliance'], {state: {data}});
  }
}
