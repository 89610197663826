<div class="wrapper">
    <app-msg-banner
        *ngIf="showNotification"
        [msgList]="messageList"
        (msgListChange)="messageList = $event"
    ></app-msg-banner>
  
    <mat-card class="mat-elevation-z2">
        <div
            fxLayout="column"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 20px; margin-top: 20px;">

            <button
                mat-raised-button
                (click)="syncTerritoryChanges()"
                class="sync-button">
                <mat-icon class="sync-icon">sync</mat-icon>
            </button>

            <p style="font-size: 18px;"> {{ terrChangesMessage }} </p>
        </div>

    </mat-card>

</div>
