import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexFill,
  ApexLegend,
  ApexYAxis,
  ApexGrid,
} from 'ng-apexcharts';
import { ResponseCompliance } from '../../../../shared/models/activity/response-compliance.model';
import {ActivityMetrics} from '../../../../shared/models/activity/activity-metrics.model';
import {ActivityComplianceService} from '../../../../core/services/activity-compliance.service';
import {ActivityStatusEnum} from '../../../../shared/models/activity/activity-status.model';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  colors: Array<string>;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
  grid: ApexGrid;
}

@Component({
  selector: 'app-metrics-dialog',
  templateUrl: './metrics-dialog.component.html',
  styleUrls: ['./metrics-dialog.component.scss'],
})
export class MetricsDialogComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  activityDetails: FormGroup;
  displayedColumns = ['compliance', 'message', 'count', 'percent'];
  dataSource: MatTableDataSource<ResponseCompliance>;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  completeResponsesCount = 0;
  pendingResponsesCount = 0;
  activityGroups: Set<string> = new Set();

  metrics: ActivityMetrics[] = [];
  isLoading = false;

  showNotification = false;
  messageList = [];

  constructor(
    public dialogRef: MatDialogRef<MetricsDialogComponent>,
    public fb: FormBuilder,
    private msgBanner: MsgBannerService,
    private activityComplianceService: ActivityComplianceService,
    @Inject(MAT_DIALOG_DATA) public complianceFormData: any
  ) {
    this.activityDetails = this.fb.group({
      numberOfActivities: [ { value: 0, disabled: true }, [], [] ],
      completeResponsesCount: [ { value: 0, disabled: true }, [], [] ],
      pendingResponsesCount: [ { value: 0, disabled: true }, [], [] ],
      unansweredActivities: [ { value: 0, disabled: true }, [], [] ],
    });
  }

  getNameForResponse(metric: ActivityMetrics, response: string): string {
    let name = '';
    metric.resps.forEach((activityResponse) => {
      if (response.localeCompare(activityResponse.respId) === 0) {
        name = activityResponse.desc;
      }
    });

    return name;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.showNotification = false;
    this.activityComplianceService.getAllActivities(this.complianceFormData, true).subscribe(
      response => {
        this.metrics = response;
        this.init();

        this.isLoading = false;
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
        this.isLoading = false;
      }
    );
  }

  init() {
    this.metrics.forEach((activity) => {
      if (activity.sta === ActivityStatusEnum.COMPLETE.valueOf()) {
        this.completeResponsesCount++;
      } else if (activity.sta === ActivityStatusEnum.PENDING.valueOf()) {
        this.pendingResponsesCount++;
      }
      console.log('cra');
      if (!this.activityGroups.has(activity.actGrp)) {
        this.activityGroups.add(activity.actGrp);
      }
    });

    this.chartOptions = {
      dataLabels: {
        style: {
          fontSize: "17px"
        }
      },
      legend: {
        fontSize: "15px"
      },
      series: [
        {
          name: 'Complete',
          data: [this.completeResponsesCount],
        },
        {
          name: 'Pending',
          data: [this.pendingResponsesCount],
        },
        {
          name: 'Unanswered',
          data: [
            this.metrics.length -
            this.completeResponsesCount -
            this.pendingResponsesCount,
          ],
        },
      ],
      chart: {
        type: 'bar',
        height: 125,
        stacked: true,
        stackType: '100%',
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ['#5cb85c', '#d9534f', '#808080'],
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return '' + val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      xaxis: {
        labels: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        showAlways: false,
        labels: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
    };

    this.activityDetails = this.fb.group({
      numberOfActivities: [
        { value: this.metrics.length, disabled: true },
        [],
        [],
      ],
      completeResponsesCount: [
        { value: this.completeResponsesCount, disabled: true },
        [],
        [],
      ],
      pendingResponsesCount: [
        { value: this.pendingResponsesCount, disabled: true },
        [],
        [],
      ],
      unansweredActivities: [
        {
          value:
            this.metrics.length -
            this.completeResponsesCount -
            this.pendingResponsesCount,
          disabled: true,
        },
        [],
        [],
      ],
    });

    if (this.activityGroups.size === 1) {
      const responsesMap: Map<string, number> = new Map();

      this.metrics.forEach((activity) => {
        if (activity.resp) {
          const responseName = this.getNameForResponse(activity, activity.resp);
          if (responsesMap.has(responseName)) {
            responsesMap.set(responseName, responsesMap.get(responseName) + 1);
          } else {
            responsesMap.set(responseName, 1);
          }
        }
      });

      const responses: ResponseCompliance[] = [];
      this.metrics[0].resps.forEach((response) => {
        let count = responsesMap.get(response.desc);
        count = count ? count : 0;
        responses.push({
          compliance: response.complInd ? 'Y' : 'N',
          message: response.desc,
          count: count,
          percent:
            ((count * 100) / this.metrics.length).toFixed(2),
        });
      });

      this.dataSource = new MatTableDataSource<ResponseCompliance>(responses);
      this.dataSource.sort = this.sort;
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
