import {Component, OnInit, ViewChild} from '@angular/core';
import {MsgBannerService} from '../../../shared/components/msg-banner/msg-banner.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {DefaultResponse} from '../../../shared/models/activity/default-response.model';
import {AdminService} from '../../../core/services/admin.service';
import {MatDialog} from '@angular/material/dialog';
import {ResponseDialogComponent} from '../response-dialog/response-dialog.component';
import {copyArrayItem} from '@angular/cdk/drag-drop';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../../shared/models/dialog';

@Component({
  selector: 'app-default-response',
  templateUrl: './manage-response.component.html',
  styleUrls: ['./manage-response.component.scss']
})
export class ManageResponseComponent implements OnInit {
  // error list
  messageList = [];
  showNotification = false;

  searchValue: string = null;

  dataSource = new MatTableDataSource<DefaultResponse>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  responses: DefaultResponse[] = [];
  resultsLength: number;

  isError = false;
  show = false;

  constructor(private msgBanner: MsgBannerService,
              private dialog: MatDialog,
              private adminService: AdminService) {
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;

    this.getData();
  }

  toggle(item) {
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].respGroupId === item.respGroupId) {
        this.dataSource.data[i]['expanded'] = !this.dataSource.data[i]['expanded'];
        continue;
      }
      this.dataSource.data[i]['expanded'] = false;
    }
    this.show = true;
  }

  getData() {
    if (this.paginator) {
      this.loadData(this.paginator.pageIndex, this.paginator.pageSize);
    } else {
      this.loadData(0, 50);
    }
  }

  onPageChange(event: PageEvent) {
    this.loadData(event.pageIndex, event.pageSize);
  }

  loadData(pageIndex?: number, pageSize?: number) {
    this.isError = false;
    this.responses = [];
    let findByTitle: string = this.searchValue ? this.searchValue : null;

    this.adminService.findAllDefaultResponses(pageIndex, pageSize, findByTitle).subscribe(
      (response: any) => {
        this.responses = JSON.parse(JSON.stringify(response.content));
        this.dataSource = new MatTableDataSource<DefaultResponse>(response.content);
        this.dataSource.data.forEach(item => {
          item['expanded'] = false;
        });

        this.resultsLength = response.totalElements;
      }, error => {
        this.dataSource = new MatTableDataSource();

        this.isError = false;
        this.resultsLength = 0;

        if (error.status === 404) {
          this.msgBanner.addMsgError(this.messageList, error.error);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  buildConfig(item) {
    const data = [];
    for (let i = 0; i < item.responses.length; i++) {
      copyArrayItem(item.responses, data, i, i);
    }
    return {
      updatable: true,
      disableActions: false,
      data: data
    };
  }

  buildObject(item: DefaultResponse) {
    return {
      respGroupId: item.respGroupId,
      title: item.title
    };
  }

  openResponseDialog() {
    const dialogRef = this.dialog.open(ResponseDialogComponent, {
      data: {
        updatable: true,
        disableActions: false,
        data: null
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchValue = null;
        this.getData();
      }
    });
  }

  onCancel(result: DefaultResponse, child) {
    if (result) {
      this.dataSource.data.find(r => r.respGroupId === result.respGroupId)['expanded'] = false;
      this.resetChild(child, result);
    }
  }

  resetChild(child, item) {
    child.config = this.buildConfig(item);
    child.object = this.buildObject(item);
    child.reset();
  }

  onUpdate(result: DefaultResponse) {
    if (result) {
      this.refreshDatasource(result);
    }
  }

  refreshDatasource(updated: DefaultResponse) {
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].respGroupId === updated.respGroupId) {
        this.dataSource.data[i] = updated;
        this.dataSource.data[i]['expanded'] = false;
        break;
      }
    }
    this.responses = this.dataSource.data;
  }

  private removeFromDataSource(deleted: string) {
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].respGroupId === deleted) {
        this.dataSource.data.splice(i, 1);
        break;
      }
    }
  }

  delete(item: DefaultResponse) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog('Do you want to delete this response set?', true, false, true),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.adminService.delete(item).subscribe(
          response => {
            if (response) {
              this.removeFromDataSource(response);
            }
          }, error => {
            this.showNotification = true;
            this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
          }
        );
      }
    });
  }
}
