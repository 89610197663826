import {Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, Testability, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {ActivityTypes} from '../../constants/ActivityTypes';
import {ActivityConstants} from '../../constants/ActivityConstants';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../models/dialog';
import {MatDialog} from '@angular/material/dialog';
import {LoadStoreComponent} from '../load-store/load-store.component';
import {Chain, ChainGroupModel} from '../../models/chain';
import {ChooseFile} from '../../models/activity/choose-file.model';
import {ReaderService} from '../../../core/services/reader.service';
import {AdminService} from '../../../core/services/admin.service';
import {GraphService} from '../../../core/graph/graph.service';
import {NewActivityService} from '../../../core/services/new-activity.service';
import {Customer} from '../../models/activity/customer.model';
import {StoreContainerConfig} from '../../models/activity/store-container.config';
import {environment} from '../../../../environments/environment';
import { LoadStoreChainComponent } from '../load-store-chain/load-store-chain.component';

@Component({
  selector: 'app-store-container',
  templateUrl: './store-container.component.html',
  styleUrls: ['./store-container.component.scss']
})
export class StoreContainerComponent implements OnInit {

  @Input() config: StoreContainerConfig;
  @Output() errorEmitter = new EventEmitter<string>();

  @ViewChild('fileInput') fileInput: ElementRef;
  private fileSelected: File = null;
  private storesFromFile: ChooseFile[] = [];

  dataSource = new MatTableDataSource<Customer>();
  selection = new SelectionModel<Customer>(true, []);
  displayedColumns: string[] = ActivityConstants.STORES_FIND.displayedColumns;

  chains: Chain[] = [];
  chainGroup: ChainGroupModel = null;

  shipperCtChanges: Map<string, {chain: string, str: string, shprCt: number}> = 
    new Map<string, {chain: string, str: string, shprCt: number}>();

  constructor(private dialog: MatDialog,
              private readerService: ReaderService,
              private adminService: AdminService,
              private graphService: GraphService,
              private newActivityService: NewActivityService) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Customer>(this.config.data);
    if (this.config.activityType === ActivityTypes.SHIPPER_COUNT) {
      this.displayedColumns = this.displayedColumns.concat('shipperCount');
    }

    this.getChainGroupById();
    this.getChains(this.graphService.user.displayName);
  }

  change(input, customer: Customer) {
    var updatedShipperCt: number = input;

    if (this.shipperCtChanges.has(customer.fullAcct)) {
        var existing = this.shipperCtChanges.get(customer.fullAcct);
        existing.shprCt = updatedShipperCt;
        this.shipperCtChanges.set(customer.fullAcct, existing);
    } else {
      this.shipperCtChanges.set(customer.fullAcct, {
        chain: customer.chain, 
        str: customer.str, 
        shprCt: updatedShipperCt});
    }
  }

  private getChains(displayName: string) {
    this.adminService.getChainsByEmail(displayName).subscribe(
      response => {
        this.chains = response;
      }, error => {
        let errorMsg = '';
        if (error.status === 404) {
          errorMsg = error.error.message;
        } else {
          errorMsg = 'An error has occurred. Please contact your administrator!';
        }
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '450px',
          data: new Dialog(errorMsg, false, false),
          disableClose: true,
        });
      }
    );
  }

  disableRow(row: Customer) {
    if (this.config.page != 'manage-group')
      return false;

    var hasChainAccess = this.chains.map(c => c.chainId).includes(row.chain) === true || this.chains.length < 1;
    return !hasChainAccess;
  }

  onFileSelected(event) {
    this.fileSelected = <File>event.target.files[0];
    if (this.fileNameExtensionIsValid(this.fileSelected.name) === 0) {
      this.readFromFile();
    } else if (this.fileNameExtensionIsValid(this.fileSelected.name) === 1) {
      this.readFromXlsxFile(event);
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: new Dialog('The file you loaded does not have txt or xlsx extension.', false, false),
        disableClose: true,
      });
    }
  }

  fileNameExtensionIsValid(fileName): number {
    if (fileName.substring(fileName.length - 4, fileName.length) === '.txt') {
      return 0;
    } else if (
      fileName.substring(
        this.fileSelected.name.length - 5,
        this.fileSelected.name.length
      ) === '.xlsx'
    ) {
      return 1;
    }
    return 2;
  }

  readFromFile(): void {
    this.readerService.readFromFile(this.fileSelected).then(response => {
      this.storesFromFile = response;
      this.loadStoresFromFile(this.storesFromFile);
    });
  }

  readFromXlsxFile(event): void {
    this.readerService.readFromXlsxFile(event).then(result => {
      this.storesFromFile = result;
      this.loadStoresFromFile(this.storesFromFile);
    });
  }

  loadStoresFromFile(data: ChooseFile[]) {
    const ids: string[] = [];
    var filtered_ids: string[] = [];
    const restricted: Set<string> = new Set<string>();
    const chainNumbers: string[] = this.chains.map(c => c.chainId);

    if (chainNumbers && chainNumbers.length > 0) {
      for (const store of data) {
        const existingCustomer = this.dataSource.data.find(s => s.fullAcct === store.fullAccount);
        if (!existingCustomer) {
          if (chainNumbers.includes(store.fullAccount.substring(0, 5))) {
            ids.push(store.fullAccount);
            continue;
          }
          restricted.add(store.fullAccount.substring(0, 5));
        } else {
          existingCustomer.shprCt = store.shipper;
        }
      }
    } else {
      for (const store of data) {
        const existingCustomer = this.dataSource.data.find(s => s.fullAcct === store.fullAccount);
        if (!existingCustomer) {
          ids.push(store.fullAccount);
        } else {
          existingCustomer.shprCt = store.shipper;
        }
      }
    }

    if (restricted.size > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: new Dialog('You don\'t have access to add stores for the following chain(s): ' +
          Array.from(restricted).toString(), false, false),
        disableClose: true,
      });
      this.fileInput.nativeElement.value = '';
    }

    if (ids.length === 0) {
      this.fileInput.nativeElement.value = '';
      return;
    }

    // filter ids based on selected chain group
    if (this.chainGroup) {

      var usrChains: string[] = this.chains.length > 0 ?
            this.chainGroup.chains.filter(chain => this.chains.map(c => c.chainId).includes(chain)) :
            this.chainGroup.chains;
      filtered_ids = ids.filter(e => {
        return usrChains.includes(e.substring(0, 5))});
    }

    this.newActivityService.loadStoresFromFile(filtered_ids).subscribe(
      result => {
        const existingStores: Customer[] = this.dataSource.data;
        for (const res of result) {
          this.config.page === 'manage-group' ? (res.isNew = true) : null;
          existingStores.push(res);
        }
        this.dataSource = new MatTableDataSource<Customer>(existingStores);

        for (const store of this.dataSource.data) {
          const index = data.findIndex(d => d.fullAccount === store.fullAcct);
          if (index !== -1) {
            store.shprCt = data[index].shipper;
          }
        }

        this.fileInput.nativeElement.value = '';
      }, error => {
        if (error.status === 404) {
          this.errorEmitter.emit(error.error.message);
        } else {
          this.errorEmitter.emit('An error has occurred. Please contact your administrator!');
        }
        this.fileInput.nativeElement.value = '';
      }
    );
    this.fileInput.nativeElement.value = '';
  }

  getShipperCountValue() {
    return ActivityTypes.SHIPPER_COUNT;
  }

  selectChainGroup(callback: Function) {
    // reset chain Group if needed
    if (this.dataSource.data.length == 0)
      this.chainGroup = null;

    if (this.chainGroup) {
      callback();
      return;
    }

    const dialogRef = this.dialog.open(LoadStoreChainComponent, null);
    dialogRef.afterClosed().subscribe((result: ChainGroupModel) => {
      if (!result)
        return 

      this.chainGroup = result;
      callback();
    });
  }

  selectChainGroupForStoreFile() {
    var callback = () : void => {
      this.fileInput.nativeElement.click();
    }
    this.selectChainGroup(callback);
  }

  selectChainGroupForLoadStore() {
    var callback = () : void => {
      this.openLoadStoreByChainGroup();
    }
    this.selectChainGroup(callback);
  }

  openLoadStoreByChainGroup() {
    const stores: string[] = [];
    for (const store of this.dataSource.data) {
      stores.push(store.fullAcct);
    }

    const dialogRef = this.dialog.open(LoadStoreComponent, {
      data: {
        stores: stores,
        chainGroup: this.chainGroup
      }
    });

    dialogRef.afterClosed().subscribe((result: Array<Customer>) => {
      if (result) {
        const existingStores: Customer[] = this.dataSource.data;
        for (const res of result) {
          this.config.page === 'manage-group' ? (res.isNew = true) : null;
          existingStores.push(res);
        }
        this.dataSource = new MatTableDataSource<Customer>(existingStores);
      }
    });
  }

  verifyIfRemoveIsPossible() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: new Dialog('Do you want to remove the selected store(s)?', true, false, true),
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeCheckedElements();
      }
    });
  }

  removeCheckedElements() {
    const dataArray = this.dataSource.data;
    this.selection.selected.forEach((item) => {
      const index: number = dataArray.findIndex((d) => d === item);
      dataArray.splice(index, 1);
    });
    this.dataSource = new MatTableDataSource<Customer>(dataArray);
    this.selection = new SelectionModel<Customer>(true, []);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;

    const numRowsMinusExcluded = this.dataSource.data
      .filter(row => !this.disableRow(row))
      .length;

    return numSelected === numRowsMinusExcluded;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      // this.dataSource.data.forEach(row => this.selection.select(row));

      this.dataSource.data.forEach(row => {
        if (!this.disableRow(row)) {
          this.selection.select(row);
        }
      });

  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Customer): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  /** Keep the state of the checkbox when page changed */
  isChecked(row: any): boolean {
    const found = this.selection.selected.find(el => el.fullAcct === row.fullAccount);
    return !!found;
  }

  get getEnvironment() {
    return environment;
  }

  getChainGroupById() {
    if (this.config.chainGroupId === undefined ||
        this.config.chainGroupId === null || this.config.chainGroupId === "")
      return
    
    this.newActivityService.getChainGroupById(this.config.chainGroupId).subscribe(
      (response : ChainGroupModel) => {
        this.chainGroup = response;
      },
      (error) => {
        this.chainGroup = null;
      }
    );
  }
}
