import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {PublishNotification} from '../../../../shared/models/notification/publish-notification.model';
import {NOTIF_CONSTS} from '../../../../shared/constants/notification-constants';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {PublishDialogComponent} from '../publish-dialog/publish-dialog.component';
import {ConfirmationDialogComponent} from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../../../shared/models/dialog';
import {CounterService} from '../../../../core/services/counter.service';
import {ReplaySubject, Subject} from 'rxjs';
import {RestrictedAccessObject} from '../../../../shared/models/notification/restricted-access-object.model';
import {take, takeUntil} from 'rxjs/operators';
import {GraphService} from '../../../../core/graph/graph.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-publish-notification',
  templateUrl: './publish-notification.component.html',
  styleUrls: ['./publish-notification.component.scss']
})
export class PublishNotificationComponent implements OnInit, AfterViewInit, OnDestroy {

  isLoading = true;
  isLoadingResults = false;
  isError = false;
  searchDisabled = false;
  resultsLength: number;

  showNotification = false;
  messageList = [];

  searchForm: FormGroup;
  dataSource = new MatTableDataSource<PublishNotification>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns = NOTIF_CONSTS.PUBLISH_HEADER.displayedColumns;

  notificationTypes = [];

  selection = new SelectionModel<PublishNotification>(true, []);

  public chainMultiFilterCtrl: FormControl = new FormControl();
  public filteredChainsMulti: ReplaySubject<RestrictedAccessObject[]> = new ReplaySubject<RestrictedAccessObject[]>(1);
  protected _onDestroy = new Subject<void>();
  public tooltipMessage = 'Select All / Unselect All';

  chains: RestrictedAccessObject[] = [];
  restricted = false;

  NOTIF_CONSTS = NOTIF_CONSTS;

  constructor(private msgBanner: MsgBannerService,
              private fb: FormBuilder,
              private counterService: CounterService,
              private graphService: GraphService,
              private dialog: MatDialog,
              public notificationService: NotificationService) {
    this.searchForm = this.fb.group({
      title: [null, Validators.pattern(environment.emojiRegEx), []],
      notType: [null, [], []],
      chains: [null, [], []],
      startNotificationDate: [null, Validators.pattern(environment.emojiRegEx)],
      endNotificationDate: [null, Validators.pattern(environment.emojiRegEx)]
    });
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.isLoading = false;

    Promise.all(this.populateDefaultValues()).then(() => {
      this.onSearch();
    });
  }

  ngAfterViewInit() {
    this.getData();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  populateDefaultValues(): Promise<boolean>[] {
    const promiseList: Promise<boolean>[] = [];
    promiseList.push(
      new Promise((release, reject) => {
        this.notificationService.getRoomsByEmail(this.graphService.user.displayName, null).subscribe(
          (response) => {
            this.chains = response.values;
            this.restricted = response.restricted;
            release(true);
            this.filterChains();
          }, (error) => {
            this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
            this.showNotification = true;
            reject(false);
          });
      })
    );
    promiseList.push(
      new Promise((release, reject) => {
        this.notificationService.getNotificationTypes().subscribe(
          (response: any) => {
            this.notificationTypes = response.body.filter(notType => notType !== NOTIF_CONSTS.NOTIF_TYPE.TERR);
            release(true);
          },
          (error: any) => {
            this.msgBanner.addMsgError(this.messageList, error.message);
            this.showNotification = true;
            reject(false);
          }
        );
      })
    );
    return promiseList;
  }

  toggleChainsSelectAll(selectAllValue: boolean) {
    this.filteredChainsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.searchForm.controls.chains.patchValue(val.map(chain => chain.value.roomId));
        } else {
          this.searchForm.controls.chains.patchValue([]);
        }
      });
  }

  filterChains() {
    this.filteredChainsMulti.next(this.chains.slice(0, 50));
    this.chainMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterChainsMulti();
      });
  }

  onSearch() {
    this.showNotification = false;
    this.getData();
  }

  onSearchReset() {
    this.searchForm.reset();
    this.searchDisabled = false;
    this.onSearch();
  }

  onPageChange(event: PageEvent) {
    this.getData();
  }

  getData() {
    if (this.paginator) {
      this.loadData(this.paginator.pageIndex, this.paginator.pageSize);
    } else {
      this.loadData(0, 50);
    }
  }

  loadData(pageIndex?: number, pageSize?: number) {
    this.isLoadingResults = true;
    this.isError = false;

    if (this.restricted
      && this.searchForm.controls.notType.value !== NOTIF_CONSTS.NOTIF_TYPE.GENERAL
      && (this.searchForm.controls.chains.value === null || this.searchForm.controls.chains.value.length === 0)) {
      this.searchForm.controls.chains.setValue(this.chains.map(chain => chain.value.id));
      this.searchForm.controls.chains.updateValueAndValidity();
    }

    this.notificationService.getPublishNotifications(pageIndex, pageSize, this.searchForm.value).subscribe(
      (response: any) => {
        this.dataSource = new MatTableDataSource(response.content);
        this.dataSource.data.forEach(item => {
          item['expanded'] = false;
        });

        this.isLoadingResults = false;
        this.resultsLength = response.totalElements;
      }, error => {
        this.dataSource = new MatTableDataSource();

        this.isError = false;
        this.resultsLength = 0;

        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;

    const numRowsMinusExcluded = this.dataSource.data.length;

    return numSelected >= numRowsMinusExcluded;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PublishNotification): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  /** Keep the state of the checkbox when page changed */
  isChecked(row: any): boolean {
    const found = this.selection.selected.find(el => el.id === row.id);
    return !!found;
  }

  editSelected(row: PublishNotification) {
    this.dialog.open(PublishDialogComponent, {
      data: row.id,
      disableClose: true
    }).afterClosed().subscribe(response => {
      if (response) {
        this.onSearch();
        this.selection = new SelectionModel<PublishNotification>(true, []);
      }
    });
  }

  doNothing() {
    return;
  }

  publishSelected() {
    this.showNotification = false;
    if (this.selection.selected.length === 0) {
      return;
    }

    const selected = [];
    for (const sel of this.selection.selected) {
      if (this.returnAttachmentType(sel.attach) === 'File'
        && !sel.attach[0].includes(NOTIF_CONSTS.ATTACHMENT_FOLDERS.GENERAL)
        && !sel.attach[0].includes(NOTIF_CONSTS.ATTACHMENT_FOLDERS.CHAIN)
        && !sel.attach[0].includes(NOTIF_CONSTS.ATTACHMENT_FOLDERS.TERRITORY)) {
        selected.push(sel);
      }
    }

    if (selected.length > 0) {
      this.dialog.open(ConfirmationDialogComponent, {
        data: new Dialog('You are publishing  a notification with a temporary file. If you proceed the attachment will be lost. Do you want to proceed?',
          true, false, true),
        disableClose: true
      }).afterClosed().subscribe((res) => {
        if (res) {
          this.publishNotifications();
        }
      });
      return;
    }
    this.publishNotifications();
  }

  publishNotifications() {
    const data: string[] = [];
    for (const not of this.selection.selected) {
      data.push(not.id);
    }

    this.notificationService.publishNotification(data).subscribe(
      response => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '450px',
          data: new Dialog(response.response, false, false),
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.selection.clear();
          this.getData();
          this.counterService.refreshNotificationCounter(true);
        });
      }, error => {
        if (error.status === 400) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;
      }
    );
  }

  returnAttachmentType(attach: string[]) {
    return attach && attach.length > 0 && attach[0].startsWith('http') ? 'URL' :
      attach && attach.length > 0 && !attach[0].startsWith('http') ? 'File' : '';
  }

  protected filterChainsMulti() {
    if (!this.chains) {
      return;
    }
    // get the search keyword
    let search = this.chainMultiFilterCtrl.value;
    if (!search) {
      this.filteredChainsMulti.next(this.chains.slice(0, 50));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredChainsMulti.next(
      this.chains
        .filter(
          (chain) =>
          chain.value.roomName
              .toLowerCase()
              .indexOf(search) > -1
        ).slice(0, 50)
    );
  }

  get getNotificationTypes() {
    return NOTIF_CONSTS.NOTIF_TYPE;
  }
}
