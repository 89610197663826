import { NotificationService } from '../../../../core/services/notification.service';
import { GenericLineItemViewConfig } from '../../../../shared/models/GenericLineItemViewConfig.model';
import { ActivityConstants } from '../../../../shared/constants/ActivityConstants';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsgBannerService } from '../../../../shared/components/msg-banner/msg-banner.service';
import { NOTIF_CONSTS } from '../../../../shared/constants/notification-constants';

@Component({
  selector: 'app-general-notification-view',
  templateUrl: './general-notification-view.component.html',
  styleUrls: ['./general-notification-view.component.scss']
})
export class GeneralNotificationViewComponent implements OnInit {

  public config = new GenericLineItemViewConfig();
  public pageType = '';

  showNotification = false;
  messageList = [];

  readonly NOTIF_CONSTS = NOTIF_CONSTS;

  constructor(private formBuilder: FormBuilder,
              public notificationService: NotificationService,
              public route: ActivatedRoute,
              private msgBanner: MsgBannerService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.pageType = data.type;
      this.setUpGenericList();
    });
  }

  public getData(pageIndex, pageSize, searchValues) {
    return this.notificationService.getNotificationsByType(pageIndex, pageSize, NOTIF_CONSTS.NOTIF_TYPE.GENERAL, searchValues);
  }

  public setUpGenericList() {
    this.config.page = NOTIF_CONSTS.NOTIF_TYPE.GENERAL + '-' + this.pageType;
    this.config.idField = 'id';
    this.config.searchForm = this.formBuilder.group({
      title: [null, []],
      startNotificationDate: [null, []],
      endNotificationDate: [null, []],
      expired: [false, []]
    });
    this.config.dataSource = new MatTableDataSource();
    this.config.dataSource.data =  [];
    this.config.displayedColumns = ActivityConstants.SEARCH.displayedColumns;
  }
}
