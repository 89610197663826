<div
  fxLayout="column"
  fxLayoutGap="20px"
>
  <div
    fxFlex
    fxLayout="row"
  >
    <div
      fxFlex
      fxLayoutAlign="start"
      class="title"
    >{{ action }} Training
    </div>

    <div
      fxFlex
      fxLayoutAlign="end"
    >
      <mat-icon
        (click)="onCancel()"
        class="close-button"
      >close
      </mat-icon>
    </div>
  </div>

  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="20px"
  >
    <form
      [formGroup]="form"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
    >
      <mat-form-field
        hintLabel="Max 200 characters"
        appearance="standard"
        fxFlex
        fxFlex.lt-md="100%"
      >
        <input
          type="text"
          formControlName="name"
          matInput
          #input
          maxlength="200"
          placeholder="Enter Name of training"
          required
        />
        <mat-error *ngIf="form.controls.name.errors?.pattern && form.controls.name.dirty">
          * Input not allowed
        </mat-error>
        <mat-hint align="end">{{ input.value?.length || 0 }}/{{ input.maxLength }}</mat-hint>
      </mat-form-field>

      <mat-form-field
        appearance="standard"
        fxFlex
        fxFlex.lt-md="100%"
      >
        <input
          type="url"
          formControlName="url"
          matInput
          #input
          placeholder="Enter URL"
          required
        />
      </mat-form-field>
    </form>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center"
      fxLayoutAlign.lt-md="start"
      fxLayoutGap="2%"
      fxLayoutGap.lt-md="0"
    >
      <button
        id="submit"
        class="btn btn-submit"
        (click)="onSubmit()"
        [disabled]="!form.valid"
        fxFlex="100px"
        fxFlex.lt-md="100%"
      >
        {{ action }}
      </button>
      <button
        id="cancel"
        class="btn btn-cancel"
        (click)="onCancel()"
        fxFlex="100px"
        fxFlex.lt-md="100%"
      >
        Cancel
      </button>
    </div>

  </mat-dialog-content>
</div>
