import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GraphService} from './graph/graph.service';
import {ActiveFilterPipe} from './pipes/active-filter.pipe';
import {GroupGuardService} from './guards/group-guard.service';
import {ExcelService} from './services/excel.service';
import {PhotoDownloaderService} from './services/photo-downloader.service';
import {CompliantMessagesService} from './services/compliant-messages.service';
import {ActivityComplianceService} from './services/activity-compliance.service';
import {FindActivityService} from './services/find-activity.service';
import {NewActivityService} from './services/new-activity.service';



@NgModule({
  declarations: [ActiveFilterPipe],
  imports: [CommonModule],
  exports: [CommonModule, ActiveFilterPipe],
  providers: [
    GraphService,
    GroupGuardService,
    FindActivityService,
    NewActivityService,
    ActivityComplianceService,
    CompliantMessagesService,
    ExcelService,
    PhotoDownloaderService,
  ],
})
export class CoreModule { }
