import { NOTIF_CONSTS } from '../../../../shared/constants/notification-constants';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../../../core/services/notification.service';
import { GenericLineItemViewConfig } from '../../../../shared/models/GenericLineItemViewConfig.model';
import { ActivityConstants } from '../../../../shared/constants/ActivityConstants';
import { MatTableDataSource } from '@angular/material/table';
import {FormBuilder, Validators} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {MsgBannerService} from '../../../../shared/components/msg-banner/msg-banner.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-territory-notification-view',
  templateUrl: './territory-notification-view.component.html',
  styleUrls: ['./territory-notification-view.component.scss']
})
export class TerritoryNotificationViewComponent implements OnInit {

  public config = new GenericLineItemViewConfig();
  public pageType = '';

  showNotification = false;
  messageList = [];

  constructor(private formBuilder: FormBuilder,
              private msgBanner: MsgBannerService,
              public notificationService: NotificationService,
              public route: ActivatedRoute) {}

  readonly NOTIF_CONSTS = NOTIF_CONSTS;

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.pageType = data.type;
      this.setUpGenericList();
    });
  }

  public getData(pageIndex, pageSize, searchValues) {
    return this.notificationService.getNotificationsByType(pageIndex, pageSize, NOTIF_CONSTS.NOTIF_TYPE.TERR, searchValues);
  }

  public setUpGenericList() {
    this.config.page = NOTIF_CONSTS.NOTIF_TYPE.TERR + '-' + this.pageType;
    this.config.idField = 'id';
    this.config.searchForm = this.formBuilder.group({
      title: [null, Validators.pattern(environment.emojiRegEx), []],
      terrs: [[], [], []],
      users: [[], [], []],
      startNotificationDate: [null, []],
      endNotificationDate: [null, []],
      expired: [false, []]
    });
    this.config.dataSource = new MatTableDataSource();
    this.config.dataSource.data =  [];
    this.config.displayedColumns = ActivityConstants.SEARCH.displayedColumns;
  }
}
