<div
  fxFlex
  fxLayout="column"
>

  <div
    fxLayout="row"
  >
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span *ngIf="!isLoading" class="title">Edit Notification</span>
      </div>
    </div>

    <div
      fxFlex="20px" fxFlexAlign="end end"
    >
      <mat-icon class="close-button" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>

  <mat-dialog-content>
    <div
      *ngIf="isLoading"
      class="loading-spinner"
    >
      <p><strong>Loading...</strong></p>
      <mat-spinner></mat-spinner>
    </div>

    <app-new-notification
      [hidden]="isLoading"
      [pageConfig]="{'notificationId': data, 'type': NOTIF_CONSTS.PAGE.MANAGE}"
      [isPublish]="true"
      (loadingEmitter)="isLoading = $event"
      (editedEmitter)="onEdit($event)"
      (closeEmitter)="onDelete($event)"
    ></app-new-notification>
  </mat-dialog-content>
</div>
