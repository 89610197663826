import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {sum} from 'ng-zorro-antd/core/util';

@Injectable()
export class ExcelService {
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  defaultNames: Record<string, string> = {
    title: 'Activity Title',
    account: 'Account',
    chainNam: 'Chain Name ',
    str: 'Store Ref',
    strOwnRef: 'Store Own Ref',
    terr: 'Territory',
    stAdr: 'Address / Location',
    stZip: 'Post Code',
    imgs: 'Images',
    sta: 'Compliant',
    resp: 'Response',
    cmnt: 'Comment',
    usrNam: 'User Name',
    usrId: 'User E-Id',
    phoneNbr: 'User Phone Nbr',
    usrTerr: 'User Territory',
    city: 'User City',
    zip: 'User Zip',
    usrStAdr: 'User Store Address',
    updatedOn: 'Updated On'
  };
  maxLengths: Record<string, number> = {};

  summaryDefaultNames: Record<string, string> = {
    resp: 'Response',
    cnt: 'Count',
    percentage: 'Percent'
  };
  summaryMaxLengths: Record<string, number> = {};

  constructor() {}

  public exportExcel(jsonData: any[], fileName: string): void {
    const jsons = [];
    jsonData.forEach((val, index) => jsons[index] = this.jsonKeyRename(val, this.defaultNames));

    this.calculateLengths(jsons);
    const wscols = [];
    Object.keys(this.maxLengths).forEach(key => {
      wscols.push({width: this.maxLengths[key] + 3});
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsons);
    ws['!cols'] = wscols;

    var lastColumn: string = String.fromCharCode(64 + wscols.length);
    ws['!autofilter'] = {ref: "A1:" + lastColumn + "1"};

    // summary
    const summary = [];

    const compliant = jsonData.filter(j => j.resp !== '').length;
    const nonCompliant = jsonData.filter(j => j.resp === '').length;
    summary.push({ resp: 'Photos pre and post visit', cnt: compliant ? compliant : '0',
      percentage: (compliant ? ((compliant / jsonData.length) * 100).toFixed(2) : '0') + '%' });
    summary.push({ resp: 'No Response', cnt: nonCompliant ? nonCompliant : '0',
      percentage: (nonCompliant ? ((nonCompliant / jsonData.length) * 100).toFixed(2) : '0') + '%' });

    const summaryData = [];
    summary.forEach((val, index) => summaryData[index] = this.jsonKeyRename(val, this.summaryDefaultNames));

    this.calculateSummaryLengths(summaryData);
    const wsSummaryCols = [];
    Object.keys(this.summaryMaxLengths).forEach(key => {
      wsSummaryCols.push({width: this.summaryMaxLengths[key] + 1});
    });

    const wsSummary: XLSX.WorkSheet = XLSX.utils.json_to_sheet(summaryData);
    wsSummary['!cols'] = wsSummaryCols;
    // end summary

    const wb: XLSX.WorkBook = { Sheets: { 'Responses': ws , 'Summary': wsSummary }, SheetNames: ['Responses', 'Summary'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + '_' + this.getDateTime() +  this.fileExtension);
  }

  private jsonKeyRename(jsonData: any[], names: Record<string, string>) {
    const output = {};
    for (const key in jsonData) {
      if (names[key]) {
        output[names[key]] = jsonData[key];
      } else {
        let newKey = key.substr(0, 1).toUpperCase() + key.substring(1);
        newKey = newKey.match(/[A-Z0-9][a-z]*/g).join(' ');
        output[newKey] = jsonData[key];
      }
    }
    return output;
  }

  private getDateTime(): string {
    const date = new Date();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();

    return mm + dd + yyyy;
  }

  private calculateLengths(jsonData: any[]) {
    this.maxLengths = {};

    jsonData.forEach(json => {
      Object.keys(json).forEach(key => {
        if (!this.maxLengths[key]) {
          if (json[key]) {
            this.maxLengths[key] = Math.max(json[key].toString().length, key.length);
          } else {  this.maxLengths[key] = key.length; }

        } else if (json[key]) {
          this.maxLengths[key] = Math.max(this.maxLengths[key], json[key].toString().length);
        }
      });
    });
  }

  private calculateSummaryLengths(jsonData: any[]) {
    this.summaryMaxLengths = {};

    jsonData.forEach(json => {
      Object.keys(json).forEach(key => {
        if (!this.summaryMaxLengths[key]) {
          if (json[key]) {
            this.summaryMaxLengths[key] = Math.max(json[key].toString().length, key.length);
          } else {  this.summaryMaxLengths[key] = key.length; }

        } else if (json[key]) {
          this.summaryMaxLengths[key] = Math.max(this.summaryMaxLengths[key], json[key].toString().length);
        }
      });
    });
  }

}
