import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Season} from '../../shared/models/season.model';
import {Location} from '../../shared/models/location.model';
import {ActivityStatus} from '../../shared/models/activity/activity-status.model';
import {ActivityType} from '../../shared/models/activity/activity-type.model';
import {Store} from '../../shared/models/activity/store.model';
import {Customer} from '../../shared/models/activity/customer.model';
import {Activity} from '../../shared/models/activity/activity.model';
import {PublishActivity} from '../../shared/models/activity/publish-activity.model';
import {UserDetail} from '../../shared/models/activity/user-detail.model';
import { ChainGroupModel } from '../../shared/models/chain';

@Injectable()
export class NewActivityService {
  private header = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) {}

  getLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(environment.apiResourceUri + 'locations');
  }

  getSeasons(): Observable<Season[]> {
    return this.http.get<Season[]>(environment.apiResourceUri + 'seasons');
  }

  getChainGroups(): Observable<ChainGroupModel[]> {
    return this.http.get<ChainGroupModel[]>(environment.apiResourceUri + 'chain-groups')
  }

  getChainGroupById(chainGroupId): Observable<ChainGroupModel> {
    return this.http.get<ChainGroupModel>(environment.apiResourceUri + 'chain-groups/' + chainGroupId);
  }

  getActivityStatuses(): Observable<ActivityStatus[]> {
    return this.http.get<ActivityStatus[]>(environment.apiResourceUri + 'activity-statuses');
  }

  getActivityType(): Observable<ActivityType[]> {
    return this.http.get<ActivityType[]>(environment.apiResourceUri + 'activity-types');
  }

  searchStores(pageIndex: number, pageSize: number, searchFields): Observable<Store[]> {
    let params = null;

    params = new HttpParams();
    if (searchFields != null || typeof searchFields !== 'undefined') {
      Object.keys(searchFields).map(function (key) {
        if (key === 'chain') {
          if (searchFields[key]?.length === 0) {
            searchFields[key] = null;
          }
        }
        if (searchFields[key] !== null && searchFields[key] !== undefined && searchFields[key] !== '') {
          params = params.append(key, searchFields[key]);
        }
      });
    }

    if (!pageIndex || typeof pageIndex === 'number') {
      params = params.append('page', pageIndex);
    }
    if (!pageSize || typeof pageSize === 'number') {
      params = params.append('size', pageSize);
    } else {
      params = params.append('size', 10);
    }

    return this.http.get(environment.apiResourceUri + 'stores', {
      headers: this.header,
      params: params
    }).pipe(map((res: Store[]) => {
        return res;
      })
    );
  }

  loadStoresFromFile(stores: string[]): Observable<Customer[]> {
    const url = environment.apiResourceUri + 'stores';
    return this.http.patch<any>(url, stores);
  }

  createOpenActivity(activities: Activity[]): Observable<Activity> {
    return this.http.post<Activity>(
      // environment.apiResourceUri + environment.createActivity,
      environment.apiResourceUri + 'activities',
      activities
    );
  }

  createReviewActivity(activity: PublishActivity): Observable<PublishActivity> {
    return this.http.post<PublishActivity>(
      environment.apiResourceUri + 'activities/review',
      activity
    );
  }

  getActivities(query: string, chainGrp: number, actGrp: string, actId: number): Observable<string> {
    var params: any = {
      subject: query,
      chainGrpId: (chainGrp != null && chainGrp != undefined) ? chainGrp.toString() : '',
      actGrp: (actGrp != null && actGrp != undefined) ? actGrp : '',
      actId: (actId != null && actId != undefined) ? actId.toString() : ''
    }

    const url =
      environment.apiResourceUri + 'activities';
    return this.http.get<string>(url, {
        observe: 'response',
        params: params
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }

  loadPersonResponsibleFromFile(usrIds: string[], terrs: string[]): Observable<UserDetail[]> {
    let params = null;
    params = new HttpParams();

    if (terrs && terrs.length > 0) {
      params = params.append('terrs', terrs);
    }

    const url = `${environment.apiResourceUri}users`;
    return this.http.patch<any>(url, usrIds, { params });
  }
}
function params<T>(arg0: string, params: any, arg2: {}): Observable<ChainGroupModel> {
  throw new Error('Function not implemented.');
}

