import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NOTIF_CONSTS} from '../../../../shared/constants/notification-constants';

@Component({
  selector: 'app-publish-dialog',
  templateUrl: './publish-dialog.component.html',
  styleUrls: ['./publish-dialog.component.scss']
})
export class PublishDialogComponent implements OnInit {

  NOTIF_CONSTS = NOTIF_CONSTS;
  isLoading = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string,
              private dialogRef: MatDialogRef<PublishDialogComponent>) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onEdit(event: any) {
    if (event) {
      this.dialogRef.close(true);
    }
  }

  onDelete(event: any) {
    if (event) {
      this.dialogRef.close(true);
    }
  }
}
