<div
  class="wrapper"
>
  <app-msg-banner *ngIf="showNotification" [msgList]="messageList" (msgListChange)="messageList = $event"></app-msg-banner>

  <mat-card
    class="mat-elevation-z2"
  >
    <app-generic-line-item-view
      [config]="config"
      [loadDataCallback]="getData"
    ></app-generic-line-item-view>
  </mat-card>
</div>
