<div
  class="wrapper"
>
  <app-msg-banner
    *ngIf="showNotification"
    [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <mat-card
    class="mat-elevation-z2"
  >
    <mat-accordion
      displayMode="flat"
      multi
      class="mat-table responsive-table"
    >
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="start"
        fxLayoutGap="2%"
        class="mrg"
      >
        <mat-form-field fxFlex="65" fxFlex.lt-md="100">
          <input
            matInput
            #searchInput
            type="search"
            [(ngModel)]="searchValue"
            (keydown.enter)="applyFilter()"
            (keyup)="applyFilter(true)"
            placeholder="Search"
          />
          <button
            mat-button
            matSuffix
            mat-stroked-button
            (click)="applyFilter()"
            aria-label="search">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>

        <div
          fxFlex
        >
          <button
            fxFlex
            fxFlex.lt-md="100%"
            mat-raised-button
            (click)="openAdd()"
            class="btn btn-add row"
          >
            Add training
          </button>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortTable($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let row" data-label="Training Name">
            {{ row.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>URL</th>
          <td mat-cell *matCellDef="let row" data-label="URL">
            <a
              [ngClass]="{ 'url-link': row.url.includes('http') }"
              (click)="openLink(row.url)"
            >{{ row.url }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="header">Actions</th>
          <td mat-cell *matCellDef="let row" data-label="Actions" class="cell">
            <button mat-button color="primary" (click)="openEdit(row)">
              <mat-icon class="btn-prim">create</mat-icon>
            </button>
            <button mat-button color="primary" (click)="onDelete(row)">
              <mat-icon class="btn-cancel">delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        #paginator
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 50, 100]"
        [length]="totalRows"
        (page)="pageEvent = getServerData($event)"
        showFirstLastButtons
      ></mat-paginator>
    </mat-accordion>
  </mat-card>
</div>
