export class Notification {
    public id: String;
    public docType: String;
    public title: String;
    public desc: String;
    public notType: String;
    public lang: String;
    public author: String;
    public posted: Date;
    public from: Date;
    public until: Date;
    public audience?: String[];
    public rooms?: String[];
    public terr?: String;
    public users?: String[];
    public attach?: String;

    constructor() {}
}
