import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AttachmentConfig, AttachmentView} from '../../models/attachment.config';
import {AG_ROLE} from '../../constants/roles';
import {environment} from '../../../../environments/environment';
import {MsgBannerService} from '../msg-banner/msg-banner.service';
import {AttachmentService} from '../../../core/services/attachment.service';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Dialog} from '../../models/dialog';
import {MatDialog} from '@angular/material/dialog';
import {HttpEventType, HttpResponse} from '@angular/common/http';

@Component({
  selector: 'app-generic-upload-file',
  templateUrl: './generic-upload-file.component.html',
  styleUrls: ['./generic-upload-file.component.scss']
})
export class GenericUploadFileComponent implements OnInit {

  @Input() config: AttachmentConfig;
  @Input() uploadPath: string;
  @Output() fileEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() closeEmitter: EventEmitter<string> = new EventEmitter();

  // error list
  messageList = [];
  showNotification = false;

  @ViewChild('fileInput') attachment: any;
  files: any[] = [];

  loadingFile: { [key: number]: boolean } = {};
  failMessage: { [key: number]: string } = {};

  constructor(private msgBanner: MsgBannerService,
              private dialog: MatDialog,
              private attachmentService: AttachmentService) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.closeEmitter.emit(null);
  }

  get attachmentView() {
    return AttachmentView;
  }

  buildGuardParamBasedRole() {
    const customRoles = [AG_ROLE.UK_MERCH_ADMIN];
    return {
      customeRole: customRoles,
      path: ''
    };
  }

  goBackToFileStructure() {
    this.fileEmitter.emit(false);
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  prepareFilesList(files: Array<any>) {
    this.showNotification = false;
    for (const item of files) {
      if ((item.size / 1024 / 1024) > environment.maxFileUploadSize) {
        this.msgBanner.addMsgWarning(this.messageList, 'File size exceeded(' + environment.maxFileUploadSize + 'MB) for ' + item.name);
        this.showNotification = true;
        continue;
      }

      if (this.config.view.valueOf() !== this.attachmentView.TEMP && !item.name.endsWith('.pdf')) {
        this.msgBanner.addMsgWarning(this.messageList, item.name + ' is not a pdf. You are only allowed to upload pdf files.');
        this.showNotification = true;
        continue;
      }

      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesInBatch(0);
  }

  uploadFilesInBatch(index: number) {
    if (this.files[index]?.progress === 100) {
      this.uploadFilesInBatch(index + 1);
    } else if (this.files[index]) {
      this.upload(index);
    }
  }

  upload(index: number) {
    this.loadingFile[index] = true;
    this.attachmentService.checkForUpdate(this.files[index], this.uploadPath, this.config.view.valueOf()).subscribe(
      response => {
        if (response === true) {
          this.dialog.open(ConfirmationDialogComponent, {
            width: 'auto',
            data: new Dialog(`File ${this.files[index].name} already exists. Do you want to proceed?`, true, false, true),
            disableClose: true,
          }).afterClosed().subscribe(
            resp => {
              if (resp === true) {
                this.uploadFile(index);
              } else {
                this.files[index]['success'] = false;
                this.loadingFile[index] = false;
                this.uploadFilesInBatch(index + 1);
              }
            }
          );
        } else {
          this.uploadFile(index);
        }
      }, error => {
        this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        this.showNotification = true;
      }
    );
  }

  uploadFile(index: number) {
    this.attachmentService.uploadFile(this.files[index], this.uploadPath, this.config.view.valueOf()).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.files[index].progress = Math.round(100 * event.loaded / event.total);

        } else if (event instanceof HttpResponse) {
          this.files[index]['success'] = true;
          this.loadingFile[index] = false;
          if (this.config.view.valueOf() === this.attachmentView.TEMP) {
            this.closeEmitter.emit(event.body.response);
          } else {
            this.uploadFilesInBatch(index + 1);
          }
        }
      }, error => {
        if (error.status === 417) {
          this.msgBanner.addMsgError(this.messageList, error.error.message);
        } else if (error.status === 400) {
          this.failMessage[index] = error.error.message;
        } else {
          this.msgBanner.addMsgError(this.messageList, 'An error has occurred. Please contact your administrator!');
        }
        this.showNotification = true;

        this.files[index]['success'] = false;
        this.loadingFile[index] = false;
        this.uploadFilesInBatch(index + 1);
      });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.attachment.nativeElement.value = '';
  }
}
