import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {User} from '../../shared/models/user';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event} from '@angular/router';
import {GraphService} from '../../core/graph/graph.service';
import {environment} from '../../../environments/environment';

const pageTitleRouteMap = {
  '/home': environment.title,
  '/notification/new': 'Create Notification',
  '/notification/publish': 'Publish Notifications',
  '/notification/manage/general': 'Manage General Notifications',
  '/notification/manage/account': 'Manage Account Notifications',
  '/notification/manage/territory': 'Manage Territory Notifications',
  '/notification/view/general': 'View General Notifications',
  '/notification/view/account': 'View Account Notifications',
  '/notification/view/territory': 'View Territory Notifications',
  '/notification/documents/account': 'View Account Documents',
  '/notification/documents/general': 'View General Documents',
  '/admin/response': 'Activity Responses',
  '/admin/user': 'Users Permissions',
  '/admin/training': 'Training Materials',
  '/admin/location': 'Location Management',
  '/admin/season': 'Season Management',
  '/admin/documents': 'Document Management',
  '/admin/rooms': 'Room Management',
  '/admin/territory-changes': 'Territory Changes',
  '/activity/new': 'Create Activities',
  '/activity/manage': 'Personal Activities',
  '/activity/manage-group': 'Manage Compliance',
  '/activity/publish': 'Publish Activities',
  '/activity/compliance': 'View Compliance',
  '/activity/compliance-quick': 'View Compliance',
  '/activity/compliance/details': 'View Compliance Details',
};


@Component({
  selector: 'app-module-testing',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit, AfterViewInit, AfterViewChecked {

  title = 'UKG Merchandising Portal';
  user: User = {
    displayName: '',
    groupIDs: [],
    currentPath: '/',
  };
  mode = 'side';

  private _toggleBar = true;

  @ViewChild('drawer', { static: false }) drawer;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.adaptSidebar();
  }

  constructor(private authService: MsalService,
              private graphService: GraphService,
              private router: Router,
              private cdr: ChangeDetectorRef) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.title = pageTitleRouteMap[event['url']]
            ? pageTitleRouteMap[event['url']]
            : 'UK2GO Management Portal';
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit(): void {
    this.user = this.graphService.getUser();
    this.adaptSidebar();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.runOnRouteChange();

    this.drawer.opened = true;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      /* const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        const ps = new PerfectScrollbar(elemMainPanel);
        ps.update();*/
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  close(reason: string) {
    console.log(reason);
    this.drawer.close();
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: 'landing'
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  private adaptSidebar() {
    if (window.innerWidth <= 991) {
      this.mode = 'over';
    } else {
      this.mode = 'side';
    }
  }
}
