import {ActivityStatusEnum} from '../models/activity/activity-status.model';

export enum ColumnType {
  STRING,
  LONG_STRING,
  DATE,
  BOOLEAN,
  ACTIVITY_TYPE,
  SEASON_TYPE,
  LOCATION_TYPE,
  LONG_STRING_NULL
}

export const ActivityConstants = {
  STORES_NEW: {
    displayedColumns: [
      'select',
      'accountName',
      'chain',
      'storeCode',
      'storeOwnRef',
      'address',
      'territory'
    ]
  },
  RESPONSES_FIND: {
    displayedColumns: [
      'answer',
      'complFlag',
      'imageReq',
      'commentReq',
      'comment',
      'actions'
    ]
  },
  STORES_FIND: {
    displayedColumns: [
      'select',
      'error',
      'fullAccount',
      'branchCode',
      'storeCode',
      'ownRef',
      'address',
      'territory',
    ]
  },
  SEARCH: {
    displayedColumns: [
      'select',
      'title',
      'startDate',
      'endDate',
      'expirationDate',
      'taskStatus',
      'type',
      'season',
      'location',
      'createdBy',
    ]
  },
  ACTIVITY_STATUS: [
    { id: ActivityStatusEnum.OPEN.valueOf(), name: 'Open', disabled: false },
    { id: ActivityStatusEnum.PENDING.valueOf(), name: 'Pending', disabled: false },
    { id: ActivityStatusEnum.COMPLETE.valueOf(), name: 'Complete', disabled: false },
    { id: ActivityStatusEnum.CANCELED.valueOf(), name: 'Canceled', disabled: false },
  ],
  OPEN_ACTIVITIES: [
    { id: ActivityStatusEnum.OPEN.valueOf(), name: 'Open', disabled: false },
    { id: ActivityStatusEnum.CANCELED.valueOf(), name: 'Canceled', disabled: false }
  ],
  PENDING_ACTIVITIES: [
    { id: ActivityStatusEnum.OPEN.valueOf(), name: 'Open', disabled: false },
    { id: ActivityStatusEnum.PENDING.valueOf(), name: 'Pending', disabled: false },
    { id: ActivityStatusEnum.CANCELED.valueOf(), name: 'Canceled', disabled: false }
  ],
  COMPLETE_ACTIVITIES: [
    { id: ActivityStatusEnum.OPEN.valueOf(), name: 'Open', disabled: false },
    { id: ActivityStatusEnum.COMPLETE.valueOf(), name: 'Complete', disabled: false }
  ],
  CANCELED_ACTIVITIES: [
    { id: ActivityStatusEnum.OPEN.valueOf(), name: 'Open', disabled: false },
    { id: ActivityStatusEnum.CANCELED.valueOf(), name: 'Canceled', disabled: false }
  ],
  ATTACHMENT_FOLDERS: {
    CHAIN: 'CHAIN/',
    EVERYDAY: 'EVERYDAY/',
    SEASONAL: 'SEASONAL/'
  },
  MANAGE_GROUPS : {
    DISPLAYED_COLUMNS: [
      // { colDef: 'actGrp', colHeader: 'Group Id', colWidth: 'w-150', colType: ColumnType.STRING, colSort: true },
      { colDef: 'title', colHeader: 'Title', colWidth: 'w-250r', colType: ColumnType.LONG_STRING, colSort: true },
      { colDef: 'start', colHeader: 'Start Date', colWidth: 'w-100r', colType: ColumnType.DATE, colSort: true },
      { colDef: 'end', colHeader: 'End Date', colWidth: 'w-100r', colType: ColumnType.DATE, colSort: true },
      { colDef: 'exp', colHeader: 'Expiration Date', colWidth: 'w-100r', colType: ColumnType.DATE, colSort: true },
      { colDef: 'actType', colHeader: 'Type', colWidth: 'w-100r', colType: ColumnType.ACTIVITY_TYPE, colSort: true },
      { colDef: 'seas', colHeader: 'Season', colWidth: 'w-100r', colType: ColumnType.SEASON_TYPE, colSort: true },
      { colDef: 'loc', colHeader: 'Location', colWidth: 'w-100r', colType: ColumnType.LOCATION_TYPE, colSort: true },
      { colDef: 'createdBy', colHeader: 'Created By', colWidth: 'w-250r', colType: ColumnType.LONG_STRING, colSort: true },
      { colDef: 'publishedBy', colHeader: 'Published By', colWidth: 'w-250r', colType: ColumnType.LONG_STRING_NULL, colSort: true, ifNullColDef: 'createdBy' },
    ]
  }
};
