import { MsgBannerService } from '../../../shared/components/msg-banner/msg-banner.service';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Chain } from '../../../shared/models/chain';
import { PortalUser } from '../../../shared/models/portalUser';

import { of, ReplaySubject, Subject } from 'rxjs';
import {
  debounceTime,
  finalize,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AdminService } from '../../../core/services/admin.service';
import { AzureUser } from '../../../shared/models/azure-user.model';
import { AzureUsersPage } from '../../../shared/models/azure-users-page.model';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('territoryMultiSelect', { static: true })
  territoryMultiSelect: MatSelect;
  @ViewChild('chainMultiSelect', { static: true }) chainMultiSelect: MatSelect;

  form: FormGroup;
  action: string;

  public chainMultiFilterCtrl: FormControl = new FormControl();
  public filteredChainsMulti: ReplaySubject<string[]> = new ReplaySubject<
    string[]
  >(1);
  public territoryMultiFilterCtrl: FormControl = new FormControl();
  public filteredTerritoriesMulti: ReplaySubject<string[]> = new ReplaySubject<
    string[]
  >(1);
  protected _onDestroy = new Subject<void>();
  public tooltipMessage = 'Select All / Unselect All';

  roles: string[] = [];
  chains: Chain[] = [];
  territories: string[] = [];
  filteredUsers: AzureUser[] = [];
  isLoading = false;

  messageList = [];
  showNotification = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserDialogComponent>,
    private service: AdminService,
    private cdr: ChangeDetectorRef,
    private msgBanner: MsgBannerService,
    @Inject(MAT_DIALOG_DATA) public data: PortalUser
  ) {
    if (data) {
      this.form = this.fb.group({
        id: [data.id, []],
        usrNam: [data.usrNam, [Validators.required, Validators.email]],
        usrRole: [data.usrRole, Validators.required],
        fullNam: [data.fullNam],
        emailValidation: [data.usrNam],
        chains: [data.chains],
        terrs: [data.terrs],
      });
      this.action = 'Update';
    } else {
      this.form = this.fb.group({
        id: [null, []],
        usrNam: ['', [Validators.required, Validators.email]],
        usrRole: ['', Validators.required],
        fullNam: [null],
        emailValidation: [null],
        chains: [[]],
        terrs: [[]],
      });
      this.action = 'Add';
    }
  }

  ngOnInit(): void {
    Promise.all(this.populateDefaultValues()).then(() => {
      this.filteredChainsMulti.next(this.chains.map(chain => chain.chainId));
      this.filteredTerritoriesMulti.next(this.territories);

      this.chainMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterChainsMulti();
        });
      this.territoryMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterTerritoriesMulti();
        });

      this.form.controls.usrNam.valueChanges
        .pipe(
          debounceTime(300),
          tap(() => (this.isLoading = true)),
          switchMap((value) => {
            if (value?.length < 3) {
              this.filteredUsers = [];
              return of(null);
            }
            if (value instanceof Object || value === '' || !value) {
              return of(null);
            }

            return this.service
              .searchAzureUsers(value)
              .pipe(finalize(() => (this.isLoading = false)));
          })
        )
        .subscribe(
          (users: AzureUsersPage) => (this.filteredUsers = users?.value)
        );
    }).catch((error) => {
      this.msgBanner.addMsgError(this.messageList, "An error has occurred. Please contact your administrator!");
    })
  }

  populateDefaultValues(): Promise<boolean>[] {
    const promiseList: Promise<boolean>[] = [];
    promiseList.push(
      new Promise((release, reject) => {
        this.service.getRoles().subscribe(
          (response: any) => {
            this.roles = response;
            release(true);
          },
          (error) => {
            reject(error);
          });
      })
    );
    promiseList.push(
      new Promise((release, reject) => {
        this.service.getTerritories().subscribe(
          (response: any) => {
            this.territories = response;
            release(true);
          },
          (error) => {
            reject(error);
          });
      })
    );
    promiseList.push(
      new Promise((release, reject) => {
        this.service.getChains().subscribe(
          (response: any) => {
            this.chains = response;
            release(true);
          },
          (error) => {
            reject(error);
          });
      })
    );
    return promiseList;
  }

  saveFullName(user: AzureUser) {
    this.form.controls.fullNam.setValue(
      user.displayName + '(' + user.employeeId + ')'
    );
    this.form.controls.emailValidation.setValue(user.mail);
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSubmit() {
    this.showNotification = false;
    if (!this.form.controls.fullNam.value || this.form.controls.fullNam.value.length < 1
      || this.form.controls.emailValidation.value !== this.form.controls.usrNam.value) {
      this.msgBanner.addMsgError(this.messageList, this.form.controls.usrNam.value + ' does not exist. Please choose an existing user!');
      this.showNotification = true;
      return;
    }

    const user: PortalUser = new PortalUser();
    if (this.form.value) {
      Object.keys(user).forEach(key => {
        user[key] = this.form.value[key];
      });
    }
    this.dialogRef.close(user);
  }

  onCancel() {
    this.dialogRef.close();
  }

  compareFunction(o1: string, o2: string) {
    return o1 && o2 && o1 === o2;
  }

  protected setInitialValue() {
    this.filteredChainsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.chainMultiSelect.compareWith = (a: string, b: string) =>
          a && b && a === b;
      });

    this.filteredTerritoriesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.territoryMultiSelect.compareWith = (a: string, b: string) =>
          a && b && a === b;
      });
  }

  toggleChainsSelectAll(selectAllValue: boolean) {
    this.filteredChainsMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.form.controls.chains.patchValue(val);
        } else {
          this.form.controls.chains.patchValue([]);
        }
      });
  }

  toggleTerrsSelectAll(selectAllValue: boolean) {
    this.filteredTerritoriesMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.form.controls.terrs.patchValue(val);
        } else {
          this.form.controls.terrs.patchValue([]);
        }
      });
  }

  protected filterChainsMulti() {
    if (!this.chains) {
      return;
    }
    // get the search keyword
    let search = this.chainMultiFilterCtrl.value;
    if (!search) {
      this.filteredChainsMulti.next(this.chains.map(chain => chain.chainId));
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the chains
    this.filteredChainsMulti.next(
      this.chains
        .filter(
          (chain) =>
            this.getCahinNameById(chain.chainId)
              .concat(chain.chainId)
              .toLowerCase()
              .indexOf(search) > -1
        ).map(chain => chain.chainId)
    );
  }

  protected filterTerritoriesMulti() {
    if (!this.territories) {
      return;
    }
    // get the search keyword
    let search = this.territoryMultiFilterCtrl.value;
    if (!search) {
      this.filteredTerritoriesMulti.next(this.territories);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the territories
    this.filteredTerritoriesMulti.next(
      this.territories
        .filter((territory) => territory.toLowerCase().indexOf(search) > -1)
    );
  }

  getCahinNameById(chainId: string) {
    return this.chains.find((chain: Chain) => chain.chainId == chainId).chainName;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
