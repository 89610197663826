import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MsalService} from '@azure/msal-angular';
import {GraphService} from '../../../core/graph/graph.service';
import {RoleGuardService} from '../../../core/guards/role-guard.service';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {

  message = 'Opps, it seems that you are not authorized to see this page.';

  constructor(private router: Router,
              private roleGuardService: RoleGuardService) { }

  ngOnInit(): void {
    if (history.state.data) {
      this.message = history.state.data;
    }
  }

  public goHome(): void {
    const groups = this.roleGuardService.userRoles;
    if (groups.length === 1 && groups[0] === '') {
      localStorage.clear();
      this.router.navigate(['landing']);
    } else {
      this.router.navigate(['/']);
    }
  }

}
