<div
  fxLayout="column"
  fxLayoutGap="20px"
>
  <app-msg-banner
    *ngIf="showNotification" [msgList]="messageList"
    (msgListChange)="messageList = $event"
  ></app-msg-banner>

  <div
    fxFlex
    fxLayout="row"
  >
    <div
      fxFlex
      fxLayoutAlign="start"
      class="title"
    >Edit Activity
    </div>

    <div
      fxFlex
      fxLayoutAlign="end"
    >
      <mat-icon
        (click)="closeDialog()"
        class="close-button"
      >close
      </mat-icon>
    </div>
  </div>


  <mat-dialog-content>
    <div
      *ngIf="isLoading"
      class="spinner-container"
    >
      <a>Downloading file...</a>
      <mat-spinner></mat-spinner>
    </div>

    <div
      fxLayout="column"
      fxLayoutGap="2%"
      *ngIf="!isLoading && showSearchResults"
    >
      <form
        *ngIf="showSearchResults && !isLoading"
        fxLayout="column"
        fxLayoutGap="2%"
        fxLayoutGap.lt-md="0px"
        [formGroup]="formDates"
      >
        <mat-form-field
          *ngIf="!massEdit"
        >
          <mat-label>Activity Type</mat-label>
          <mat-select formControlName="taskType" [disabled]="!massEdit" required>
            <mat-option *ngFor="let activity of activityTypes" [value]="massEdit ? activity : activity.actTypeId">
              <span *ngIf="activity.actTypeId !== getActivityTypes.SHIPPER_COUNT">{{ activity.en }}</span>
              <span *ngIf="activity.actTypeId === getActivityTypes.SHIPPER_COUNT">{{ getEnvironment.shipperName }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between center"
          fxLayoutAlign.lt-md="start"
          fxLayoutGap="2%"
          fxLayoutGap.lt-md="0"
          *ngIf="!haveTheSameActivityGroupId"
        >
          <mat-form-field
            fxFlex
            fxFlex.lt-md="100%"
          >
            <mat-label>Activity Title</mat-label>
            <input
              matInput
              formControlName="title"
              [maxLength]="getLength().title"
              [required]="!massEdit"
            />
            <mat-hint>{{ formDates.controls.title.value ? formDates.controls.title.value.length : 0 }}
              /{{ getLength().title }}</mat-hint>
            <mat-error *ngIf="formDates.controls.title.errors?.required && formDates.controls.title.dirty">
              Activity Title is Mandatory.
            </mat-error>
            <mat-error *ngIf="formDates.controls.title.errors?.pattern && formDates.controls.title.dirty">
              * Input not allowed
            </mat-error>
            <mat-error *ngIf="formDates.controls.title.errors?.maxLength && formDates.controls.title.touched">
              {{formDates.controls.title.errors?.maxLength}}
            </mat-error>
          </mat-form-field>

          <mat-form-field
            *ngIf="!massEdit && data.page === 'manage' && !isPersonalActivity"
            fxFlex="20%"
            fxFlex.lt-md="100%"
          >
            <mat-label>Full Account</mat-label>
            <input
              matInput
              formControlName="fullAccount"
              [disabled]="true"
            />
            <mat-error *ngIf="formDates.controls.fullAccount.dirty && formDates.controls.fullAccount.invalid">
              Full Account is Mandatory.
            </mat-error>
            <mat-error *ngIf="formDates.controls.fullAccount.errors?.pattern && formDates.controls.fullAccount.dirty">
              * Input not allowed
            </mat-error>
          </mat-form-field>

          <mat-form-field
            *ngIf="!massEdit && data.page === 'manage' && !isPersonalActivity"
            fxFlex="20%"
            fxFlex.lt-md="100%"
          >
            <mat-label>Store Name</mat-label>
            <input
              matInput
              formControlName="storeName"
              [disabled]="true"
            />
            <mat-error *ngIf="formDates.controls.storeName.dirty && formDates.controls.storeName.invalid">
              Store Name is Mandatory.
            </mat-error>
            <mat-error *ngIf="formDates.controls.storeName.errors?.pattern && formDates.controls.storeName.dirty">
              * Input not allowed
            </mat-error>
          </mat-form-field>
        </div>

        <div
          fxFlex
          class="form-group has-feedback mat-elevation-z0"
          *ngIf="!haveTheSameActivityGroupId"
          [ngClass]="{ 'has-error': formDates.controls.desc.invalid && formDates.controls.desc.touched }"
        >
          <span class="description" style="height: auto; user-select: none;">Description</span>
          <ckeditor
            [config]="config"
            formControlName="desc"
            required
          ></ckeditor>
          <mat-error *ngIf="formDates.controls.desc.errors?.required && formDates.controls.desc.dirty">
            * Description is Mandatory.
          </mat-error>
          <mat-error *ngIf="formDates.controls.desc.errors?.pattern && formDates.controls.desc.dirty">
            * Input not allowed
          </mat-error>
          <mat-error *ngIf="formDates.controls.desc.errors?.maxLength && formDates.controls.desc.touched">
            {{formDates.controls.desc.errors?.maxLength}}
          </mat-error>
        </div>

        <div
          fxFlex
          fxFlex.lt-md="100%"
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="2%"
          fxLayoutGap.lt-md="0"
          *ngIf="data.page === 'manage'"
        >
          <mat-form-field
            fxFlex
            fxFlex.lt-md="100%"
          >
            <mat-label>Activity Status</mat-label>
            <mat-select formControlName="activityStatus" [required]="!massEdit">
              <mat-option *ngFor="let activityStatus of activityStatuses" [value]="activityStatus.id">
                {{ activityStatus.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="!massEdit && formDates.controls.taskType?.value == getShipperCountValue()"
            fxFlex="20%"
            fxFlex.lt-md="100%"
          >
            <mat-label>{{ getEnvironment.shipperName }}</mat-label>
            <input type="number" matInput required formControlName="shipperCount"/>
            <mat-error
              *ngIf="formDates.controls.shipperCount.value?.toString().trim() === ''
                || formDates.controls.shipperCount.value == null
                || formDates.controls.shipperCount.value == undefined"
            >
              {{ getEnvironment.shipperName }} is mandatory.
            </mat-error>
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="2%"
          fxLayoutGap.lt-md="0"
        >
          <mat-form-field
            fxFlex="32%"
            fxFlex.lt-md="100%"
          >
            <mat-label>Start Date</mat-label>
            <input
              formControlName="startDate"
              matInput
              [required]="!massEdit"
              [matDatepicker]="picker1"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
              style="display: inline-block"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker1
              style="display: inline-block"
            ></mat-datepicker>
          </mat-form-field>

          <mat-form-field
            fxFlex="32%"
            fxFlex.lt-md="100%"
          >
            <mat-label> End Date</mat-label>
            <input
              formControlName="endDate"
              matInput
              [required]="!massEdit"
              [min]="formDates.controls.startDate.value"
              [matDatepicker]="picker2"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
              style="display: inline-block"
            >
            </mat-datepicker-toggle>
            <mat-datepicker
              #picker2
              style="display: inline-block"
            ></mat-datepicker>
          </mat-form-field>

          <mat-form-field
            fxFlex="32%"
            fxFlex.lt-md="100%"
          >
            <mat-label>Expiration Date</mat-label>
            <input
              formControlName="expirationDate"
              matInput
              [required]="!massEdit"
              [min]="formDates.controls.endDate.value"
              [matDatepicker]="picker3"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker3"
              style="display: inline-block"
            >
            </mat-datepicker-toggle>
            <mat-datepicker
              #picker3
              style="display: inline-block"
            ></mat-datepicker>
          </mat-form-field>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="2%"
          fxLayoutGap.lt-md="0"
        >
          <mat-form-field
            *ngIf="!haveTheSameActivityGroupId && !isPersonalActivity"
            fxFlex="49%"
            fxFlex.lt-md="100%"
          >
            <mat-label>Season</mat-label>
            <mat-select formControlName="season" [required]="!massEdit && !isPersonalActivity">
              <mat-option
                *ngFor="let season of seasons"
                [disabled]="!season.active"
                [value]="massEdit ? season : season.seasId"
              >
                {{ season.en }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="!haveTheSameActivityGroupId && !isPersonalActivity"
            fxFlex="49%"
            fxFlex.lt-md="100%"
          >
            <mat-label>Location</mat-label>
            <mat-select formControlName="location" [required]="!massEdit && !isPersonalActivity">
              <mat-option
                *ngFor="let location of locations"
                [disabled]="!location.active"
                [value]="massEdit ? location : location.locId"
              >
                {{ location.en }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="data.page === 'manage' && ((massEdit || !haveTheSameActivityGroupId) && isPersonalActivity)"
            fxFlex
            fxFlex.lt-md="100%"
          >
            <mat-label>Person Responsible</mat-label>
            <input type="text"
                   placeholder="Person Responsible"
                   matInput
                   formControlName="personResponsible"
                   [matAutocomplete]="auto"
                   [required]="!massEdit && isPersonalActivity">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="valueMapper">
              <mat-option *ngFor="let usr of filteredUsers" [value]="usr">
                {{ usr.nam }} ({{usr.usrId }})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field
            *ngIf="data.page === 'publish' && ((massEdit || !haveTheSameActivityGroupId) && isPersonalActivity)"
            fxFlex
            fxFlex.lt-md="100%"
          >
            <mat-label>Person Responsible</mat-label>
            <mat-chip-list #chipList aria-label="Fruit selection">
              <mat-chip
                class="chip-item"
                *ngFor="let usr of users"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(usr)">
                {{ usr.nam }} ({{ usr.usrId }})
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                type="text"
                placeholder="Person Responsible"
                #userInput
                matInput
                formControlName="personResponsible"
                (focusout)="removeOnFocusOut()"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
            </mat-chip-list>
            <mat-autocomplete
              autoActiveFirstOption #auto="matAutocomplete"
              (closed)="close()"
              (optionSelected)="selectedValue($event)" [displayWith]="valueMapper"
            >
              <mat-option *ngFor="let usr of filteredUsers" [value]="usr">
                {{ usr.nam }} ({{usr.usrId }})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <app-generic-attachment
          *ngIf="massEdit"
          [config]="buildAttachmentConfig()"
          [attachments]="attachments"
          [isUrl]="isUrl"
          (errorEmitter)="displayError($event)"
        ></app-generic-attachment>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="2%"
          fxLayoutGap.lt-md="0"
          *ngIf="!massEdit"
        >
          <mat-form-field
            fxFlex
            fxFlex.lt-md="100%"
          >
            <mat-label>Created By</mat-label>
            <input
              type="text"
              placeholder="Created By"
              matInput
              disabled
              formControlName="createdBy">
          </mat-form-field>

          <mat-form-field
            *ngIf="this.data.page === 'manage'"
            fxFlex="49%"
            fxFlex.lt-md="100%"
          >
            <mat-label>Published By</mat-label>
            <input
              type="text"
              placeholder="Published By"
              matInput
              disabled
              formControlName="publishedBy">
          </mat-form-field>
        </div>

      </form>

      <app-store-container
        #storeContainer
        [config]="this.buildStoreConfig()"
        (errorEmitter)="showError($event)"
      ></app-store-container>

      <app-generic-attachment
        *ngIf="!massEdit"
        [config]="buildAttachmentConfig()"
        [attachments]="attachments"
        [isUrl]="isUrl"
        (errorEmitter)="displayError($event)"
      ></app-generic-attachment>

      <div
        fxLayout="column"
        fxLayoutGap="0"
        *ngIf="!haveTheSameActivityGroupId"
      >
        <app-msg-banner
          *ngIf="showInfo"
          [msgList]="infoList"
          (msgListChange)="infoList = $event"
        ></app-msg-banner>

        <mat-form-field
          *ngIf="data.page === 'publish'"
          fxFlex
          appearance="standard"
        >
          <mat-label>Select a default list of responses</mat-label>
          <mat-select>
            <mat-option *ngFor="let response of responses" (click)="sendDataToChild(response.responses)"
                        [value]="response.responses">
              {{ response.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <app-load-responses 
          *ngIf="loadResponses" 
          fxFlex 
          [config]="buildConfig()"
          [parentActGrpId]="getCurrentActGrpId()"
        ></app-load-responses>
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="center"
        fxLayoutGap="2%"
      >
        <button
          fxFlex="100px"
          fxFlex.lt-md="100%"
          mat-raised-button
          class="btn btn-actions update"
          color="primary"
          type="button"
          [disabled]="massEdit ? false : this.formDates.invalid"
          (click)="massEdit ? massUpdate(formDates.value) : updateActivity()"
        >Update
        </button>

        <button
          *ngIf="this.data.page === 'manage-group'"
          fxFlex="100px"
          fxFlex.lt-md="100%"
          mat-raised-button
          class="btn btn-actions delete"
          color="primary"
          type="button"
          (click)="deleteActivityGroup()"
        >Delete
        </button>

        <button
          *ngIf="this.data.page === 'manage' && massEdit"
          fxFlex="100px"
          fxFlex.lt-md="100%"
          mat-raised-button
          class="btn btn-actions delete"
          color="primary"
          type="button"
          (click)="cancel()"
        >Cancel
        </button>

        <button
          *ngIf="this.data.page === 'manage' && !massEdit"
          fxFlex="100px"
          fxFlex.lt-md="100%"
          mat-raised-button
          class="btn btn-actions delete"
          color="primary"
          type="button"
          (click)="deletePersonalActivity()"
        >Delete
        </button>

        <button
          *ngIf="this.data.page === 'publish'"
          fxFlex="100px"
          fxFlex.lt-md="100%"
          mat-raised-button
          class="btn btn-actions delete"
          color="primary"
          type="button"
          (click)="deletePublishActivity()"
        >Delete
        </button>

      </div>
    </div>

  </mat-dialog-content>
</div>
